const authEN = {
	welcomeText: 'Welcome Back! Please Enter Your Details.',
	rememberText: 'Remember for 30 Days',
	forgotPassword: 'Forgot Password',
	signIn: 'Sign In',
	signInGoogle: 'Sign in with Google',
	forgotPasswordTitle: 'Forgot Password?',
	emailSendText: 'No Worries, We’ll Send You Reset Instructions.',
	sendEmail: 'Send Email',
	backToLogin: 'Back in to Log In',
	emailPlaceholder: 'Enter Your Email',
	resetPassword: 'Reset Password',
	newPasswordPlaceholder: 'Enter New Password',
	confirmNewPasswordPlaceholder: 'Enter Confirm New Password',
	verificationText: 'For the Security Reason We Using a Two Factor Authentication.',
	emailVerificationText: 'We will Send You Authentication Code for Complete Your Account Setup',
	codeVerificationText: 'Provide Security Code Below.',
	verificationEmailPlaceholder: 'Enter Email Address',
	sendCode: 'Send Code',
	confirm: 'Confirm',
	createAccountText: 'This is a Last Step! Please Create Your Password',
	passwordPlaceholder: 'Type Your Password',
	repeatPassword: 'Repeat Password',
	createAccount: 'Create Account',
	youAreIn: "You're In!",
	accountCreationSuccessMessage: 'Account Created Successfully',
	emailSendSuccessMessage: 'Email Sent Successfully',
	resetPasswordSuccessMessage: 'Password Reset Successfully'
};

export default authEN;
