export const QUICk_NOTE_TABS = [
	{
		title: 'My Notes',
		key: 'users_quick_note_information'
	},
	{
		title: 'Team Yonex Notes',
		key: 'team_yonex_quick_note_information'
	}
];

export enum TAB_KEY {
	MY_NOTES = 'users_quick_note_information',
	TEAM_YONEX_NOTES = 'team_yonex_quick_note_information'
}
export const notesData = {
	myNotes: [
		{
			id: '1',
			createdBy: 'Matrin Cawde',
			note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum in vitae diam laoreet. Sed metus, tincidunt eget dui, ac pellentesque sit. Enim hendrerit commodo arcu sit eget amet sagittis porttitor. Erat quisque enim, gravida nulla dui nunc porta semper.',
			createdDate: '2024-08-22T00:00:00.000Z'
		},
		{
			id: '2',
			createdBy: 'Matrin Cawde',
			note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum in vitae diam laoreet. Sed metus, tincidunt eget dui, ac pellentesque sit. Enim hendrerit commodo arcu sit eget amet sagittis porttitor. Erat quisque enim, gravida nulla dui nunc porta semper.',
			createdDate: '2024-08-22T00:00:00.000Z'
		}
	],
	yonextNotes: [
		{
			id: '1',
			createdBy: 'Matrin Cawde',
			note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum in vitae diam laoreet. Sed metus, tincidunt eget dui, ac pellentesque sit. Enim hendrerit commodo arcu sit eget amet sagittis porttitor. Erat quisque enim, gravida nulla dui nunc porta semper.',
			createdDate: '2024-08-22T00:00:00.000Z'
		}
	]
};

export const enum NoteModal {
	addNote = 'added',
	editNote = 'updated',
	viewNote = 'View note',
	deleteNote = 'deleted',
	viewSocialMedia = 'Social Media'
}

export enum KpiGroup {
	Footwork = 'Footwork',
	Technical = 'Technical',
	Tactical = 'Tactical',
	Psychological = 'Psychological',
	Physical = 'Physical',
	Mental = 'Mental',
	'Daily Practice Or Lifestyle' = 'Daily / Lifecycle',
	'Performance Environment' = 'P. En.'
}

// Define your color arrays
export const colors = [
	'#0057B7',
	'#1D9533',
	'#ED3833',
	'#F3B743',
	'#655091',
	'#A44062',
	'#EE79C3',
	'#337E7E',
	'#7F7F7F',
	'#3A4B90'
];
