import { FC, useCallback, Fragment, useMemo } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';

import Dropdowns from 'shared/components/dropdown/flowboteDropdown';
import { CloseIcon, FilterIcon, PlusIcon } from 'shared/icon';
import ButtonSecondary from 'shared/components/buttons/secondary';
import ButtonPrimary from 'shared/components/buttons/primary';
import { useAthleteContext } from 'store/athleteStore';
import usePermission from 'shared/components/permissions/usePermissions';
import { FILTERS, IFilters, ViewFilter } from 'features/athleteDatabase/interface';
import { FilterModal } from 'features/athleteDatabase/container';
import { ATHLETES_OPTIONS } from 'features/athleteDatabase/constant';
import SearchInput from 'shared/components/search';
import SportsFilter from 'shared/components/filter/sportsFilter';
import TableView from 'shared/components/filter/tableView';
import { useNavigate } from 'react-router-dom';

interface IProps {
	filters: IFilters;
	viewType: string;
	totalAthlete: number | undefined;
	savedFilterName: string;
	isTennis: boolean;
	onChangeFilter: (key: FILTERS, value: string) => void;
	handleChangeLayout: (type: string) => void;
	openModal: (value: FilterModal) => void;
}

const Filters: FC<IProps> = (props) => {
	const { athleteFilters: filters } = useAthleteContext();

	const { totalAthlete, viewType, savedFilterName, openModal, onChangeFilter, handleChangeLayout } = props;
	const { countryOptions } = useAthleteContext();

	const navigate = useNavigate();

	const { canAdd } = usePermission();

	const getCountry = useCallback(
		(countryId: number | string) => {
			const countryData = countryOptions.find((country) => country.value === countryId);
			return countryData ? `${countryData?.emoji} ${countryData?.iso3}` : 'Country';
		},
		[countryOptions]
	);

	const getAppliedFilters = useCallback(
		(key: FILTERS) => {
			if (key === FILTERS.COUNTRY) {
				return getCountry(filters[key]);
			} else if (key === FILTERS.FILTER_ID) {
				return savedFilterName;
			} else {
				return filters[key];
			}
		},
		[savedFilterName, filters, getCountry]
	);

	const kpiArray = useMemo(() => {
		if (filters.kpis && filters.kpis !== '') {
			const serializedFilters = decodeURIComponent(filters.kpis as string);
			return JSON.parse(serializedFilters);
		}
		return [];
	}, [filters.kpis]);

	return (
		<div className='bg-lightBlue'>
			<div className='flex max-h-[77px] w-full items-center justify-between rounded-t-lg border p-2 lg:p-2 2xl:p-4'>
				<div>
					<div className='flex items-center gap-2'>
						<p className='header-txt'>General Athlete Database</p>
						{totalAthlete && totalAthlete > 0 ? (
							<p className='bg-[#1d953317] p-2 rounded-lg h-8 text-[#1E9634] text-xs'>
								{totalAthlete} {`${totalAthlete === 1 ? 'Athlete' : 'Athletes'}`}
							</p>
						) : (
							<></>
						)}
					</div>
					<p className='sub-header-txt '>Keep Track of Athletes and Their Ratings.</p>
				</div>
				<div className='flex items-center gap-3'>
					<SearchInput
						onChangeFilter={onChangeFilter as any}
						FilterKey={FILTERS.SEARCH}
						searchValue={filters[FILTERS.SEARCH] as string}
						sizing='sm'
					/>
					{canAdd && (
						<div className='flex gap-3'>
							<ButtonPrimary
								type='button'
								btnTxt='Quick Add'
								onClick={() => {
									openModal(FilterModal.QUICK_ADD);
								}}
								icon={<PlusIcon width='15px' className='stroke-white mr-2' />}
							/>
							<ButtonSecondary btnTxt='Full Registration' onClick={() => navigate('/add-player')} />
						</div>
					)}
				</div>
			</div>
			<div className='flex max-h-[77px] w-full items-center justify-between border-x border-b px-4 py-2'>
				<div className='flex items-center'>
					<SportsFilter />

					<Dropdowns
						options={ATHLETES_OPTIONS}
						defaultValue={(filters[FILTERS.ATHLETE] as string) || ''}
						onChange={(selected) => onChangeFilter(FILTERS.ATHLETE, selected)}
					/>
				</div>
				<div className='flex '>
					<div className='flex'>
						{(Object.keys(filters) as Array<keyof IFilters>)
							.filter((k) => filters[k] !== '' && ViewFilter.includes(k))
							.map((key) => {
								return (
									<Fragment key={key}>
										{key === FILTERS.KPI ? (
											<div className='text-sm ml-1 border px-1 rounded-md'>
												<button
													onClick={() => openModal(FilterModal.Filter_Modal)}
													className='ml-1 cursor-pointer'
												>
													+{kpiArray.length}
												</button>
											</div>
										) : (
											<div className='text-sm ml-1 border px-1 rounded-md'>
												{getAppliedFilters(key)}
												<button
													onClick={() => onChangeFilter(key, '')}
													className='ml-1 cursor-pointer'
												>
													<CloseIcon className='cursor-pointer ml-1' />
												</button>
											</div>
										)}
									</Fragment>
								);
							})}
					</div>
					<div className='mr-3 flex h-6 items-center justify-center border-r border-[#EAECF0]'>
						<Button
							color='light'
							className='ring-none border-none'
							onClick={() => openModal(FilterModal.Filter_Modal)}
						>
							<FilterIcon className='mr-2 size-5' />
							Filter
						</Button>
					</div>
					<TableView viewType={viewType} onChange={handleChangeLayout} />
				</div>
			</div>
		</div>
	);
};

export default Filters;
