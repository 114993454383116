import { FC, useContext, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'flowbite-react';

import { AddRecruitingIcon, AllAthleteIcon, DownArrowIcon, PlusIcon, ScoutingIcon } from 'shared/icon';
import {
	ATHLETE_PATH,
	CommonModal,
	CONTRACT_MANAGEMENT,
	DASHBOARD,
	PLAYER_MANAGE_LIST,
	SCOUTING_PATH
} from '../constant';
import useOnClickOutside from 'shared/hooks/useOutSideClick';
import { ROLES } from 'shared/components/permissions/constants';
import PermissionWrapper from 'shared/components/permissions/permissions';
import usePermission from 'shared/components/permissions/usePermissions';
import ButtonPrimary from 'shared/components/buttons/primary';
import ButtonSecondary from 'shared/components/buttons/secondary';
import useModal from 'shared/hooks/useModal';
import { defaultValues } from 'features/shortlist/constant';
import { ScreenType } from 'features/allAthlates/constant';
import QuickAdd from 'features/athleteDatabase/component/quickAdd';

import { DashboardDefaultValues } from 'features/recruitingDashboard/constant';
import { useAthleteContext } from 'store/athleteStore';

import CreateShortListModal from 'features/shortlist/component/modal/createShortlistModal';
import ViewShortListModal from 'features/shortlist/component/modal/viewShortlistModal';
import CreateBoardModal from 'features/recruitingDashboard/component/modal/createBoardModal';
import ViewDashboardModal from 'features/recruitingDashboard/component/modal/viewDashboardModal';
import useModulePermission from 'shared/components/permissions/userModulePermission';
import TooltipContainer from 'shared/components/tooltip';

import { AuthContext } from 'store/userDetailStore';
import ImportExportButton from 'shared/components/importExportButton';

interface IProps {}

const Navigation: FC<IProps> = (/* props */) => {
	const { t } = useTranslation('header');
	const navigate = useNavigate();
	const location = useLocation();
	const playerRef = useRef(null);
	const recruitingRef = useRef(null);
	const [navMenu, setNavMenu] = useState('');

	const { canAdd, canView } = usePermission();

	const { countDetails } = useAthleteContext();

	const { isOpen, type, closeModal, openModal } = useModal<CommonModal>();

	const [title, setTitle] = useState('');

	const { hasAthleteManagement } = useModulePermission();
	const { sport } = useContext(AuthContext);

	useOnClickOutside(playerRef, () => {
		if (navMenu === 'athlete_management') setNavMenu('');
	});
	useOnClickOutside(recruitingRef, () => {
		const element = document.getElementById('info_modal');
		if (navMenu === 'scouting_recruiting_dropdown' && !element) setNavMenu('');
	});

	// Toggle the dropdown visibility
	const toggleDropdown = (title: string) => {
		setNavMenu(navMenu === title ? '' : title);
	};

	const RECRUITING_LIST = useMemo(() => {
		return [
			{
				icon: <AllAthleteIcon className='stroke-primary' />,
				title: 'allAthletes',
				desc: 'allAthletesText',
				onclick: undefined,
				path: '/all-athletes',
				isTooltip: false,
				tooltipContent: {
					junior_athlete: countDetails?.junior_athletes,
					senior_athlete: countDetails?.senior_athletes
				},
				count: countDetails?.players,
				decIcon: <DownArrowIcon className='size-4 stroke-primary rotate-[270deg]' />
			},
			{
				icon: <ScoutingIcon className='stroke-primary' />,
				title: 'scoutingShortlists',
				desc: 'scoutingShortlistsText',
				path: '/shortlist',
				onclick: undefined,
				count: countDetails?.shortlists_count,
				isTooltip: false,
				decIcon: <PlusIcon width='15px' className='stroke-primary mr-2' />
			},
			{
				icon: <AddRecruitingIcon className='stroke-primary' />,
				title: 'recruitingDashboard',
				desc: 'recruitingDashboardText',
				path: '/recruiting-dashboard',
				onclick: undefined,
				count: countDetails?.recruiting_board_count,
				isTooltip: false,
				decIcon: <PlusIcon width='15px' className='stroke-primary mr-2' />
			}
		];
	}, [countDetails]);

	return (
		<div
			id='mega-menu-icons'
			className='z-[11] mt-3 hidden h-16 w-full items-center justify-between bg-white lg:order-1 lg:mt-0 lg:flex lg:h-0 lg:w-auto lg:border-[none]'
		>
			<ul className='mt-4 flex justify-center font-medium space-x-3 2xl:space-x-6 lg:mt-0 rtl:space-x-reverse'>
				<li onClick={() => navigate('/dashboard')}>
					<a
						className={`cursor-pointer flex w-full items-center justify-between text-base-responsive pb-1 font-semibold dark:text-white md:w-auto md:hover:bg-transparent md:dark:hover:bg-transparent ${
							DASHBOARD.includes(location.pathname)
								? 'border-b border-[#0057B7] text-textBlack'
								: 'hover:border-b hover:border-[#0057B7] text-textGray hover:text-textBlack'
						}`}
						aria-current='page'
					>
						{t('home')}
					</a>
				</li>
				<li ref={playerRef}>
					<PermissionWrapper accessRoles={[ROLES.ATHLETE_MANAGEMENT_DATABASE]}>
						<button
							onClick={() => toggleDropdown('athlete_management')} // Toggle dropdown on click
							className={`flex w-full items-center justify-between pb-1 text-base-responsive font-semibold active:border-b active:text-textBlack dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 ${
								ATHLETE_PATH.some((path: string) => matchPath({ path, end: true }, location.pathname))
									? 'border-b border-[#0057B7] text-textBlack'
									: 'hover:border-b hover:border-[#0057B7] text-textGray hover:text-textBlack'
							}`}
						>
							{t('playerManagement')}
							<DownArrowIcon className='ms-1 2xl:ms-3 size-2.5 stroke-[#344054]' />
						</button>
					</PermissionWrapper>
					{navMenu === 'athlete_management' && (
						<div
							className={`absolute ${
								PLAYER_MANAGE_LIST.length > 1 && canAdd ? 'left-[27%]' : ''
							} z-10 w-auto grid-cols-2 rounded-lg border border-gray-100 bg-white text-sm shadow-md dark:border-gray-700 dark:bg-gray-700 md:grid-cols-3`}
						>
							<div className='flex'>
								<div className='p-4 pb-0 text-gray-900 dark:text-white md:pb-4'>
									<ul className='flex' aria-labelledby='mega-menu-icons-dropdown-button'>
										{PLAYER_MANAGE_LIST.map((data: any) => {
											const isSelected = data.path === location.pathname;
											if (data.path === '/add-player' && !canAdd) {
												return <></>;
											}
											return (
												<li
													key={t(data.title)}
													className={`group mr-3 flex cursor-pointer items-center p-2 border-primary border rounded-md  ${
														isSelected ? 'bg-[#D6E9FF]' : 'hover:bg-lightBlue1'
													}`}
													onClick={() => {
														setNavMenu('');
														if (location.pathname !== data.path) {
															navigate(data.path);
														}
													}}
												>
													<div
														className={`mr-4 flex size-12 items-center justify-center rounded-lg border  ${
															isSelected
																? 'border-themeGray-text'
																: 'group-hover:border-themeGray-text'
														}`}
													>
														{data.icon}
													</div>
													<div className='flex flex-col'>
														<p className='text-base-responsive font-semibold text-textBlack'>
															{t(data.title)}
														</p>
														<p className='text-sm-responsive font-normal text-textGray'>
															{t(data.desc)}
														</p>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					)}
				</li>
				<li ref={recruitingRef}>
					<PermissionWrapper accessRoles={[ROLES.SCOUTING_RECRUITING]}>
						<button
							onClick={() => toggleDropdown('scouting_recruiting_dropdown')}
							className={`z-10 flex w-full items-center justify-between text-base-responsive pb-1 font-semibold dark:text-white md:w-auto md:hover:bg-transparent md:dark:hover:bg-transparent ${
								SCOUTING_PATH.includes(location.pathname)
									? 'border-b border-[#0057B7] text-textBlack'
									: 'hover:border-b hover:border-[#0057B7] text-textGray hover:text-textBlack'
							}`}
						>
							{t('scoutingRecruiting')}
							<DownArrowIcon className='ms-1 2xl:ms-3 size-2.5 stroke-[#344054]' />
						</button>
					</PermissionWrapper>

					{navMenu === 'scouting_recruiting_dropdown' && (
						<div className='absolute top-[70px] z-20 left-[50%] w-[calc(100vw-80px)] max-w-[1690px] transform -translate-x-1/2 flex flex-col rounded-lg border border-gray-200 bg-white text-sm shadow-lg'>
							<div className='p-4 flex items-center justify-between pb-0 text-gray-900 dark:text-white md:pb-4'>
								<ul className='flex w-full justify-between items-center gap-4 2xl:gap-6'>
									{RECRUITING_LIST.map((data: any) => {
										const isSelected = location.pathname === data.path;
										return (
											<li
												key={t(data.title)}
												className={`group w-[33%] flex justify-between border-primary border rounded-lg cursor-pointer items-center p-2 ${
													isSelected ? 'bg-[#D6E9FF]' : 'hover:bg-lightBlue1'
												}`}
												onClick={(e) => {
													e.stopPropagation();
													setNavMenu('');
													navigate(data.path);
												}}
											>
												<div className='flex'>
													<div
														className={`mr-4 flex size-12 items-center justify-center rounded-lg border  ${
															isSelected
																? 'border-themeGray-text'
																: 'group-hover:border-themeGray-text'
														}`}
													>
														{data.icon}
													</div>
													<div className={`flex flex-col justify-center`}>
														<div className='flex items-center'>
															<p
																className={`text-base-responsive font-semibold  mr-2 text-textBlack`}
															>
																{t(data.title)}
															</p>
															{data.isTooltip ? (
																<Tooltip
																	content={
																		<div>
																			{/*<p>
																				{`${ATHLETE['junior_athlete']} : `}
																				<span className='text-primary font-bold text-lg-responsive'>{`${data.tooltipContent['junior_athlete']}`}</span>
																			</p>
																			<p>
																				{`${ATHLETE['senior_athlete']} : `}
																				<span className='text-primary font-bold text-lg-responsive'>{`${data.tooltipContent['senior_athlete']}`}</span>
																			</p>*/}
																		</div>
																	}
																	theme={{
																		style: {
																			auto: 'bg-white border border-primary'
																		},
																		arrow: {
																			style: {
																				auto: 'bg-white border-primary border-l border-b'
																			}
																		}
																	}}
																	style={'light'}
																	color={'light'}
																	placement='top'
																>
																	<p
																		className={`p-[3px] 2xl:px-[6px] 2xl:py-[3px] text-sm-responsive rounded-full bg-[#1d953317] text-[#1E9634]`}
																		onClick={(e) => {
																			e.stopPropagation();
																			openModal(CommonModal.SHOW_LIST);
																			setTitle(t(data.title));
																		}}
																	>
																		{data.count}
																	</p>
																</Tooltip>
															) : (
																<>
																	{canView && (
																		<p
																			className={`p-[3px] 2xl:px-[6px] 2xl:py-[3px] text-sm-responsive rounded-full bg-[#1d953317] text-[#1E9634]`}
																			onClick={(e) => {
																				e.stopPropagation();
																				openModal(CommonModal.SHOW_LIST);
																				setTitle(t(data.title));
																			}}
																		>
																			{data.count}
																		</p>
																	)}
																</>
															)}
														</div>
														<p className={`text-sm-responsive font-normal text-textGray `}>
															{t(data.desc)}
														</p>
													</div>
												</div>
												{canAdd && (
													<div
														className={`flex items-center justify-center`}
														onClick={(e) => {
															e.stopPropagation();
															openModal(CommonModal.CREATE_SCOUT);
															setTitle(t(data.title));
														}}
													>
														{data.decIcon}
													</div>
												)}
											</li>
										);
									})}
								</ul>
							</div>
							<div className='flex items-center justify-between px-4 pb-4'>
								{canAdd && hasAthleteManagement && (
									<div className='flex gap-3'>
										<ButtonPrimary
											type='button'
											btnTxt='Quick Add'
											onClick={() => {
												openModal(CommonModal.QUICK_ADD);
												setNavMenu('');
											}}
											icon={<PlusIcon width='15px' className='stroke-white mr-2' />}
										/>
										<ButtonSecondary
											btnTxt='Full Registration'
											onClick={() => {
												navigate('/add-player');
												setNavMenu('');
											}}
										/>
									</div>
								)}
								<div
									className={`flex items-center ${
										hasAthleteManagement
											? 'justify-between w-[300px] lg:w-[420px]'
											: 'justify-end w-full'
									} `}
								>
									{hasAthleteManagement && canAdd && <ImportExportButton />}
									{canAdd && (
										<div>
											<TooltipContainer
												textClassName=' w-[150px]'
												placement='top'
												content={'Add multiple into Shortlist or Recruiting'}
											>
												<div
													className='bg-lightBlue1 flex items-center p-2 border border-themeGray rounded-lg cursor-pointer'
													onClick={() => {
														navigate('/all-athletes', {
															state: {
																screen: ScreenType.SELECTION_ATHLETE
															}
														});
														setNavMenu('');
													}}
												>
													<PlusIcon width='15px' className='stroke-primary mr-2' />
													<p className='text-sm-responsive text-primary font-semibold'>
														Add Multiple
													</p>
												</div>
											</TooltipContainer>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</li>
				<PermissionWrapper accessRoles={[ROLES.CONTRACT_MANAGEMENT]}>
					<li onClick={() => navigate('/contract-management?type=Tournament')} className='cursor-pointer'>
						<a
							className={`flex w-full items-center justify-between text-base-responsive pb-1 font-semibold dark:text-white md:w-auto md:hover:bg-transparent md:dark:hover:bg-transparent ${
								CONTRACT_MANAGEMENT.some((path: string) =>
									matchPath({ path, end: true }, location.pathname)
								)
									? 'border-b border-[#0057B7] text-textBlack'
									: 'hover:border-b hover:border-[#0057B7] text-textGray hover:text-textBlack'
							}`}
						>
							{t('contractManagement')}
						</a>
					</li>
				</PermissionWrapper>
			</ul>
			{isOpen && type === CommonModal.CREATE_SCOUT && (
				<>
					{title === 'Scouted Athletes' && (
						<CreateShortListModal
							type={CommonModal.CREATE_SCOUT}
							show={isOpen}
							onClose={closeModal}
							defaultValues={defaultValues}
							isEdit={false}
						/>
					)}
					{title === 'Recruited Athletes' && (
						<CreateBoardModal
							type={CommonModal.CREATE_SCOUT}
							show={isOpen}
							onClose={closeModal}
							defaultValues={DashboardDefaultValues}
							isEdit={false}
						/>
					)}
				</>
			)}
			{isOpen && type === CommonModal.SHOW_LIST && (
				<>
					{title === 'Scouted Athletes' && (
						<ViewShortListModal show={isOpen} onClose={closeModal} sport={sport} />
					)}
					{title === 'Recruited Athletes' && (
						<ViewDashboardModal show={isOpen} onClose={closeModal} sport={sport} />
					)}
				</>
			)}
			{isOpen && type === CommonModal.QUICK_ADD && (
				<QuickAdd
					isOpenModal={isOpen}
					handleClickClose={closeModal}
					handleClose={() => {
						closeModal();
						window.location.reload();
					}}
				/>
			)}
		</div>
	);
};

export default Navigation;
