import React, { FC, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { HidePasswordIcon, InputErrorIcon, ShowPasswordIcon, StarIcon } from 'shared/icon';

interface IProp {
	name: string;
	title: string;
	type?: string;
	errorMsg?: string;
	placeholder: string;
	required?: boolean;
	disabled?: boolean;
	register: UseFormRegister<any>;
	className?: string;
	isLabel?: boolean;
	isPassword?: boolean;
}

export const Input: FC<IProp> = ({
	name,
	title,
	type,
	errorMsg,
	placeholder,
	required,
	register,
	className,
	disabled,
	isPassword,
	isLabel = true
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (type === 'number') {
			const value = parseFloat(e.target.value);
			e.target.value = value < 0 ? '0' : e.target.value;
		}
	};

	const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
		if (type === 'number') e.currentTarget.blur();
	};

	return (
		<div className={`min-h-[64px] lg:min-h-[64px] 2xl:min-h-[84px] ${className}`}>
			{isLabel && (
				<label htmlFor={name} className={`input-label ${disabled ? 'text-themeGray-text' : ''}`}>
					{title}{' '}
					{required && (
						<sub className='bottom-[-1px]'>
							<StarIcon height='7px' width='7px' />
						</sub>
					)}
				</label>
			)}
			<div className='relative'>
				<input
					{...register(name, { valueAsNumber: !!type && type === 'number' })}
					type={showPassword ? 'text' : type || 'text'}
					id={name}
					className={`flex w-full rounded-lg border pr-8 text-sm-responsive ${
						disabled && 'text-themeGray-text font-semibold'
					} ${errorMsg ? 'border-red-600' : 'border-themeGray'}`}
					placeholder={placeholder}
					disabled={disabled ?? false}
					onWheel={handleWheel}
					onInput={handleInput}
				/>

				{errorMsg && !isPassword && (
					<div className='pointer-events-none absolute inset-y-0 end-0 flex items-center pe-3'>
						<InputErrorIcon />
					</div>
				)}
				{isPassword && (
					<div
						className=' absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer'
						onClick={() => {
							setShowPassword(!showPassword);
						}}
					>
						{!showPassword ? (
							<HidePasswordIcon className='fill--white' />
						) : (
							<ShowPasswordIcon className='fill--comet' />
						)}
					</div>
				)}
			</div>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};
