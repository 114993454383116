import { FC } from 'react';
import ProgressBar from './progressBar';
import { useAuthContext } from 'store/userDetailStore';
import StarRating from 'shared/rating/rating';

const ScoreCard: FC<{ title: string; score: number; isSmall: boolean; className?: string }> = ({
	title,
	score,
	isSmall,
	className
}) => {
	const { isTennis } = useAuthContext();

	return (
		<div className={`${className} relative xl:w-[47%] w-96`}>
			<div className='flex items-start justify-between'>
				<p
					className={`mt-4 2xl:mt-2 text-left ${
						isSmall ? 'text-sm-responsive' : 'text-base-responsive'
					} font-semibold w-full max-w-[120px]`}
				>
					{title ?? '-'}
				</p>
				{!isTennis && <ProgressBar score={score} />}
				{isTennis && (
					<div className='h-[60px] 2xl:h-20 min-w-[130px]'>
						<StarRating
							className='mt-3 2xl:mt-1'
							initialRating={score}
							isDisabled
							fractions={2}
							size='24'
						/>
					</div>
				)}
			</div>
			<hr className='absolute top-16 w-full' />
		</div>
	);
};

export default ScoreCard;
