import React, { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListGroup } from 'flowbite-react';

import TableRow from 'shared/components/table/tableRow';
import TableCell from 'shared/components/table/tableCell';
import { formatDate } from 'shared/util/utility';
import { DotMenuIcon } from 'shared/icon';
import { IPlayers, ViewType } from '../interface';
import { FilterModal } from '../container';
import useModal from 'shared/hooks/useModal';
import ConfirmModal from 'shared/components/confirmModal';
import TooltipContainer from 'shared/components/tooltip';
import ProfilePic from 'shared/components/profilePic';
import Gender from 'shared/components/gender';
import ExpandButton from 'shared/components/playerComparison/expandButton';
import { ITableCell } from 'shared/components/table/tableHeader';

import ExpandRow from './expandRow';
import Perform from './perform';
import usePermission from 'shared/components/permissions/usePermissions';
import useOnClickOutside from 'shared/hooks/useOutSideClick';

interface IProps {
	playerData: IPlayers[];
	handleDeletePlayer: (id: string, sport: string) => void;
	rows: ITableCell[];
}

const PlayerList: React.FC<IProps> = (props) => {
	const { playerData, rows, handleDeletePlayer } = props;
	const [actionId, setActionId] = useState('');
	const [player, setPlayer] = useState<IPlayers>({} as IPlayers);
	const { isOpen, type, closeModal, openModal } = useModal<FilterModal>();

	const [expandRowId, setExpandRowId] = useState('');

	const { canView, canEdit, canDelete } = usePermission();

	const navigate = useNavigate();
	const popupRef = useRef(null);

	useOnClickOutside(popupRef, () => {
		setActionId('');
	});

	// Function to calculate the difference between two dates in months
	const getMonthsDifference = (startDate: Date, endDate: Date): number => {
		const diffInMs = endDate.getTime() - startDate.getTime();
		const diffInMonths = diffInMs / (1000 * 60 * 60 * 24 * 30.44); // Approximate months
		return Math.floor(diffInMonths);
	};

	const getContractStatus = (retainerEndDate: string) => {
		const today = new Date();
		const monthsDifference = getMonthsDifference(today, new Date(retainerEndDate));

		if (monthsDifference < 3) {
			return 'bg-[#FF3B30]';
		} else if (monthsDifference >= 3 && monthsDifference < 6) {
			return 'bg-[#FF9500]';
		} else if (monthsDifference >= 6 && monthsDifference < 12) {
			return 'bg-[#0057B7]';
		} else {
			return 'bg-[#34C759]';
		}
	};

	const getContractTooltipText = (monthsDifference: number) => {
		if (monthsDifference < 3) {
			return 'Contract expires in less than 3 months';
		} else if (monthsDifference >= 3 && monthsDifference < 6) {
			return 'Contract expires in 3 to less than 6 months';
		} else if (monthsDifference >= 6 && monthsDifference < 12) {
			return 'Contract expires in 6 to less than 12 months';
		} else {
			return 'Contract expires in 12 months or more';
		}
	};

	const getCell = (key: string, record: IPlayers) => {
		switch (key) {
			case 'playerName':
				return (
					<div className='flex cursor-pointer items-center justify-start ml-3'>
						<ExpandButton
							isSelected={record.id === expandRowId}
							onClick={() => setExpandRowId(expandRowId === record.id ? '' : record.id)}
						/>
						<div className='curser-pointer flex items-center'>
							<ProfilePic
								profileUrl={record.profile_picture}
								playerName={`${record.first_name} ${record.last_name}`}
							/>
						</div>
					</div>
				);
			case 'sport':
				return <>{record.sports || '-'}</>;
			case 'country':
				return (
					<span>
						{record.country.emoji} &nbsp;
						{record.country.iso3 || '-'}
					</span>
				);
			case 'gender':
				return <Gender data={record.gender} className='flex items-center justify-center' />;

			case 'worldRank':
				return (
					<p className='truncate w-28' title={record.athlete_id}>
						{record.athlete_id || '-'}
					</p>
				);

			case 'circuitLvl':
				return <>{record.circuit_level || '-'}</>;
			case 'perform': {
				return <Perform performData={record.performance} />;
			}
			case 'totalFollower':
				return (
					<p className='truncate max-w-32' title={record.total_social_media_count}>
						{record.total_social_media_count || '-'}
					</p>
				);
			case 'marketabilityC':
				return <>{record.marketability_current || '-'}</>;
			case 'marketabilityP':
				return <>{record.marketability_potential || '-'}</>;
			case 'yonexLvl':
				return <>{record.performance_profile?.team_yonex_senior_level || '-'}</>;
			case 'status':
				return <>{record.status || '-'}</>;
			case 'expDate':
				return (
					<div className='flex items-center justify-center'>
						{formatDate(record.contract.retainer_term_end) || '-'}

						{record.contract.retainer_term_end && (
							<TooltipContainer
								content={getContractTooltipText(
									getMonthsDifference(new Date(), new Date(record.contract.retainer_term_end))
								)}
								style={'light'}
								color={'light'}
								textClassName='w-[300px]'
							>
								<div
									className={`size-3 rounded-full ml-1 cursor-pointer ${getContractStatus(
										record.contract.retainer_term_end
									)} `}
								/>
							</TooltipContainer>
						)}
					</div>
				);

			case 'action':
				return (
					<div className='relative'>
						{(canEdit || canDelete) && (
							<DotMenuIcon
								className='cursor-pointer stroke-textGray'
								onClick={(event: any) => {
									event.stopPropagation();
									setActionId(actionId === record.id ? '' : record.id);
								}}
							/>
						)}
						{actionId === record.id && (
							<div className='absolute right-4 top-5 z-20' ref={popupRef}>
								<ListGroup className='w-36' onClick={(e) => e.stopPropagation()}>
									{canEdit && (
										<ListGroup.Item onClick={() => navigate(`/add-player/${record.id}`)}>
											Edit Athlete
										</ListGroup.Item>
									)}
									{canDelete && (
										<ListGroup.Item
											onClick={() => {
												openModal(FilterModal.DELETE_ATHLETE);
												setPlayer(record);
												setActionId('');
											}}
										>
											Delete Athlete
										</ListGroup.Item>
									)}
								</ListGroup>
							</div>
						)}
					</div>
				);

			default:
				return <p>-</p>;
		}
	};

	return (
		<>
			{playerData &&
				playerData.length > 0 &&
				playerData.map((data: IPlayers) => {
					return (
						<Fragment key={data.id}>
							<TableRow
								onClick={() => {
									canView && navigate(`/player-profile/${data.id}`);
								}}
								className='cursor-pointer tr-left-border'
							>
								{rows.map((cell) => (
									<TableCell key={cell.title} className='text-center'>
										{getCell(cell.key as string, data)}
									</TableCell>
								))}
							</TableRow>

							{expandRowId === data.id && (
								<TableRow>
									<TableCell colSpan={rows.length} className='px-5'>
										<ExpandRow record={data} viewType={ViewType.Table_View} />
									</TableCell>
								</TableRow>
							)}
						</Fragment>
					);
				})}
			{isOpen && type === FilterModal.DELETE_ATHLETE && (
				<ConfirmModal
					btnPrimaryTxt='Yes'
					btnSecondaryTxt='No'
					show={isOpen}
					onClose={closeModal}
					onSubmit={() => {
						handleDeletePlayer(player.id, player.sports);
						closeModal();
					}}
					primaryText={`Are you sure you want to delete ${player.first_name} ${player.last_name}? `}
				/>
			)}
		</>
	);
};

export default PlayerList;
