import React, { useEffect, useState } from 'react';
// import { Carousel } from 'flowbite-react';

// import loginLogoOne from 'assets/images/login-page-logo.png';
// import loginLogoTwo from 'assets/images/forgot-password-logo.png';
// import loginLogoThree from 'assets/images/login_logo_3.png';
// import loginLogoFour from 'assets/images/login_logo_4.png';
// import YonexLogo from 'assets/images/white_logo.png';
import { NextEdgeLogo } from 'shared/icon';

import Img1 from 'assets/images/bg-img1.png';
import Img2 from 'assets/images/bg-img2.png';

const AuthHoc: React.FC<React.PropsWithChildren> = ({ children }) => {
	const images = [Img1, Img2];

	// State for the current image index
	const [currentImageIndex, setCurrentImageIndex] = useState(Math.floor(Math.random() * images.length));
	const [isFading, setIsFading] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsFading(true); // Start fade-out
			setTimeout(() => {
				setCurrentImageIndex((prev) => (prev + 1) % images.length); // Update image
				setIsFading(false); // Start fade-in
			}, 1500); // Match CSS transition duration
		}, 5000); // Delay between transitions

		return () => clearInterval(interval); // Clean up interval on component unmount
	}, []);

	return (
		<div className='relative w-full h-screen overflow-hidden'>
			{/* Current Image */}
			<div
				className={`absolute inset-0  ${
					isFading ? ' transition-opacity duration-[1500ms] opacity-0' : 'opacity-1'
				}`}
				style={{
					backgroundImage: `url(${images[currentImageIndex]})`,
					backgroundSize: 'cover',
					backgroundAttachment: 'fixed',
					backgroundRepeat: 'no-repeat',
					zIndex: 1
				}}
			/>

			{/* Next Image */}
			<div
				className={`absolute inset-0  ${
					isFading ? 'opacity-1 transition-opacity duration-[1500ms]' : 'opacity-0'
				}`}
				style={{
					backgroundImage: `url(${images[(currentImageIndex + 1) % images.length]})`,
					backgroundSize: 'cover',
					backgroundAttachment: 'fixed',
					backgroundRepeat: 'no-repeat',
					zIndex: 0
				}}
			/>

			{/* Content Layer */}
			<section className='relative z-10 h-screen flex items-center justify-left xl:max-w-[1440px]'>
				<div className='flex-col justify-between bg-white w-2/6 max-w-[520px] min-w-[434px] px-[60px] py-[60px] ml-[6%] rounded-[20px] shadow-lg'>
					{children}
					<div className='flex justify-center items-center gap-3 mt-7'>
						<p className='text-[13px] font-normal text-textGray'>Powered By</p>
						<NextEdgeLogo height='40px' width='129px' />
					</div>
				</div>
			</section>
		</div>
	);
	// return (
	// 	<section className='mx-auto bg-white xl:max-w-[1440px]'>
	// 		<div className='h-screen flex items-center justify-end lg:justify-center relative'>
	// 			<div className='mx-auto hidden w-2/6 max-w-[440px] px-5 lg:block'>
	// 				{<Outlet />}
	// 				<div className='flex justify-center items-center gap-3 absolute bottom-5 left-[17%]'>
	// 					<p className='text-[13px] font-normal text-textGray'>Powered By</p>
	// 					<NextEdgeLogo height='40px' width='129px' />
	// 				</div>
	// 			</div>
	// 			<div className='relative w-4/5 object-right lg:w-1/2'>
	// 				<div className='absolute left-[-180px] top-16 z-[1] mx-auto w-[400px] rounded-lg bg-white p-5 lg:static lg:hidden lg:w-1/3 2xl:w-1/4'>
	// 					{<Outlet />}
	// 				</div>
	// 				<div className='m-5'>
	// 					<Carousel slideInterval={3000} indicators={false}>
	// 						{ImageArray.map((data, i) => {
	// 							return (
	// 								<div className='relative' key={i}>
	// 									<img
	// 										className='h-[calc(100vh-40px)] max-h-[1240px] w-full rounded-2xl object-cover'
	// 										src={data.imageUrl}
	// 										alt='login form'
	// 									/>
	// 									<img src={YonexLogo} alt='yonex-logo' className='absolute right-8 top-6' />
	// 									<div className={`absolute right-8 flex flex-col items-end ${data.className}`}>
	// 										<p className='text-base'>Global Athlete Access Management</p>
	// 										<p className='text-[17px] font-semibold'>Revolutionizing the Game</p>
	// 									</div>
	// 								</div>
	// 							);
	// 						})}
	// 					</Carousel>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</section>
	// );
};

export default AuthHoc;

// const ImageArray = [
// 	{
// 		imageUrl: loginLogoOne,
// 		className: 'text-white bottom-7'
// 	},
// 	{
// 		imageUrl: loginLogoTwo,
// 		className: 'text-textBlack bottom-7'
// 	},
// 	{
// 		imageUrl: loginLogoThree,
// 		className: 'text-textBlack bottom-7'
// 	},
// 	{
// 		imageUrl: loginLogoFour,
// 		className: 'text-textBlack top-16'
// 	}
// ];
