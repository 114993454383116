import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Modal, Spinner } from 'flowbite-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { EditIcon, PlusIcon } from 'shared/icon';
import Select from 'shared/form/select/select';
import ButtonPrimary from 'shared/components/buttons/primary';
import { useAthleteContext } from 'store/athleteStore';
import SliderSelect from 'shared/form/select/sliderSelect';
import ButtonSecondary from 'shared/components/buttons/secondary';
import { IKpiDataNew } from 'features/addPlayer/interface';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { Input } from 'shared/form/input';
import { cleanObject } from 'shared/util/utility';
import Loader from 'shared/components/spinner/spinner';
import { FILTERS, IFilters, initFilters, initOtherFilters } from 'features/athleteDatabase/interface';
import {
	ATHLETES_OPTIONS,
	CIRCUIT_LEVEL_OPTIONS,
	DISCIPLINE_OPTIONS,
	GENDER_OPTIONS,
	STATUS_OPTIONS,
	TENNIS_CIRCUIT_LEVEL_OPTIONS,
	TENNIS_DISCIPLINE_OPTIONS,
	YONEX_ATHLETE
} from 'features/athleteDatabase/constant';
import { useAuthContext } from 'store/userDetailStore';
import StarRating from 'shared/rating/rating';

interface IProps {
	show: boolean;
	filters: IFilters;
	onClose: () => void;
	onChangeFilter: (filters: any) => void;
	setSavedFilterName: (name: string) => void;
}

export type FilterValues = {
	sport: string;
	circuit_level: string;
	category: string;
	country_id: number | string;
	status: string;
	gender: string;
	player_name: string;
};

const schema = Yup.object({
	name: Yup.string().trim().required('Filter name is required')
});

const PlayerFilterModal: React.FC<IProps> = ({ show, filters, onClose, onChangeFilter, setSavedFilterName }) => {
	const { countryOptions, kpiData, tennisKpiData, kpiLoading } = useAthleteContext();
	const { isTennis } = useAuthContext();
	const kpis = useMemo(() => (isTennis ? tennisKpiData : kpiData), [isTennis, tennisKpiData, kpiData]);
	const apiPath = useMemo(() => (isTennis ? API_CONFIG.tennisFilters : API_CONFIG.filters), [isTennis]);

	const [isLoading, setLoading] = useState(false);
	const [mode, setMode] = useState('');
	const [savedFilter, setSavedFilter] = useState([] as { id: string; name: string }[]);
	const [kpiValues, setKpiValues] = useState<{ [key: number]: number }>({});
	const [editFilterId, setEditFilterId] = useState('');

	const fetchFilters = useCallback(async () => {
		try {
			setLoading(true);
			const res = await HttpService.get(apiPath);
			setSavedFilter(res?.data ?? []);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [apiPath]);

	useEffect(() => {
		const formValue = {
			[FILTERS.SPORTS]: filters.sport,
			[FILTERS.COUNTRY]: filters.country_id,
			[FILTERS.CIRCUIT_LEVEL]: filters.circuit_level,
			[FILTERS.CATEGORY]: filters.category,
			[FILTERS.STATUS]: filters.status,
			[FILTERS.GENDER]: filters.gender,
			[FILTERS.SEARCH]: filters.player_name
		};
		reset(formValue as FilterValues);
		fetchFilters();
		if (filters.kpis && filters.kpis !== '') {
			const serializedFilters = decodeURIComponent(filters.kpis as string);
			const kpiArray = JSON.parse(serializedFilters);
			setKpiValues(() => {
				const kpiObj: { [key: number]: number } = {};
				kpiArray.forEach(({ kpi_id, value }: any) => {
					kpiObj[kpi_id] = value;
				});
				return kpiObj;
			});
		}
	}, []);

	const isAddEdit = useMemo(() => !isEmpty(mode), [mode]);

	const {
		handleSubmit,
		control,
		reset,
		register,
		formState: { errors }
	} = useForm<any>({
		defaultValues: initOtherFilters,
		resolver: yupResolver(isAddEdit ? schema : Yup.object())
	});

	const onSubmit: SubmitHandler<FilterValues> = async (data) => {
		let formValues: any = { ...data };

		let KPIArray: { kpi_id: number; value: number }[] = [];
		const isKPIValues = !isEmpty(kpiValues);

		if (isKPIValues) {
			KPIArray = Object.entries(kpiValues).map(([kpi_id, value]) => ({
				kpi_id: Number(kpi_id),
				value
			}));
			formValues = { ...data, [FILTERS.KPI]: KPIArray };
		}
		if (isAddEdit) {
			try {
				const params = cleanObject(formValues);
				if (editFilterId) {
					await HttpService.put(`${apiPath}/${editFilterId}`, params);
					setSavedFilter((prevState) => {
						const updatedFilter = prevState.map((filter) => {
							if (filter.id === editFilterId) {
								return {
									...filter,
									name: formValues.name // Update the name property
								};
							}
							return filter;
						});
						return updatedFilter;
					});
					reset(initOtherFilters);
					setKpiValues({});
					setMode('');
				} else {
					const res = await HttpService.post(apiPath, params);
					if (res.data?.filter?.id) {
						ApplySavedFilter(res.data?.filter?.id, res.data?.filter?.name);
					}
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			if (isKPIValues) {
				const serializedFilters = encodeURIComponent(JSON.stringify(KPIArray));
				formValues = { ...data, [FILTERS.KPI]: serializedFilters };
			}
			changeAndCloseFilter({ ...formValues, [FILTERS.FILTER_ID]: '' });
		}
	};

	const getFilterDetails = async (id: string) => {
		try {
			const res = await HttpService.get(`${apiPath}/${id}`);
			reset(res.data);
			setMode('Edit');
			setKpiValues(() => {
				const kpiObj: { [key: number]: number } = {};
				res.data.kpis.forEach(({ kpi_id, value }: any) => {
					kpiObj[kpi_id] = value;
				});
				return kpiObj;
			});
		} catch (error) {
			console.log(error);
		}
	};

	const ApplySavedFilter = (id: string, filterName = '') => {
		const name = savedFilter.find((filter) => filter.id === id);
		setSavedFilterName(name?.name || filterName || '');
		changeAndCloseFilter({ ...initOtherFilters, [FILTERS.FILTER_ID]: id });
	};

	const resetFilters = useCallback(() => {
		reset(initOtherFilters);
		setKpiValues({});
	}, [setKpiValues, reset]);

	const changeAndCloseFilter = useCallback(
		(filters: any) => {
			if (filters.status) {
				filters = {
					...filters,
					[FILTERS.ATHLETE]: ATHLETES_OPTIONS[YONEX_ATHLETE.includes(filters.status) ? 0 : 1]?.value ?? ''
				};
			}
			onChangeFilter({ ...filters });
			onClose();
		},
		[onChangeFilter, onClose]
	);

	const OPTIONS = useMemo(() => {
		return isTennis ? TENNIS_CIRCUIT_LEVEL_OPTIONS : CIRCUIT_LEVEL_OPTIONS;
	}, [isTennis]);

	const CATEGORY_OPTIONS = useMemo(() => {
		return isTennis ? TENNIS_DISCIPLINE_OPTIONS : DISCIPLINE_OPTIONS;
	}, [isTennis]);

	return (
		<Modal show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg bg-lightBlue'>
				<p className='header-txt'>{isAddEdit ? 'Filters' : 'Stats Filter Settings'}</p>
				{isAddEdit && (
					<p className='sub-header-txt'>
						Select a rank of Athletes in one or more stats. e. g. top 20% of Athletes by number of games
						played
					</p>
				)}
			</Modal.Header>
			<Modal.Body>
				{kpiLoading && (
					<div className='h-[700px]'>
						<Loader size={24} />
					</div>
				)}

				{!kpiLoading && (
					<form>
						<div className='bg-lightBlue1 rounded-xl px-3 py-2 mb-[10px]'>
							{isAddEdit ? (
								<>
									<p className='header-txt text-sm'>Saved Filters</p>
									<Input
										register={register}
										placeholder='Add filter name'
										name='name'
										title='Name'
										className='mt-3 min-h-[unset]'
										isLabel={false}
										required
										errorMsg={errors?.['name']?.message || ('' as any)}
									/>
								</>
							) : (
								<>
									<p className='text-sm mb-1'>
										You can create, adjust your own filter and save for future analysis
									</p>
									<div className='flex items-center cursor-pointer'>
										<PlusIcon className='size-2.5 stroke-primary mr-1' />
										<p
											className='text-primary font-semibold text-sm '
											onClick={() => {
												setMode('Add');
												resetFilters();
											}}
										>
											Create Filter
										</p>
									</div>
								</>
							)}
						</div>
						{!isAddEdit && (
							<div className='border-themeGray border rounded-lg p-2 mb-2'>
								<p className='text-primary text-sm font-semibold mb-1'>Saved Filters</p>
								{isLoading && (
									<div className='text-center flex items-center justify-center mt-3'>
										<Spinner aria-label='Extra large Center-aligned spinner example' size='md' />
									</div>
								)}
								{isEmpty(savedFilter) && !isLoading && (
									<p className='text-center text-sm text-themeGray-text'>No Saved Filters Found</p>
								)}
								{!isEmpty(savedFilter) && (
									<div className='flex flex-wrap'>
										{savedFilter.map(({ name, id }) => (
											<div className='relative my-1 group' key={id}>
												<div
													className='border p-1 text-xs max-w-[120px] truncate rounded-md cursor-pointer mr-1'
													onClick={() => {
														ApplySavedFilter(id, name);
														onClose();
													}}
												>
													{name}
												</div>
												<EditIcon
													height='18px'
													width='14px'
													onClick={() => {
														getFilterDetails(id);
														setEditFilterId(id);
													}}
													className='stroke-primary mr-2 cursor-pointer absolute top-[-7px] right-[-7px] hidden bg-white group-hover:block'
												/>
											</div>
										))}
									</div>
								)}
							</div>
						)}
						<p className='text-textBlack font-semibold text-md mt-1'>General Metrics</p>
						<div className='flex flex-col'>
							<div className='grid grid-cols-2 gap-3 mt-2'>
								{/*<Select
									placeHolder='Sport'
									control={control}
									name='sport'
									title='Sport'
									options={SPORTS_OPTIONS}
									isLabel={false}
								/>*/}
								<Select
									placeHolder='Country'
									control={control}
									name='country_id'
									title='Country'
									options={countryOptions}
									isLabel={false}
								/>
								<Select
									placeHolder='Circuit lvl.'
									control={control}
									name='circuit_level'
									title='Circuit Lvl.'
									options={OPTIONS}
									isLabel={false}
								/>
								<Select
									placeHolder='Category'
									control={control}
									name='category'
									title='category'
									options={CATEGORY_OPTIONS}
									isLabel={false}
								/>
								{!isTennis && (
									<Select
										placeHolder='Status'
										control={control}
										name='status'
										title='Status'
										options={STATUS_OPTIONS}
										isLabel={false}
									/>
								)}
								<Select
									placeHolder='Gender'
									control={control}
									name='gender'
									title='Gender'
									options={GENDER_OPTIONS}
									isLabel={false}
								/>
							</div>
							<p className='text-textBlack font-semibold text-md mt-3'>Performance Metrics</p>
							{kpis && kpis.length > 0 && (
								<div className='grid grid-cols-2 gap-x-4'>
									{!isEmpty(kpis) &&
										kpis.map((data: IKpiDataNew) => {
											return !isTennis ? (
												<div className='mt-3' key={data.title}>
													<SliderSelect
														kpiValues={kpiValues}
														handleChangeSlider={(kpiId, value) => {
															setKpiValues((prevState) => ({
																...prevState,
																[kpiId]: value // Update the value for the specific KPI
															}));
														}}
														placeHolder={data.title}
														control={control}
														name=''
														options={data.kpisData}
													/>
												</div>
											) : (
												<>
													{data.kpisData.map((kpi) => (
														<div className='mt-3'>
															<label
																className={`text-sm-responsive flex font-semibold text-textBlack capitalize dark:text-gray-300 mb-1`}
															>
																{kpi.name}
															</label>
															<StarRating
																className='2xl:mt-1'
																initialRating={kpiValues[kpi.id] || 0}
																fractions={2}
																onClick={(val) => {
																	setKpiValues((prevState) => ({
																		...prevState,
																		[kpi.id]: val // Update the value for the specific KPI
																	}));
																}}
																size='24'
															/>
														</div>
													))}
												</>
											);
										})}
								</div>
							)}
						</div>
					</form>
				)}
				<div className='flex gap-3 mt-3'>
					<ButtonSecondary
						className='w-1/2'
						btnTxt={isAddEdit ? 'Back' : 'Reset filter'}
						onClick={() => {
							resetFilters();
							if (isAddEdit) {
								setMode('');
							} else {
								changeAndCloseFilter({ ...initFilters });
							}
						}}
					/>
					<ButtonPrimary
						className='w-1/2'
						btnTxt={isAddEdit ? (mode === 'Add' ? 'Create and apply filter' : 'Update') : 'Apply'}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default PlayerFilterModal;
const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	header: {
		base: 'flex items-start justify-between rounded-t p-3 dark:border-gray-600'
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[580px]'
	},
	body: {
		base: 'flex-1 px-3 pb-3 overflow-auto custom-scrollbar'
	}
};
