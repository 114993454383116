import { FC, MouseEvent, ReactElement } from 'react';
import { Button, Spinner } from 'flowbite-react';

interface IProps {
	btnTxt: string;
	className?: string;
	isLoading?: boolean;
	type?: 'reset' | 'button';
	disable?: boolean;
	onClick: (e: MouseEvent) => void;
	icon?: ReactElement;
}

const ButtonPrimary: FC<IProps> = ({ className, btnTxt, type, disable, isLoading, onClick, icon }) => {
	return (
		<Button
			type={type || 'submit'}
			className={`ring-none bg-[#0057B7] font-semibold text-white ${className} disabled:bg-primary disabled:text-themeGray-text`}
			color='blue'
			theme={ButtonTheme}
			onClick={onClick}
			disabled={disable || isLoading}
		>
			{icon && icon}
			{isLoading && <Spinner aria-label='Spinner button' className='mr-2' size='sm' theme={Theme} />}
			{btnTxt}
		</Button>
	);
};

export default ButtonPrimary;

const Theme = {
	color: {
		info: 'fill-primary'
	},
	isProcessing: 'cursor-wait'
};

export const ButtonTheme = {
	size: {
		md: 'px-2 lg:px-2 2xl:px-4 py-1.5 lg:py-1.5 2xl:py-2 text-sm-responsive'
	},
	base: 'group relative flex items-center justify-center p-0.5 text-center font-medium transition-[color,background-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none'
};
