import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import useFlowbite from 'shared/hooks/useFlowbite';
import { CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';
import { IDropdownOptions } from 'shared/interface';

interface IProps {
	name: string;
	title: string;
	options: IDropdownOptions[];
	className?: string;
	errorMsg: string;
	control: any;
	placeHolder?: string;
	menuPosition?: string;
	required?: boolean;
	disabled?: boolean;
	leftPosition?: string;
	bottomPosition?: string;
	translate?: string;
}

const NestedSelect: FC<IProps> = ({
	name,
	title,
	options,
	className,
	control,
	errorMsg,
	placeHolder,
	menuPosition,
	required,
	bottomPosition = 24,
	leftPosition = 44,
	disabled = false,
	translate
}) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [nestedDropdownVisible, setNestedDropdownVisible] = useState('');
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	// Handle outside click
	const handleClickOutside = (event: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		// Add event listener to handle outside clicks
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	const closeDropdown = () => {
		setDropdownVisible(false);
		setNestedDropdownVisible('');
	};

	const toggleNestedDropdown = (selectedValue: string) => {
		const value = nestedDropdownVisible ? '' : selectedValue;
		setNestedDropdownVisible(value);
	};

	const getLabel = (selectedValue: string, selectedSubvalue: string) => {
		if (!selectedSubvalue) {
			return selectedValue;
		}

		// Find the main option that matches the selected value
		const mainOption = options.find((option) => option.value === selectedValue);

		// If the main option is found and has nested options, find the nested option
		if (mainOption && mainOption.nestedOptions) {
			const subOption = mainOption.nestedOptions.find((option) => option.value === selectedSubvalue);

			// If both the main option and the nested option are found, return the formatted label
			if (subOption) {
				return `${subOption.label} (${mainOption.label})`;
			}
		}

		// Return null or an empty string if no matching option is found
		return null;
	};

	return (
		<div className={`${className || ''} relative w-full`} ref={dropdownRef}>
			<label htmlFor={name} className={`input-label ${disabled ? 'text-themeGray-text' : ''}`}>
				{title}{' '}
				{required && (
					<span className='text-xs'>
						<StarIcon height='7px' width='7px' />
					</span>
				)}
			</label>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => {
					const [selectedMenu, selectedSubMenu] = value?.split('~') || [];
					return (
						<>
							<button
								id='multiLevelDropdownButton'
								disabled={disabled}
								className={`${disabled && 'cursor-not-allowed'} input-select ring-none ${
									!value && 'text-themeGray-text'
								}`}
								type='button'
								color='white'
								onClick={toggleDropdown}
							>
								{selectedMenu ? getLabel(selectedMenu, selectedSubMenu) : placeHolder || 'Select'}
								{value && value.length > 0 ? (
									<button
										className='h-3 w-4 flex justify-end cursor-pointer'
										onClick={(e) => {
											onChange(null);
											e.stopPropagation();
										}}
									>
										<CloseIcon className='size-2.5 stroke-[#344054] ' />
									</button>
								) : (
									<DownArrowIcon className='size-2.5 stroke-[#344054]' />
								)}
							</button>

							<div
								id='multi-dropdown'
								className={`z-10 absolute w-full divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700  ${
									dropdownVisible ? 'block' : 'hidden'
								}`}
								style={
									menuPosition === 'top'
										? {
												inset: 'auto auto 0px 0px',
												margin: '0px',
												transform: 'translate(0px,-54px)'
										  }
										: {}
								}
							>
								{/* {dropdownVisible && ( */}
								<ul
									className='py-2 text-sm text-gray-700 dark:text-gray-200 border border-gray-300 rounded-lg'
									aria-labelledby='multiLevelDropdownButton'
								>
									{options.map((option) =>
										option.nested ? (
											<li key={option.value}>
												<button
													id='doubleDropdownButton'
													// data-dropdown-toggle='doubleDropdown'
													// data-dropdown-placement='top-start'
													type='button'
													className={`relative text-sm-responsive flex w-full items-center justify-between px-4 py-2  dark:hover:bg-gray-600 dark:hover:text-white  ${
														selectedMenu === option.value
															? 'bg-lightBlue1'
															: nestedDropdownVisible === option.value
															? 'bg-lightBlue1'
															: 'bg-white hover:bg-gray-100'
													}`}
													onClick={() => toggleNestedDropdown(option.value)}
												>
													{option.label}
													<svg
														className='ms-3 size-2.5 rtl:rotate-180'
														aria-hidden='true'
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 6 10'
													>
														<path
															stroke='currentColor'
															strokeLinecap='round'
															strokeLinejoin='round'
															strokeWidth='2'
															d='m1 9 4-4-4-4'
														/>
													</svg>
													<div
														id='doubleDropdown'
														className={`absolute bottom-${bottomPosition} left-[${leftPosition}] z-10 w-60 max-h-72 overflow-auto custom-scrollbar divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700 ${
															nestedDropdownVisible === option.value ? 'block' : 'hidden'
														}`}
														style={
															menuPosition === 'top' || menuPosition === 'bottom'
																? {
																		inset: '0px auto auto 0px',
																		margin: '0px',
																		transform: `translate(${
																			translate ? translate : '290px'
																		}, 0)`
																  }
																: {}
														}
													>
														{/* {nestedDropdownVisible && ( */}
														<ul
															className='py-2 text-sm-responsive text-gray-700 dark:text-gray-200 border border-gray-300 rounded-lg'
															aria-labelledby='doubleDropdownButton'
														>
															{option.nestedOptions?.map((nestedOption) => (
																<li
																	key={nestedOption.value}
																	onClick={() => {
																		onChange(
																			`${option.value}~${nestedOption.value}`
																		);
																		closeDropdown();
																	}}
																>
																	<div
																		className={`block text-left text-sm-responsive px-4 py-2 hover:bg-lightBlue1 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer ${
																			selectedMenu === option.value &&
																			selectedSubMenu === nestedOption.value
																				? 'bg-lightBlue1'
																				: 'bg-white '
																		}`}
																	>
																		{nestedOption.label}
																	</div>
																</li>
															))}
														</ul>
														{/* )} */}
													</div>
												</button>
											</li>
										) : (
											<li
												key={option.value}
												onClick={() => {
													onChange(option.value);
													closeDropdown();
												}}
											>
												<div
													className={`block text-sm-responsive px-4 py-2  dark:hover:bg-gray-600 dark:hover:text-white ${
														selectedMenu === option.value ? 'bg-lightBlue1' : 'bg-white'
													}`}
												>
													{option.label}
												</div>
											</li>
										)
									)}
								</ul>
								{/* )} */}
							</div>
						</>
					);
				}}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default NestedSelect;
