import React from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import StarRating from 'shared/rating/rating';

//Use this slider when you need single slider with tooltip
interface ISlideProps {
	title?: string;
	value: number;
	className?: string;
	min?: number;
	max?: number;
	step?: number;
	onChange?: (value: number) => void;
	onAfterChange?: (value: number) => void;
	isSize?: boolean;
	isTennis?: boolean | undefined;
}

const TooltipSlider: React.FC<ISlideProps> = ({
	value,
	min = 5,
	max = 20,
	onChange,
	step = 1,
	onAfterChange,
	className,
	title,
	isSize,
	isTennis
}) => {
	// const { value, max, min, onChange, step, onAfterChange, className, title, isSize } = props;

	//const hideTooltipClass = value === min || value === max ? 'hide-tooltip' : '';

	return (
		<div className={`flex w-full flex-col items-start  ${className}`} title={(value || '').toString()}>
			<label
				className={`${
					isSize ? 'text-xs' : 'text-sm-responsive'
				} flex font-semibold text-textBlack capitalize dark:text-gray-300 mb-1`}
			>
				{title}
			</label>
			{!isTennis ? (
				<>
					<div className='relative w-full'>
						<Slider
							value={value}
							max={max}
							min={min}
							step={step}
							tooltip={true}
							orientation='horizontal'
							onChange={(val) => onChange && onChange(val)}
							onChangeComplete={(val) => onAfterChange && onAfterChange(val)} // sending value from props because here onComplete gives html synthetic event instead of value it is bug from package
							className={`${
								isSize ? '!my-2.5' : 'mt-2 mb-4'
							} slider-container h-2 w-full rounded-lg shadow-none`}
						/>
					</div>
					<div className='flex w-full items-center justify-between pl-[6px] pr-[2px]'>
						<p
							className={`text-sm-responsive font-semibold text-[#D0D5DD] ${
								value === min && 'text-primary'
							}`}
						>
							{min}
						</p>
						<p
							className={`text-sm-responsive font-semibold text-[#D0D5DD] ${
								value === max && 'text-primary'
							}`}
						>
							{max}
						</p>
					</div>
				</>
			) : (
				<StarRating
					className='mt-3 2xl:mt-1'
					initialRating={value || 0}
					fractions={2}
					onClick={(val) => onChange && onChange(val)}
					size='40'
				/>
			)}
		</div>
	);
};

export default TooltipSlider;
