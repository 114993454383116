import React, { useState } from 'react';
import Rating from 'react-rating';
import { StarFillInIcon, StarFillOutIcon } from 'shared/icon';

interface IProps {
	initialRating: number; // 1 to 10
	isDisabled?: boolean;
	onClick?: (ranting: number) => void;
	fractions: number; // 1 to 10
	className?: string;
	size?: string;
}

const TypedRating = Rating as unknown as React.FC<any>;
/**
 * Paginate - render pagination
 */
const StarRating: React.FC<IProps> = (props) => {
	const size = props.size || '32';
	const [currentRating, setCurrentRating] = useState(props.initialRating || 0);

	const handleRatingClick = (rating: number) => {
		const newRating = currentRating === rating ? 0 : rating; // Toggle rating to 0 if clicked again
		setCurrentRating(newRating);
		if (props.onClick) props.onClick(newRating);
	};

	return (
		<TypedRating
			emptySymbol={<StarFillOutIcon width={size} height={size} />}
			fullSymbol={<StarFillInIcon width={size} height={size} />}
			initialRating={props.initialRating}
			fractions={props.fractions}
			readonly={props.isDisabled || false}
			onClick={handleRatingClick}
			className={props.className || ''}
		/>
	);
};

export default StarRating;
