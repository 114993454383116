import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IPlayers } from '../interface';
import ProfilePic from 'shared/components/profilePic';
import { DownArrowIcon } from 'shared/icon';
import { formatDate } from 'shared/util/utility';
import Gender from 'shared/components/gender';
import TooltipContainer from 'shared/components/tooltip';
import { useAuthContext } from 'store/userDetailStore';

interface IProps {
	player: IPlayers;
	isExpanded: boolean;
	onExpand: () => void;
}

const PlayerCard: React.FC<IProps> = ({ player, isExpanded, onExpand }) => {
	const navigate = useNavigate();

	const { isTennis } = useAuthContext();

	return (
		<div className='m-1 grid-view-card'>
			<div className='flex justify-between border-b border-themeGray pb-4'>
				<div className='flex'>
					<div
						className='cursor-pointer flex items-center'
						onClick={() => navigate(`/player-profile/${player.id}`)}
					>
						<ProfilePic profileUrl={player.profile_picture} />
					</div>
					<div className='flex flex-col ml-2'>
						<div
							className='text-sm-responsive font-medium text-[#101828] cursor-pointer'
							onClick={() => navigate(`/player-profile/${player.id}`)}
						>
							{player.first_name} {player.last_name}
						</div>
						<div className='flex items-center'>
							<p className='border-r border-themeGray pr-2 text-base-responsive'>
								{player.country.emoji} {player.country.iso3}
							</p>
							<p className='ml-2'>{player.sports}</p>
							<Gender data={player.gender} className='ml-2' />
						</div>
					</div>
				</div>
				<div className='flex flex-col ml-1'>
					<p className='text-sm-responsive font-medium text-[#101828]'>OPS</p>
					<p className='text-primary font-bold text-lg'>{Math.floor(+player.performance.current) || 0}</p>
				</div>
			</div>
			<div className='mt-4 flex gap-1 items-center justify-between border-b border-themeGray pb-4'>
				<div className='flex flex-col'>
					<p className='text-xs text-textGray'>Circuit Lvl.</p>
					<p className='text-sm-responsive font-semibold text-textGray'>{player.circuit_level || '-'}</p>
				</div>
				{!isTennis && (
					<div className='flex flex-col'>
						<p className='text-xs text-textGray'>Athlete ID</p>
						<p className='text-sm-responsive font-semibold text-textGray'>{player.athlete_id || '-'}</p>
					</div>
				)}
				<div className='flex flex-col'>
					<p className='text-xs text-textGray'>Status</p>
					<p className='text-sm-responsive font-semibold text-textGray'>{player.status || '-'}</p>
				</div>
				<div className='flex flex-col'>
					<p className='text-xs text-textGray'>Category</p>
					<p className='text-sm-responsive font-semibold text-textGray'>{player.category1 || '-'}</p>
				</div>

				{isTennis && (
					<>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Brand</p>
							<p className='text-sm font-semibold text-textGray'>{player.brand || '-'}</p>
						</div>
						{/*<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Racket Model</p>
							<p className='text-sm font-semibold text-textGray'>{player.racket_model || '-'}</p>
						</div>*/}
					</>
				)}
			</div>
			<div className='mt-4 flex gap-1 items-center justify-between pb-2'>
				<div className='flex flex-col'>
					<TooltipContainer
						placement='top'
						style={'light'}
						color={'light'}
						content={'Marketability Current '}
					>
						<p className='text-xs text-textGray'>Marketability C.</p>
						<p className='text-sm-responsive font-semibold text-textGray'>
							{player?.marketability_current || '-'}
						</p>
					</TooltipContainer>
				</div>
				{!isTennis && (
					<div className='flex flex-col'>
						<p className='text-xs text-textGray'>Performance Profile</p>
						<p className='text-sm-responsive font-semibold text-textGray'>
							{player?.performance_profile?.team_yonex_current_performance_level || '-'}
						</p>
					</div>
				)}
				{isTennis && (
					<div className='flex flex-col'>
						<p className='text-xs text-textGray'>Recruit Chance</p>
						<p className='text-sm font-semibold text-textGray'>{player.recruit_chance || '-'}</p>
					</div>
				)}
				<div className='flex flex-col'>
					<p className='text-xs text-textGray'>Exp. date</p>
					<p className='text-sm-responsive font-semibold text-textGray'>
						{formatDate(player?.contract?.retainer_term_end)}
					</p>
				</div>
				<div
					className='flex size-9 ml-1 cursor-pointer items-center justify-center rounded-md border border-themeGray bg-white shadow-sm'
					onClick={onExpand}
				>
					{isExpanded ? (
						<DownArrowIcon className='size-2.5 rotate-180 stroke-primary' />
					) : (
						<DownArrowIcon className='size-2.5 stroke-primary' />
					)}
				</div>
			</div>
		</div>
	);
};

export default PlayerCard;
