import { FC, useMemo } from 'react';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';

import { Input } from 'shared/form/input';
import { CheckBox } from 'shared/form/checkbox';

import NestedSelect from 'shared/form/select/nestedSelect';
import Select from 'shared/form/select/select';
import PhoneNumberInput from 'shared/form/input/phoneNumber';
import DropdownSelect from 'shared/form/select/dropdownSelect';
import { UserFormValues } from './accessManagement';
import {
	ACCESS_TYPE_OPTIONS,
	dropdownOptions,
	INTERNAL_DEPARTMENT_OPTIONS,
	INTERNAL_DEPARTMENT_SCOUT_OPTIONS,
	MODULE_OPTIONS
} from 'shared/constants';
import { InfoIcon, StarIcon } from 'shared/icon';
import useModal from 'shared/hooks/useModal';

import AccessTypeViewModal from './accessTypeViewModal';
import TooltipContainer from 'shared/components/tooltip';

interface IProps {
	register: UseFormRegister<any>;
	control: any;
	watch: UseFormWatch<any>;
	errors: FieldErrors<UserFormValues>;
	isEdit?: boolean;
}

export const enum AccessTypeModal {
	accessType = 'Access Type'
}

export interface IAccessType {
	userLevelAccess: string;
	levelOne: boolean;
	levelTwo: boolean;
	levelThree: boolean;
	levelFour: boolean;
	levelFive: boolean;
	id: number;
}

const InviteForm: FC<IProps> = ({ isEdit, register, control, watch, errors }) => {
	const departmentValue = useMemo(() => watch('department'), [watch('department')]);

	const { isOpen, type, closeModal, openModal } = useModal<AccessTypeModal>();

	return (
		<>
			<form>
				<div className='grid grid-cols-2 gap-x-4'>
					<Input
						register={register}
						title={'Name'}
						name='name'
						errorMsg={errors?.name?.message ?? ''}
						placeholder='Type Name'
						required
					/>
					<Input
						register={register}
						title={'Last name'}
						name='last_name'
						errorMsg={errors?.last_name?.message ?? ''}
						placeholder='Type Last Name'
						required
					/>
					<Input
						register={register}
						title={'Work email'}
						name='email'
						type='email'
						disabled={!!isEdit}
						errorMsg={errors?.email?.message ?? ''}
						placeholder='Work Email Address'
						required
					/>
					<PhoneNumberInput control={control} name='phone_number' title='Phone Number' errorMsg={''} />
				</div>
				<Input
					register={register}
					title={'Title'}
					name='coworker_title'
					errorMsg={errors?.coworker_title?.message ?? ''}
					placeholder='Add Coworker Title'
				/>
				<div className='mb-2'>
					<label className='relative font-medium text-sm-responsive text-[#344054]'>
						Sport
						<sub className='top-0 absolute'>
							<StarIcon height='7px' width='7px' />
						</sub>
					</label>
					<div className='flex gap-x-3 mt-[6px]'>
						<CheckBox register={register} title={'Tennis'} name='sports[0]' />
						<CheckBox register={register} title={'Badminton'} name='sports[1]' />
					</div>
					{errors?.sports?.message && (
						<p className='text-left text-xs text-red-600'>{errors?.sports?.message}</p>
					)}
				</div>
				<div className='flex gap-x-3 relative'>
					<NestedSelect
						menuPosition='top'
						placeHolder='Select Department'
						className='mt-2'
						control={control}
						name='department'
						title='Department'
						options={dropdownOptions}
						errorMsg={errors?.department?.message ?? ''}
						required={true}
					/>

					<Select
						menuPosition='top'
						placeHolder='Select Access'
						className='mt-2'
						control={control}
						name='access_type'
						title='Access Type'
						options={ACCESS_TYPE_OPTIONS}
						errorMsg={errors?.access_type?.message ?? ''}
						required={true}
					/>
					<div className='absolute right-0 top-[10px]' onClick={() => openModal(AccessTypeModal.accessType)}>
						<InfoIcon className={`stroke-[#667085] cursor-pointer `} height='13px' width='13px' />
					</div>
				</div>
				<div className='flex gap-x-3'>
					<div className='w-full relative'>
						<Select
							disabled={!departmentValue}
							menuPosition='top'
							placeHolder='Select Internal Department'
							className='mt-2 '
							control={control}
							name='internal_department'
							title='Internal Department Level'
							options={
								departmentValue && departmentValue === 'ScoutingTeam'
									? INTERNAL_DEPARTMENT_SCOUT_OPTIONS
									: INTERNAL_DEPARTMENT_OPTIONS
							}
							errorMsg={errors?.internal_department?.message ?? ''}
							required={true}
						/>

						{!departmentValue && (
							<div className='absolute right-2 top-2'>
								<TooltipContainer content={'Select Department First'} textClassName='w-[180px]'>
									<InfoIcon className={`stroke-[red] cursor-pointer `} height='13px' width='13px' />
								</TooltipContainer>
							</div>
						)}
					</div>

					<DropdownSelect
						required={true}
						menuPosition='top'
						name='modules'
						title='Module'
						options={MODULE_OPTIONS}
						errorMsg={errors?.modules?.message ?? ''}
						control={control}
						placeHolder='Select Module'
						className='mt-2'
					/>
				</div>
			</form>
			{isOpen && type === AccessTypeModal.accessType && (
				<AccessTypeViewModal show={isOpen} onClose={closeModal} />
			)}
		</>
	);
};

export default InviteForm;
