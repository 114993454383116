import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { AddRecruitingIcon, InfoIcon } from 'shared/icon';
import ButtonSecondary from 'shared/components/buttons/secondary';
import ButtonPrimary from 'shared/components/buttons/primary';
import NestedSelect from 'shared/form/select/nestedSelect';
import { dropdownOptions, SPORTS_OPTIONS } from 'shared/constants';
import DropdownSelect from 'shared/form/select/dropdownSelect';
import { Input } from 'shared/form/input';
import { IBoardDetail, ScreenType } from '../../constant';
import SelectTheme from 'shared/form/select/selectTheme';
import SelectColor from 'shared/form/select/selectColor';
import Select from 'shared/form/select/select';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { IEditorResponse } from 'features/addPlayer/interface';
import { CommonModal } from 'shared/components/header/constant';
import { useNavigate } from 'react-router-dom';
import { cleanObject } from 'shared/util/utility';
import { AllAthleteModal } from 'features/allAthlates/constant';
import { useAthleteContext } from 'store/athleteStore';
import TooltipContainer from 'shared/components/tooltip';
import { useAuthContext } from 'store/userDetailStore';

interface IProps {
	show: boolean;
	onClose: () => void;
	setScreenType?: (value: ScreenType) => void;
	setDashboardDetails?: React.Dispatch<React.SetStateAction<IBoardDetail>>;
	defaultValues: any;
	isEdit: boolean;
	fetchDashboard?: () => void;
	type?: string;
	handleAddPlayerToBoard?: (id: string) => void;
}

const schema = Yup.object({
	name: Yup.string().trim().required('Board name is required'),
	theme: Yup.string().trim().required('Select one theme'),
	color_code: Yup.string().trim().required('Select one color code'),
	sports: Yup.string().required('Sports is required')
});

const CreateBoardModal: React.FC<IProps> = (props) => {
	const {
		show,
		onClose,
		setScreenType,
		setDashboardDetails,
		defaultValues,
		isEdit = false,
		fetchDashboard,
		type,
		handleAddPlayerToBoard
	} = props;

	const editorIds = useMemo(() => {
		return isEdit ? defaultValues?.editors?.map((editor: IEditorResponse) => editor.id) : [];
	}, [isEdit, defaultValues]);

	const { getCountDetails } = useAthleteContext();
	const { sport } = useAuthContext();

	const {
		handleSubmit,
		control,
		register,
		setValue,
		watch,
		formState: { errors }
	} = useForm<any>({
		defaultValues: {
			...defaultValues,
			editor_ids: editorIds, // set editor_ids in default values
			sports: sport
		},
		resolver: yupResolver(schema)
	});

	const [editorDropdown, setEditorDropdown] = useState([]);
	const navigate = useNavigate();

	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);

	const sports = useWatch({
		control,
		name: 'sports' // Watch this field
	});
	const department = useWatch({
		control,
		name: 'department' // Watch this field always
	});

	const [selectedMenu, selectedSubMenu] = department ? department.split('~') : [null, null];
	const fetchEditorList = async () => {
		try {
			const params = {
				sports: sport,
				department_group: selectedMenu,
				department: selectedSubMenu ?? null
			};
			const res = await HttpService.get(`${API_CONFIG.editorsList}`, params);
			const editorOptions = res?.data.map((details: IEditorResponse) => ({
				label: `${details.name} ${details.last_name}`,
				value: details.id
			}));
			setEditorDropdown(editorOptions);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		sports && department && fetchEditorList();
	}, [sports, department]);

	useEffect(() => {
		// Set editor_ids when in edit mode
		if (isEdit) {
			setValue('editor_ids', editorIds);
		}
	}, [isEdit, editorIds, setValue]);

	useEffect(() => {
		if (!sports) {
			setValue && setValue('department', ''); // Clear department if sports is empty
		}
		if (sports !== defaultValues.sports || department !== defaultValues.department) {
			setValue('editor_ids', []); // Set editor_ids to an empty array
		} else if (!department) {
			setValue('editor_ids', editorIds);
		}
	}, [sports, department, defaultValues, setValue]);

	const onSubmit: SubmitHandler<any> = async (data) => {
		const { department, ...newParams } = data;
		try {
			const params = {
				...newParams,
				department_group: selectedMenu,
				department: selectedSubMenu ?? null
			};

			const paramsData = cleanObject(params);

			if (!isEdit) {
				const res = await HttpService.post(`${API_CONFIG.recruitingDashboard}`, paramsData);
				if (res.data) {
					setDashboardDetails && setDashboardDetails({ ...data, id: res.data.recruiting_board_id });
					setScreenType && setScreenType(ScreenType.SELECTION_ATHLETE);
				}
				if (type === CommonModal.CREATE_SCOUT) {
					navigate('/recruiting-dashboard', {
						state: {
							screen: ScreenType.SELECTION_ATHLETE,
							details: { ...data, name: data.name, id: res.data.recruiting_board_id }
						}
					});
					onClose();
				}
				if (type == AllAthleteModal.CREATE_TO_BOARD) {
					handleAddPlayerToBoard && handleAddPlayerToBoard(res.data.recruiting_board_id);
					getCountDetails();
					onClose();
				}
			} else {
				delete params.id;
				delete params.editors;

				await HttpService.put(`${API_CONFIG.recruiting}/${data.id}`, params);
				onClose();
				fetchDashboard && fetchDashboard();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const sportIsSelected = watch('sports');
	const EditorDepartment = watch('department');

	return (
		<Modal show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg bg-lightBlue'>
				<div>
					<div className='size-10 shadow-sm mb-1 flex border-themeGray items-center justify-center rounded-lg'>
						<AddRecruitingIcon />
					</div>
					<p className='header-txt'>{`${isEdit ? 'Edit' : 'Add'} Board`}</p>
					<p className='sub-header-txt'>Create board for recruiting process</p>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='p-3'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='flex flex-col'>
							<Input
								register={register}
								title='Board Name'
								name='name'
								errorMsg={getErrorMsg('name')}
								placeholder='Board Name'
								required
							/>
							<Select
								disabled={true}
								placeHolder='Select Sport Type'
								control={control}
								name='sports'
								title='Sport'
								options={SPORTS_OPTIONS}
								errorMsg={getErrorMsg('sports')}
								className='mb-3'
								required
							/>
							<div className='w-full flex justify-between items-center relative gap-2'>
								<SelectTheme
									placeHolder='Select Theme'
									control={control}
									name='theme'
									title='Select Theme'
									errorMsg={getErrorMsg('theme')}
								/>
								<SelectColor
									control={control}
									name='color_code'
									title='Select Color'
									errorMsg={getErrorMsg('color_code')}
								/>
							</div>
							<div className='relative mt-3'>
								<NestedSelect
									menuPosition='top'
									placeHolder='Select Department'
									className='mb-3'
									control={control}
									name='department'
									title={`Editor's Department`}
									options={dropdownOptions}
									errorMsg={''}
									translate={'144px'}
									disabled={!sports}
								/>
								{!sportIsSelected && (
									<div className='absolute right-2 top-0'>
										<TooltipContainer content={'Select Sport First'} textClassName='w-[120px]'>
											<InfoIcon
												className={`stroke-[red] cursor-pointer `}
												height='13px'
												width='13px'
											/>
										</TooltipContainer>
									</div>
								)}
							</div>
							<div className='relative'>
								<DropdownSelect
									menuPosition='top'
									name='editor_ids'
									title='Editor'
									options={editorDropdown}
									errorMsg={''}
									control={control}
									placeHolder='Select Editor'
									disabled={!department}
								/>
								{!EditorDepartment && (
									<div className='absolute right-2 top-0'>
										<TooltipContainer
											content={"Select Editor's Department First"}
											textClassName='w-[220px]'
										>
											<InfoIcon
												className={`stroke-[red] cursor-pointer `}
												height='13px'
												width='13px'
											/>
										</TooltipContainer>
									</div>
								)}
							</div>
						</div>
					</form>
				</div>
				<div className='flex gap-3 mt-3'>
					<ButtonSecondary className='w-1/2' btnTxt='Cancel' onClick={onClose} />
					<ButtonPrimary
						className='w-1/2'
						btnTxt={`${isEdit ? 'Save' : 'Create'} Board`}
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};
export default CreateBoardModal;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	header: {
		base: 'flex items-start justify-between px-4 py-5 border-b-2 border-primary'
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[450px]'
	},
	body: {
		base: 'flex-1 px-3 pb-3 overflow-auto custom-scrollbar'
	}
};
