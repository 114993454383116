import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';
import { IDropdownOptions } from 'shared/interface';

interface IProps {
	name: string;
	title: string;
	options: IDropdownOptions[];
	className?: string;
	errorMsg?: string;
	control: any;
	placeHolder?: string;
	menuPosition?: 'top' | 'bottom';
	disabled?: boolean;
	required?: boolean;
	isLabel?: boolean;
	isGroup?: boolean;
}

const Select: FC<IProps> = ({
	name,
	title,
	options,
	className,
	control,
	errorMsg,
	placeHolder,
	menuPosition,
	disabled,
	required,
	isLabel = true,
	isGroup = false
}) => {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		// Add listener for clicks outside
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Clean up listener on component unmount
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const closeDropdown = () => {
		setDropdownVisible(false);
	};

	const getLabel = (selectedValue: string) => {
		const mainOption = options.find((option) => option.value === selectedValue);
		return mainOption ? mainOption.label : null;
	};

	const groupOptions = (options: any[]) => {
		const grouped = {
			'Yonex Athletes': options.filter((option) => option.isAthlete),
			'Non-Yonex Athletes': options.filter((option) => !option.isAthlete)
		};
		return grouped;
	};

	const groupedOptions = isGroup && groupOptions(options);

	return (
		<div ref={dropdownRef} className={`${className || ''} relative w-full`}>
			{isLabel && (
				<label htmlFor={name} className={`input-label ${disabled ? 'text-themeGray-text' : ''}`}>
					{title}
					{required && (
						<span className='text-xs'>
							<StarIcon height='7px' width='7px' />
						</span>
					)}
				</label>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<>
						<button
							disabled={disabled}
							id='dropdownButton'
							className={`input-select ring-none ${!value || disabled ? 'text-themeGray-text' : ''} ${
								disabled && 'cursor-not-allowed'
							}`}
							type='button'
							onClick={() => setDropdownVisible((prev) => !prev)}
						>
							{getLabel(value) || placeHolder || 'Select'}
							{value ? (
								<button
									type='button'
									className='h-3 w-4 flex justify-end'
									onClick={(e) => {
										onChange(null);
										e.stopPropagation();
									}}
								>
									{!disabled && <CloseIcon className='size-2.5 stroke-[#344054] cursor-pointer' />}
								</button>
							) : (
								<DownArrowIcon className='size-2.5 stroke-[#344054]' />
							)}
						</button>

						{dropdownVisible && (
							<div
								id='dropdown'
								className={`absolute z-10 w-full divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700`}
								style={
									menuPosition === 'top'
										? {
												transform: 'translate(0px, -54px)'
										  }
										: {}
								}
							>
								<ul
									className='text-sm-responsive text-gray-700 dark:text-gray-200 max-h-[300px] overflow-auto custom-scrollbar'
									aria-labelledby='dropdownButton'
								>
									{isGroup
										? Object.entries(groupedOptions).map(([group, items]) => (
												<li key={group}>
													<div className='px-4 py-2 font-bold bg-gray-100'>{group}</div>
													{items.map((option: any) => (
														<div
															key={option.value}
															className={`block px-4 py-2 hover:bg-lightBlue1 dark:hover:bg-gray-600 ${
																value === option.value ? 'bg-lightBlue1' : ''
															}`}
															onClick={() => {
																onChange(option.value);
																closeDropdown();
															}}
														>
															{option.label}
														</div>
													))}
												</li>
										  ))
										: options.map((option) => (
												<li
													key={option.value}
													className={`block px-4 py-2 hover:bg-lightBlue1 dark:hover:bg-gray-600 ${
														value === option.value ? 'bg-lightBlue1' : ''
													}`}
													onClick={() => {
														onChange(option.value);
														closeDropdown();
													}}
												>
													{option.label}
												</li>
										  ))}
								</ul>
							</div>
						)}
					</>
				)}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600'>{errorMsg}</p>}
		</div>
	);
};

export default Select;
