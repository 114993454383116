import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Spinner } from 'flowbite-react';
import { isEmpty } from 'lodash';

import TableComponent from 'shared/components/table/table';
import TableBody from 'shared/components/table/tableBody';
import TableCell from 'shared/components/table/tableCell';
import TableHeader from 'shared/components/table/tableHeader';
import TableRow from 'shared/components/table/tableRow';
import ProfilePic from 'shared/components/profilePic';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { CloseIcon, FilterIcon } from 'shared/icon';
import { ISortData } from 'shared/interface';
import { cleanObject } from 'shared/util/utility';
import useModal from 'shared/hooks/useModal';
import { useAthleteContext } from 'store/athleteStore';
import EmptyData from 'shared/components/table/emptyData';
import { compareInitialFilter, FILTERS, IFilters, IPlayers, ViewFilter } from 'features/athleteDatabase/interface';
import { FilterModal } from 'features/athleteDatabase/container';
import {
	ATHLETE_TABLE_CELL,
	filterButtonTheme,
	selectAthleteModalTheme,
	TENNIS_ATHLETE_TABLE_CELL
} from 'features/athleteDatabase/constant';
import PlayerFilterModal from '../filter/filterModal';
import Gender from 'shared/components/gender';

import Perform from '../perform';
import SearchInput from 'shared/components/search';
import Pagination from 'shared/components/pagination';
import { AuthContext } from 'store/userDetailStore';

interface IProps {
	className?: string;
	show: boolean;
	onClose: () => void;
	handelSelectAthlete: (data: IPlayers) => void;
	currentId: string;
}
interface IPagination {
	current_page: number;
	record_per_page: number;
	total_pages: number;
}
const SelectAthlete: React.FC<IProps> = (props) => {
	const { className, show, onClose, handelSelectAthlete, currentId } = props;

	const [athleteData, setAthleteData] = useState([] as IPlayers[]);
	const [loading, setLoading] = useState(true);
	const [filters, setFilters] = useState(compareInitialFilter);
	const [pagination, setPagination] = useState<IPagination>({ current_page: 1, record_per_page: 10, total_pages: 1 });
	const [totalRecord, setTotalRecords] = useState<number>();

	const { isTennis } = useContext(AuthContext);

	const [savedFilterName, setSavedFilterName] = useState('');
	const { countryOptions } = useAthleteContext();

	const { isOpen, type, closeModal, openModal } = useModal<FilterModal>();

	const fetchPlayers = async (filterParams: any) => {
		const params = cleanObject(filterParams);
		try {
			setLoading(true);
			const res = await HttpService.get(`${isTennis ? API_CONFIG.tennisPlayer : API_CONFIG.player}`, params);
			setPagination({ ...pagination, total_pages: res.data.total_pages, current_page: res.data.current_page });
			setAthleteData(res.data.data);
			setTotalRecords(res.data.total);

			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	};

	const onPageChange = (page: number) => {
		fetchPlayers({ ...pagination, current_page: page });
	};

	useEffect(() => {
		show && fetchPlayers({ ...filters, ...pagination });
	}, [show, filters]);

	const getCell = useCallback((key: string, player: IPlayers) => {
		switch (key) {
			case 'athlete_name':
				return (
					<ProfilePic
						profileUrl={player.profile_picture}
						playerName={`${player.first_name} ${player.last_name}`}
					/>
				);

			case 'sport':
				return <p className='text-textGray'>{player.sports}</p>;

			case 'country':
				return (
					<p className='text-textGray'>
						{player.country.emoji} {player.country.iso3}
					</p>
				);

			case 'gender':
				return <Gender data={player.gender} className='flex items-center justify-center' />;

			case 'circuit_level':
				return <p className='text-textGray'>{player.circuit_level}</p>;

			case 'category1':
				return <p className='text-textGray'>{player.category1}</p>;

			case 'perform': {
				return <Perform performData={player.performance} />;
			}

			default:
				return <p>-</p>;
		}
	}, []);

	const onChangeFilter = (key: FILTERS, value: string) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[key]: value
		}));
	};

	const onSort = (sortData: ISortData) => {
		setFilters({ ...filters, ...sortData });
	};

	const getCountry = useCallback(
		(countryId: number | string) => {
			const countryData = countryOptions.find((country) => country.value === countryId);
			return countryData ? `${countryData?.emoji} ${countryData?.iso3}` : 'Country';
		},
		[countryOptions]
	);

	const getAppliedFilters = useCallback(
		(key: FILTERS) => {
			if (key === FILTERS.COUNTRY) {
				return getCountry(filters[key]);
			} else if (key === FILTERS.FILTER_ID) {
				return savedFilterName;
			} else {
				return filters[key];
			}
		},
		[savedFilterName, filters, getCountry]
	);

	const kpiArray = useMemo(() => {
		if (filters.kpis && filters.kpis !== '') {
			const serializedFilters = decodeURIComponent(filters.kpis as string);
			return JSON.parse(serializedFilters);
		}
		return [];
	}, [filters.kpis]);

	const visibleFilterCount = useMemo(() => {
		return (
			(Object.keys(filters) as Array<keyof IFilters>).filter(
				(k) => filters[k] !== '' && ViewFilter.includes(k) && k !== FILTERS.KPI
			).length - 2
		);
	}, [filters]);

	const totalFilterCount = useMemo(() => {
		return visibleFilterCount + (filters.kpis ? kpiArray.length : 0);
	}, [filters.kpis, visibleFilterCount]);

	const tableCellList = isTennis ? TENNIS_ATHLETE_TABLE_CELL : ATHLETE_TABLE_CELL;

	return (
		<>
			<Modal className={`${className || ''}`} show={show} onClose={onClose} theme={selectAthleteModalTheme}>
				<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
					<div className='flex items-center gap-2'>
						<p className='header-txt'>Athlete Comparison</p>
						{totalRecord && totalRecord > 0 ? (
							<p className='bg-[#1d953317] p-2 rounded-lg h-8 text-[#1E9634] text-xs'>
								{totalRecord} Athletes
							</p>
						) : (
							<></>
						)}
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className='border border-themeGray rounded-xl border-b-none'>
						<div className=' flex justify-between items-center border-b border-themeGray p-3'>
							<div className='flex'>
								<SearchInput
									onChangeFilter={onChangeFilter as any}
									FilterKey={FILTERS.SEARCH}
									searchValue={filters[FILTERS.SEARCH] as string}
								/>
							</div>
							<div className='flex'>
								<div className='flex'>
									{(Object.keys(filters) as Array<keyof IFilters>)
										.filter((k) => filters[k] !== '' && ViewFilter.includes(k))
										.slice(0, 2)
										.map((key) => {
											return (
												<Fragment key={key}>
													{key === FILTERS.KPI ? (
														<div className='text-sm ml-1 border-2 px-1 rounded-md'>
															<button
																onClick={() => openModal(FilterModal.Filter_Modal)}
																className='ml-1 cursor-pointer'
															>
																+{kpiArray.length}
															</button>
														</div>
													) : (
														<div className='text-sm ml-1 border-2 px-1 rounded-md'>
															{getAppliedFilters(key)}
															<button
																onClick={() => onChangeFilter(key, '')}
																className='ml-1 cursor-pointer'
															>
																<CloseIcon className='cursor-pointer ml-1' />
															</button>
														</div>
													)}
												</Fragment>
											);
										})}
									{totalFilterCount > 0 && (
										<div className='text-sm ml-1 border-2 px-1 rounded-md'>
											<button
												onClick={() => openModal(FilterModal.Filter_Modal)}
												className='ml-1 cursor-pointer'
											>
												+{totalFilterCount}
											</button>
										</div>
									)}
								</div>
								<div className='mr-3 flex h-6 items-center justify-center border-r border-[#EAECF0]'>
									<Button
										color='light'
										theme={filterButtonTheme}
										onClick={() => openModal(FilterModal.Filter_Modal)}
									>
										<FilterIcon className='mr-2 size-5' />
										Filter
									</Button>
								</div>
							</div>
						</div>
						<TableComponent className='rounded-xl border'>
							<TableHeader
								tableCellList={tableCellList}
								sortData={{ sort_by: filters.sort_by, order_by: filters.order_by }}
								className='sticky top-0 z-[1] table w-full table-fixed bg-white first:text-left'
								onSort={onSort}
							/>
							<TableBody className='custom-scrollbar block h-[calc(100vh-750px)] min-h-[38vh] overflow-y-auto border-none'>
								{!loading &&
									!isEmpty(athleteData) &&
									athleteData.map((player: any) => {
										return (
											<Fragment key={player.id}>
												<TableRow
													className='table w-full table-fixed cursor-pointer last:border-none last:rounded-lg tr-left-border'
													onClick={() => handelSelectAthlete(player)}
													disabled={currentId === player.id}
												>
													{tableCellList.map((cell) => (
														<TableCell key={cell.title} className='last:border-none'>
															{getCell(cell.key, player)}
														</TableCell>
													))}
												</TableRow>
											</Fragment>
										);
									})}
								{!loading && isEmpty(athleteData) && (
									<EmptyData className='h-[calc(100vh-750px)] min-h-[38vh]' />
								)}
								{loading && (
									<div className='text-center flex items-center justify-center mt-3'>
										<Spinner aria-label='Extra large Center-aligned spinner example' size='xl' />
									</div>
								)}
							</TableBody>
						</TableComponent>
						<Pagination loading={loading} onPageChange={onPageChange} pagination={pagination} />
					</div>
				</Modal.Body>
			</Modal>
			{isOpen && type === FilterModal.Filter_Modal && (
				<PlayerFilterModal
					show={isOpen}
					onClose={closeModal}
					onChangeFilter={setFilters}
					filters={filters}
					setSavedFilterName={setSavedFilterName}
				/>
			)}
		</>
	);
};
export default SelectAthlete;
