const headerEN = {
	home: 'Dashboard',
	playerManagement: 'Athlete Management & Database',
	scoutingRecruiting: 'Scouting & Recruiting',
	contractManagement: 'Contract Management',
	events: 'Events',
	accountSetting: 'Account Setting',
	accountSettingText: 'Your Personal Account Settings',
	notificationsManagement: 'Notifications Management',
	notificationsManagementText: 'Manage Your Notifications',
	accessManagement: 'Access Management',
	inviteToThePlatform: 'Invite to the Platform',
	accessManagementText: 'Manage Access to the Platform',
	helpSupport: 'Help and Support',
	helpSupportText: 'Contact to Tech Support',
	logout: 'Logout',
	athleteRegistration: 'Athlete Registration',
	athleteRegistrationText: 'Add a New player and Monitor Career',
	athleteDatabase: 'Athlete Database',
	athleteDatabaseText: 'Check the Athlete List',
	allAthletes: ' Athlete Database',
	allAthletesText: 'Scout & Recruit',
	scoutingShortlists: 'Scouted Athletes',
	scoutingShortlistsText: 'Create & Manage Shortlists',
	recruitingDashboard: 'Recruited Athletes',
	recruitingDashboardText: 'Create & Manage Recruiting Pipelines'
};

export default headerEN;
