import { ATHLETES_OPTIONS } from '../constant';

export enum FILTERS {
	SPORTS = 'sport',
	SEARCH = 'player_name',
	STATUS = 'status',
	ATHLETE = 'athlete_type',
	SHORT_BY = 'sort_by',
	ORDER_BY = 'order_by',
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	CIRCUIT_LEVEL = 'circuit_level',
	CATEGORY = 'category',
	COUNTRY = 'country_id',
	GENDER = 'gender',
	FILTER_ID = 'filter_id',
	KPI = 'kpis'
}

export const initOtherFilters = {
	[FILTERS.SPORTS]: '',
	[FILTERS.COUNTRY]: '',
	[FILTERS.CIRCUIT_LEVEL]: '',
	[FILTERS.CATEGORY]: '',
	[FILTERS.STATUS]: '',
	[FILTERS.GENDER]: '',
	[FILTERS.FILTER_ID]: '',
	[FILTERS.KPI]: ''
};

export const initFilters = {
	...initOtherFilters,
	[FILTERS.SEARCH]: '',
	[FILTERS.ATHLETE]: ATHLETES_OPTIONS[0]?.value ?? '',
	[FILTERS.SHORT_BY]: '',
	[FILTERS.ORDER_BY]: '',
	[FILTERS.CURRENT_PAGE]: 1,
	[FILTERS.RECORD_PER_PAGE]: 10
};

export const ViewFilter = [
	FILTERS.SPORTS,
	FILTERS.COUNTRY,
	FILTERS.CIRCUIT_LEVEL,
	FILTERS.CATEGORY,
	FILTERS.STATUS,
	FILTERS.GENDER,
	FILTERS.FILTER_ID,
	FILTERS.KPI
];

export type IFilters = {
	[key in FILTERS]: string | number;
};

export const initTennisFilters = {
	[FILTERS.ATHLETE]: ATHLETES_OPTIONS[0]?.value ?? '',
	[FILTERS.CIRCUIT_LEVEL]: '',
	[FILTERS.STATUS]: '',
	[FILTERS.FILTER_ID]: '',
	[FILTERS.KPI]: '',
	[FILTERS.ORDER_BY]: '',
	[FILTERS.SHORT_BY]: '',
	[FILTERS.CURRENT_PAGE]: 1
};

export interface IPlayerData {
	current_page: number;
	record_per_page: number;
	total: number;
	total_pages: number;
	next_page: number;
	remaining_count: number;
	players: IPlayers[];
}

export interface IPlayers {
	id: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	profile_picture: string;
	first_name: string;
	last_name: string;
	racquet_brand: string;
	gender: string;
	date_of_birth: string;
	status: string;
	athlete_id: string;
	circuit_level: string;
	playing_hand: string;
	category1: string;
	category2: string;
	category3: string;
	sports: string;
	contract: IContract;
	guardian: IGuardian;
	agent: IAgent;
	performance_profile: IPerformanceProfile;
	marketability_current: string;
	marketability_potential: string;
	country: ICountry;
	performance: IPerformance;
	total_social_media_count: string;
	brand?: string;
	racket_model?: string;
	recruit_chance?: string;
	string_model?: string;
}

export interface IContract {
	type: string;
	retainer_amount: number;
	retainer_currency: string;
	retainer_term_start: string | null;
	retainer_term_end: string | null;
}

export interface IGuardian {
	first_name: string;
	last_name: string;
	relation: string;
	email: string;
	telephone_number: string;
}

export interface IAgent {
	first_name: string;
	last_name: string;
	email: string;
	telephone_number: string;
}

export interface IPerformanceProfile {
	team_yonex_current_performance_level: string;
	training_age: number;
	potential_grade: string;
	team_yonex_senior_level: string;
	legends_vision: string;
	performance_toggle?: boolean;
	recruit_chance?: string;
	x_factor: string;
}

export interface ICountry {
	emoji: any;
	id: number;
	name: string;
	iso3: string;
}

export interface IPerformance {
	current: string;
	previous: string;
	difference: string;
}

export enum ViewType {
	Table_View = 'Table View',
	Card_View = 'Card View'
}

export interface IPlayerVideos {
	tournament_name: string;
	comment: string;
	video_url: string;
	creation_date: string;
	file?: File;
	athlete_name?: string;
	scout_name?: string;
	id?: string;
}

export interface IQuickAddValues {
	player_information: {
		first_name: string;
		last_name: string;
		status: string;
		circuit_level: string;
		gender: string;
		date_of_birth: string;
		country: string;
		racquet_brand: string;
		category1: string;
		category2: string;
		category3: string;
		profile_creator: string;
		athlete_id: string;
		tournament: string;
	};
	xfactor: boolean;
	//player_videos: IPlayerVideos[];
	performance_profile: {
		performance_toggle: boolean;
		team_yonex_senior_level: string;
		legends_vision: string;
		team_yonex_current_performance_level: string;
		training_age: number;
		potential_grade: string;
		x_factor: string;
	};
	marketability_current: string;
	marketability_potential: string;
	//kpis: [];
	//kpi_groups: [
	//	{
	//		kpi_group_name: string;
	//		note: string;
	//		tournament: string;
	//	}
	//];
	notify_editor_info: IEditor;
}

export const defaultValues: IQuickAddValues = {
	player_information: {
		first_name: '',
		last_name: '',
		status: '',
		circuit_level: '',
		gender: '',
		date_of_birth: '',
		country: '',
		racquet_brand: '',
		category1: '',
		category2: '',
		category3: '',
		profile_creator: '',
		athlete_id: '',
		tournament: ''
	},
	//player_videos: [],
	performance_profile: {
		performance_toggle: false,
		team_yonex_senior_level: '',
		legends_vision: '',
		team_yonex_current_performance_level: '',
		training_age: 0,
		potential_grade: '',
		x_factor: ''
	},
	marketability_current: '',
	marketability_potential: '',
	//kpis: [],
	//kpi_groups: [
	//	{
	//		kpi_group_name: '',
	//		note: '',
	//		tournament: ''
	//	}
	//],
	xfactor: false,
	notify_editor_info: {} as IEditor
};

export interface IPerformanceMatrix {
	player_id: string;
	profile_picture: string;
	first_name: string;
	last_name: string;
	kpis: IKpi[];
	OPS: string;
	is_max_ops: boolean;
	is_min_ops: boolean;
}

export interface IKpi {
	kpi_group: string;
	avg: string;
	kpi_name: string;
}

export const enum AthleteModal {
	SELECT_ATHLETE = 'Select Athlete',
	COMPARE_ATHLETE = 'Compare Athlete'
}

export interface ICompareAthlete {
	id: string;
	profile_picture: string;
	first_name: string;
	last_name: string;
	athlete_id: string;
	gender: string;
	circuit_level: string;
	status: string;
	category1: string;
	team_yonex_future_star_level: string;
	team_yonex_senior_level: string;
	marketability_current: string;
	contract_retainer_term_end: string;
	sports: string;
	performance: IPerformance;
	country: ICountry;
	kpis: IKpis;
	racket_model?: string;
	brand?: string;
	recruit_chance?: string;
	string_model?: string;
}

export interface IKpis {
	[key: string]: IKpiValue[];
}

export interface IKpiValue {
	id: number;
	value: number;
	kpi_name: string;
}

export interface IKpiAvg {
	[key: string]: IKpiAvgScore;
}

export interface IKpiAvgScore {
	player_one_avg_score: number;
	player_two_avg_score: number;
	kpiData: IKpiNum[];
}

export interface IKpiNum {
	player_one_value: number;
	player_two_value: number;
	player_one_fill: number;
	player_two_fill: number;
	kpi_name: string;
}

export const compareInitialFilter = {
	...initOtherFilters,
	[FILTERS.SEARCH]: '',
	[FILTERS.ATHLETE]: '',
	[FILTERS.SHORT_BY]: '',
	[FILTERS.ORDER_BY]: '',
	[FILTERS.CURRENT_PAGE]: 1,
	[FILTERS.RECORD_PER_PAGE]: 500
};

export interface IEditor {
	content: string;
	user_ids: string[];
}

export enum SPORT_TYPE {
	BADMINTON = 'Badminton',
	TENNIS = 'Tennis'
}
