import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { Spinner } from 'flowbite-react';

import { AthleteModal, IPlayers, ViewType } from '../interface';
import PerformanceProgressBar from 'shared/components/performanceProgressBar';
import { AddUserIcon, FrameIcon, ProfileIcon, ProfileImgFrameIcon } from 'shared/icon';
import ButtonSecondary from 'shared/components/buttons/secondary';
import { IPlayerDetails, IScore } from 'features/playerProfile/interface';
import { calculateAveragePerGroup, calculateTennisAvg } from 'shared/util/utility';
import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';
import useModal from 'shared/hooks/useModal';
import { ISelectPlayer } from 'features/shortlist/interface';
import { useAuthContext } from 'store/userDetailStore';

import SocialMediaExpand from './socialMediaExpand';
import SelectAthlete from './comparison/selectAthleteModal';
import CompareAthlete from './comparison/compareAthlete';
import { IScoutingPlayer } from 'features/allAthlates/interface';

interface IProps {
	record: IPlayers | IScoutingPlayer | ISelectPlayer;
	viewType: string;
	showCompareSection?: boolean;
}

const ExpandRow: React.FC<IProps> = (props) => {
	const { record, viewType, showCompareSection = true } = props;
	const [playerDetails, setPlayerDetails] = useState<IPlayerDetails>();
	const [avgData, setAvgData] = useState<IScore[]>([]);
	const [loading, setLoading] = useState(true);
	const [athleteConfig, setAthleteConfig] = useState({
		currentAthlete: {} as IPlayers | IScoutingPlayer | ISelectPlayer,
		selectedAthlete: {} as IPlayers
	});
	const { isTennis } = useAuthContext();
	const { isOpen, type, closeModal, openModal } = useModal<AthleteModal>();

	const fetchPlayerDetails = async (id: string) => {
		setLoading(true);
		try {
			const res = await HttpService.get(`${isTennis ? API_CONFIG.tennisPlayer : API_CONFIG.player}/${id}`);
			setAthleteConfig((prevState) => {
				return { ...prevState, currentAthlete: record };
			});
			setPlayerDetails(res.data);
			if (!isNull(res.data) && !isNull(res.data.kpis)) {
				const avgData = isTennis ? calculateTennisAvg(res.data.kpis) : calculateAveragePerGroup(res.data.kpis);
				setAvgData(avgData);
			}
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	};

	useEffect(() => {
		record.id && fetchPlayerDetails(record.id);
	}, []);

	const handelSelectAthlete = (data: IPlayers) => {
		setAthleteConfig((prevState) => {
			return { ...prevState, selectedAthlete: data };
		});
		closeModal();
		openModal(AthleteModal.COMPARE_ATHLETE);
	};

	return (
		<>
			<div className='w-full'>
				{!loading && playerDetails && (
					<div className={`flex items-start justify-center`}>
						<PerformanceProgressBar data={avgData} className='w-[40%]' />
						{showCompareSection && (
							<div
								className={`ml-5 flex ${
									viewType === ViewType.Table_View ? 'w-[450px]' : 'w-[330px] 2xl:w-[450px]'
								} flex-col flex-wrap gap-x-4 rounded-xl border border-themeGray bg-lightBlue p-5`}
							>
								<p className='mb-3 text-center text-lg-responsive font-semibold text-[#8D8D8E]'>
									Compare{' '}
									<span className='text-[#101828]'>
										{playerDetails?.player_information.first_name}{' '}
										{playerDetails?.player_information.last_name}
									</span>{' '}
									stats with other Athletes
								</p>
								<div className='flex items-center justify-center'>
									{athleteConfig?.currentAthlete?.profile_picture ? (
										<ProfileImgFrameIcon
											width='78'
											height='69'
											imgURL={athleteConfig?.currentAthlete?.profile_picture}
										/>
									) : (
										<div className='relative'>
											<FrameIcon />
											<ProfileIcon className='absolute left-[38%] top-[35%]' />
										</div>
									)}
									<p className='mx-3 text-lg font-semibold text-textGray'>vs</p>
									{athleteConfig?.selectedAthlete?.profile_picture ? (
										<ProfileImgFrameIcon
											width='78'
											height='69'
											imgURL={athleteConfig?.selectedAthlete?.profile_picture}
										/>
									) : (
										<div
											className='relative cursor-pointer'
											onClick={() => openModal(AthleteModal.SELECT_ATHLETE)}
										>
											<FrameIcon />
											<AddUserIcon className='absolute left-[38%] top-[35%] stroke-[#667085]' />
										</div>
									)}
								</div>
								<ButtonSecondary
									className='mb-4 mt-6'
									btnTxt='Compare prospect'
									onClick={() => openModal(AthleteModal.SELECT_ATHLETE)}
								/>
								<div className='my-3 border' />
								<div className='flex h-10 items-center justify-center rounded-lg bg-[#0057b72e]'>
									<p
										className={`mr-4 text-[#101828] ${
											viewType === ViewType.Table_View ? 'text-lg' : 'text-sm 2xl:text-lg'
										}`}
									>
										{playerDetails?.player_information.first_name}{' '}
										{playerDetails?.player_information.last_name}'s OPS
									</p>
									<p className='text-xl font-semibold text-[#101828]'>
										{athleteConfig.currentAthlete.performance.current || 0}
									</p>
								</div>
							</div>
						)}
						<SocialMediaExpand playerDetails={playerDetails} viewType={viewType} />
					</div>
				)}
				{loading && (
					<div className='text-center flex items-center justify-center'>
						<Spinner aria-label='Extra large Center-aligned spinner example' size='xl' />
					</div>
				)}
			</div>
			{isOpen && type === AthleteModal.SELECT_ATHLETE && (
				<SelectAthlete
					show={isOpen}
					onClose={closeModal}
					handelSelectAthlete={handelSelectAthlete}
					currentId={athleteConfig.currentAthlete.id}
				/>
			)}
			{isOpen && type === AthleteModal.COMPARE_ATHLETE && (
				<CompareAthlete
					show={true}
					onClose={() => {
						setAthleteConfig({ currentAthlete: record, selectedAthlete: {} as IPlayers });
						closeModal();
					}}
					comparisonIds={{
						currentId: athleteConfig.currentAthlete.id,
						selectedId: athleteConfig.selectedAthlete.id
					}}
				/>
			)}
		</>
	);
};

export default ExpandRow;
