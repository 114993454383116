export const API_BASE_URL = process.env['REACT_APP_BASE_URL'];
export const ENVIRONMENT = process.env['REACT_APP_ENVIRONMENT'] || '';
export const SENTRY_DSN = process.env['REACT_APP_SENTRY_DSN'] || '';
export const BUCKET_NAME = process.env['REACT_APP_BUCKET'];
export const FILE_NAME = process.env['REACT_APP_FILE_NAME'];
export const TENNIS_FILE_NAME = process.env['REACT_APP_TENNIS_FILE_NAME'];

export const TOURNAMENT_VIDEO_OPTIONS = [
	{ label: 'Tournament 1', value: 'Tournament 1' },
	{ label: 'Tournament 2', value: 'Tournament 2' },
	{ label: 'Tournament 3', value: 'Tournament 3' },
	{ label: 'Tournament 4', value: 'Tournament 4' }
];
export const TOURNAMENT_OPTIONS = [
	{ label: 'All Tournament', value: '' },
	{ label: 'European Junior Championships', value: 'European Junior Championships' },
	{ label: 'World Junior Championship', value: 'World Junior Championship' },
	{ label: 'Asia Junior Championships', value: 'Asia Junior Championships' },
	{ label: 'PanAm Junior Championships', value: 'PanAm Junior Championships' },
	{ label: 'German Junior', value: 'German Junior' },
	{ label: 'Dutch Junior', value: 'Dutch Junior' },
	{ label: 'Junior National Championships', value: 'Junior National Championships' },
	{ label: 'Other', value: 'Other' }
];

export const TENNIS_TOURNAMENT_OPTIONS = [
	{ label: 'All Tournament', value: '' },
	{ label: 'ITF J200 Bratislava- Zdenek', value: 'ITF J200 Bratislava- Zdenek' },
	{ label: 'Australian Open ITF (GA)', value: 'Australian Open ITF (GA)' },
	{ label: 'LES PETITS AS U14 (super category)', value: 'LES PETITS AS U14 (super category)' },
	{ label: 'OBERHACHING ITF J200', value: 'OBERHACHING ITF J200' },
	{ label: 'TIM ESSONE U14 (G1)', value: 'TIM ESSONE U14 (G1)' },
	{ label: 'VAMOS J Zagreb', value: 'VAMOS J Zagreb' },
	{ label: 'KUNGENS KANNA TE U12 super category', value: 'KUNGENS KANNA TE U12 super category' },
	{ label: 'AURAY U12 (G1)', value: 'AURAY U12 (G1)' },
	{ label: 'VALENCIA J200', value: 'VALENCIA J200' },
	{ label: 'BENICARLO J200', value: 'BENICARLO J200' },
	{ label: 'VRSAR U12 (G1)', value: 'VRSAR U12 (G1)' },
	{ label: 'VILLENA ITF J300', value: 'VILLENA ITF J300' },
	{ label: 'VRSAR ITF J300', value: 'VRSAR ITF J300' },
	{ label: "CAP D'AIL J200", value: "CAP D'AIL J200" },
	{ label: 'PLOVDIV (Bulgaria) J300', value: 'PLOVDIV (Bulgaria) J300' },
	{ label: 'MAYA U14 (super category)', value: 'MAYA U14 (super category)' },
	{ label: 'BEAULIEU SUR MER ITF J300', value: 'BEAULIEU SUR MER ITF J300' },
	{ label: 'IMG FUTURE STARS U12', value: 'IMG FUTURE STARS U12' },
	{ label: 'NERIDE CUP U12 (G1)', value: 'NERIDE CUP U12 (G1)' },
	{ label: 'MLADOST GRILL OPEN ZAGREB U14 (G2)', value: 'MLADOST GRILL OPEN ZAGREB U14 (G2)' },
	{ label: 'ITF OFFENBACH J500', value: 'ITF OFFENBACH J500' },
	{ label: 'MOST CZECH R. U14 (G1)', value: 'MOST CZECH R. U14 (G1)' },
	{ label: 'VILLACH (Austria) J200', value: 'VILLACH (Austria) J200' },
	{ label: 'PASSAGESPOIRS FRANCE (G1)', value: 'PASSAGESPOIRS FRANCE (G1)' },
	{ label: 'BONFIGLIO MILANO ITF J500', value: 'BONFIGLIO MILANO ITF J500' },
	{ label: 'KOPER SLOVENIA U12 (G1)', value: 'KOPER SLOVENIA U12 (G1)' },
	{ label: 'CROATIA MARIN SUICA OPEN ITF (G3)', value: 'CROATIA MARIN SUICA OPEN ITF (G3)' },
	{ label: 'EURO REGION NISA CUP U12 CZECH R. (G1)', value: 'EURO REGION NISA CUP U12 CZECH R. (G1)' },
	{ label: 'Roland Garros ITF', value: 'Roland Garros ITF' },
	{ label: 'CACAK (SERBIA) TE U12 (G1)', value: 'CACAK (SERBIA) TE U12 (G1)' },
	{ label: 'AYLESBURY, GREAT BRITAIN, TE U12 (G1)', value: 'AYLESBURY, GREAT BRITAIN, TE U12 (G1)' },
	{ label: 'SMRIKVA BOWL U10, CROATIA', value: 'SMRIKVA BOWL U10, CROATIA' },
	{ label: 'RAKOVNIK TE U12 (G1)', value: 'RAKOVNIK TE U12 (G1)' },
	{ label: 'PORTO SAN GIORGIO TE U12 (G1)', value: 'PORTO SAN GIORGIO TE U12 (G1)' },
	{ label: 'PILZEN ITF (J200)', value: 'PILZEN ITF (J200)' },
	{ label: 'ROEHAMPTON ITF (J300)', value: 'ROEHAMPTON ITF (J300)' },
	{ label: 'AMSTEL PARK TE U12, U14', value: 'AMSTEL PARK TE U12, U14' },
	{ label: 'WIMBLEDON ITF', value: 'WIMBLEDON ITF' },
	{ label: 'STADE FRANCAIS U14 (G1)', value: 'STADE FRANCAIS U14 (G1)' },
	{ label: 'Dub Bowl', value: 'Dub Bowl' },
	{ label: 'MOST U14 EU championship (Super category)', value: 'MOST U14 EU championship (Super category)' },
	{ label: 'PREROV TE U16 EU Championship', value: 'PREROV TE U16 EU Championship' },
	{ label: 'KLOSTERS ITF (J300) U18 EU championship', value: 'KLOSTERS ITF (J300) U18 EU championship' },
	{ label: 'HAGUE (J100)', value: 'HAGUE (J100)' },
	{ label: 'ANNECY (France) TE U 14 (G1)', value: 'ANNECY (France) TE U 14 (G1)' },
	{ label: 'BYTOM ITF (J300)', value: 'BYTOM ITF (J300)' },
	{ label: 'PISEK CUP TE U12 (G1)', value: 'PISEK CUP TE U12 (G1)' },
	{ label: 'WORLD JUNIOR TENNIS, PROSTEJOV U14', value: 'WORLD JUNIOR TENNIS, PROSTEJOV U14' },
	{ label: 'RENNINGEN ITF (J200)', value: 'RENNINGEN ITF (J200)' },
	{
		label: 'DIJON SUMMER CUP FINALS boys U12 (10-13 August)',
		value: 'DIJON SUMMER CUP FINALS boys U12 (10-13 August)'
	},
	{ label: 'PRAGUE ITF (J300)', value: 'PRAGUE ITF (J300)' },
	{ label: 'REGENSBURG TE U12 (G1)', value: 'REGENSBURG TE U12 (G1)' },
	{ label: 'PANCEVO ITF (J300)', value: 'PANCEVO ITF (J300)' },
	{ label: 'BUDAPEST ITF (J100)', value: 'BUDAPEST ITF (J100)' },
	{ label: 'KOBLENZ (Germany) TE U12 (G1)', value: 'KOBLENZ (Germany) TE U12 (G1)' },
	{ label: 'US OPEN ITF', value: 'US OPEN ITF' },
	{ label: 'RAKOVNIK ITF (J200)', value: 'RAKOVNIK ITF (J200)' },
	{ label: 'BEIJING ITF (J300)', value: 'BEIJING ITF (J300)' },
	{ label: 'BRESSUIRE (France) TE U12 (G1)', value: 'BRESSUIRE (France) TE U12 (G1)' },
	{ label: 'OSAKA ITF (J500)', value: 'OSAKA ITF (J500)' },
	{ label: 'SANXENXO (Spain) ITF (J300)', value: 'SANXENXO (Spain) ITF (J300)' },
	{ label: 'TELDE (Spain) TE U12 (G1)', value: 'TELDE (Spain) TE U12 (G1)' },
	{ label: 'SZEKESFEHERVAR (Hungary) ITF (J200)', value: 'SZEKESFEHERVAR (Hungary) ITF (J200)' },
	{ label: 'JUNIOR DAVIS CUP U16 (Cordoba, Spain)', value: 'JUNIOR DAVIS CUP U16 (Cordoba, Spain)' },
	{ label: 'BILLIE JEAN KING CUP U16 (Cordoba, Spain)', value: 'BILLIE JEAN KING CUP U16 (Cordoba, Spain)' },
	{ label: 'RAFA NADAL FESTIVAL U12', value: 'RAFA NADAL FESTIVAL U12' },
	{ label: 'EDDIE HERR ITF, U12, U14 (J300)', value: 'EDDIE HERR ITF, U12, U14 (J300)' },
	{ label: 'ORANGE BOWL ITF (J500)', value: 'ORANGE BOWL ITF (J500)' },
	{ label: 'ORANGE BOWL U12, U14 (MD start Dec 13th)', value: 'ORANGE BOWL U12, U14 (MD start Dec 13th)' }
];

export const dropdownOptions = [
	{
		value: 'Headquarters',
		label: 'Headquarters',
		nested: false
	},
	{
		value: 'Subsidiary',
		label: 'Subsidiary',
		nested: true,
		nestedOptions: [
			{ value: 'YONEX Gmbh (GERMANY)', label: 'YONEX Gmbh (GERMANY)' },
			{ value: 'YONEX SPORTS (CHINA) CO., LTD.', label: 'YONEX SPORTS (CHINA) CO., LTD.' },
			{ value: 'YONEX JAPAN', label: 'YONEX JAPAN' },
			{ value: 'YONEX CANADA', label: 'YONEX CANADA' },
			{ value: 'YONEX U.K. Ltd.', label: 'YONEX U.K. Ltd.' },
			{ value: 'YONEX TAIWAN Co., Ltd.', label: 'YONEX TAIWAN Co., Ltd.' }
		]
	},
	{
		value: 'Distributor',
		label: 'Distributor',
		nested: true,
		nestedOptions: [
			{ value: `Mexico: La Loma Proshop`, label: `Mexico: La Loma Proshop` },
			{ value: `Argentina: SUPERBRANDS S.A.`, label: `Argentina: SUPERBRANDS S.A.` },
			{ value: `Brazil: Licensing Development`, label: `Brazil: Licensing Development` },
			{ value: `Chile: SPORTING BRANDS LTDA`, label: `Chile: SPORTING BRANDS LTDA` },
			{ value: `Panama: Supro Mundial, S.A.`, label: `Panama: Supro Mundial, S.A.` },
			{
				value: `Botswana,Namibia,SouthAfrica and Zambia: Aurora Sports (PTY)LTD`,
				label: `Botswana,Namibia,SouthAfrica and Zambia: Aurora Sports (PTY)LTD`
			},
			{ value: `Egypt: De Quanta`, label: `Egypt: De Quanta` },
			{ value: `Other Countries in Africa: YONEX Co. Ltd.`, label: `Other Countries in Africa: YONEX Co. Ltd.` },
			{
				value: `Denmark, Sweden, Norway, Finland, Iceland: Scansport A/S`,
				label: `Denmark, Sweden, Norway, Finland, Iceland: Scansport A/S`
			},
			{
				value: `Belgium, Netherlands, Luxembourg: Distri Sport International NV`,
				label: `Belgium, Netherlands, Luxembourg: Distri Sport International NV`
			},
			{
				value: `France, Spain, Portugal: BDE-Boyauderie de L'est`,
				label: `France, Spain, Portugal: BDE-Boyauderie de L'est`
			},
			{ value: `Switzerland: Y GmbH`, label: `Switzerland: Y GmbH` },
			{ value: `Italy: FASSA S.R.L.`, label: `Italy: FASSA S.R.L.` },
			{ value: `Russia: Preppy Consulting LLC`, label: `Russia: Preppy Consulting LLC` },
			{ value: `Thailand: Far East Speciality Co., Ltd.`, label: `Thailand: Far East Speciality Co., Ltd.` },
			{ value: `Korea: Dong Seung Trading Co., Ltd.`, label: `Korea: Dong Seung Trading Co., Ltd.` },
			{ value: `Singapore: Sunrise & Company (Pte) Ltd.`, label: `Singapore: Sunrise & Company (Pte) Ltd.` },
			{ value: `Malaysia: Sunrise-Sports Sdn. Bhd.`, label: `Malaysia: Sunrise-Sports Sdn. Bhd.` },
			{ value: `Indonesia: Sunrise & Company (Pte) Ltd.`, label: `Indonesia: Sunrise & Company (Pte) Ltd.` },
			{ value: `Hong Kong: Sunrise Sports Limited`, label: `Hong Kong: Sunrise Sports Limited` },
			{ value: `India: Sunrise Sport (India) Pvt Ltd.`, label: `India: Sunrise Sport (India) Pvt Ltd.` },
			{ value: `Philippines: Sunrise & Co. Pte Ltd`, label: `Philippines: Sunrise & Co. Pte Ltd` },
			{ value: `Vietnam: SUNRISE SPORTSGEAR VIETNAM LLC`, label: `Vietnam: SUNRISE SPORTSGEAR VIETNAM LLC` },
			{ value: `Myanmar: KAUNG MYAT CO., LTD.`, label: `Myanmar: KAUNG MYAT CO., LTD.` },
			{ value: `Laos: PRO CORPORATE SOLE CO., LTD.`, label: `Laos: PRO CORPORATE SOLE CO., LTD.` },
			{
				value: `Cambodia: Main Vision (Cambodia) Co., Ltd.`,
				label: `Cambodia: Main Vision (Cambodia) Co., Ltd.`
			},
			{ value: `UAE: Al Raed Pioneers`, label: `UAE: Al Raed Pioneers` },
			{ value: `Oman: Modern Business Enterprise LLC`, label: `Oman: Modern Business Enterprise LLC` },
			{
				value: `Kuwait: Professional Sports General Trading Co.`,
				label: `Kuwait: Professional Sports General Trading Co.`
			},
			{
				value: `Saudi Arabia: Nasser Hamdan Al Hamdan Est. (Raed Sport)`,
				label: `Saudi Arabia: Nasser Hamdan Al Hamdan Est. (Raed Sport)`
			},
			{
				value: `Qatar: UNITED COOPERATION GENERAL TRADING WLL`,
				label: `Qatar: UNITED COOPERATION GENERAL TRADING WLL`
			},
			{
				value: `Other Countries in Middle East: YONEX CO., Ltd.`,
				label: `Other Countries in Middle East: YONEX CO., Ltd.`
			},
			{ value: `Australia: Gray-Nicolls Sports Pty Ltd.`, label: `Australia: Gray-Nicolls Sports Pty Ltd.` },
			{ value: `New Zealand: Gray-Nicolls Sports (NZ) Ltd.`, label: `New Zealand: Gray-Nicolls Sports (NZ) Ltd.` }
		]
	},
	{
		value: 'Scouting team',
		label: 'Scouting team',
		nested: false
	}
];

export enum SPORT {
	Badminton = 'Badminton',
	Tennis = 'Tennis'
}

export const SPORTS_OPTIONS = [
	{ label: 'Badminton', value: SPORT.Badminton },
	{ label: 'Tennis', value: SPORT.Tennis }
];

export const INTERNAL_DEPARTMENT_OPTIONS = [
	{ label: 'Top management', value: 'Top management' },
	{ label: 'Promotion', value: 'Promotion' },
	{ label: 'International sales', value: 'International sales' },
	{ label: 'Advertisement', value: 'Advertisement' },
	{ label: 'Marketing', value: 'Marketing' }
];

export const INTERNAL_DEPARTMENT_SCOUT_OPTIONS = [{ label: 'Scout', value: 'Scout' }];

export const ACCESS_TYPE_OPTIONS = [
	{ label: 'Level 1', value: 'Level 1' },
	{ label: 'Level 2', value: 'Level 2' },
	{ label: 'Level 3', value: 'Level 3' },
	{ label: 'Level 4', value: 'Level 4' }
];

export const MODULE_OPTIONS = [
	{ label: 'Athlete Management & Database', value: 'Athlete Management & Database' },
	{ label: 'Scouting & Recruiting', value: 'Scouting & Recruiting' },
	{ label: 'Contract Management', value: 'Contract Management' }
];
