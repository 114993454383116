import { FC, MouseEvent, ReactElement } from 'react';
import { Button, Spinner } from 'flowbite-react';
import { ButtonTheme } from './primary';

interface IProps {
	btnTxt: string;
	className?: string;
	type?: 'submit' | 'reset';
	isLoading?: boolean;
	onClick: (e: MouseEvent) => void;
	icon?: ReactElement;
	disabled?: boolean;
}

const ButtonSecondary: FC<IProps> = ({ className, btnTxt, type, onClick, icon, isLoading, disabled = false }) => {
	return (
		<Button
			type={type || 'button'}
			className={`ring-none border-primary font-semibold text-primary ${className}`}
			color='light'
			theme={ButtonTheme}
			disabled={disabled || isLoading}
			//isProcessing={isLoading || false}
			onClick={onClick}
		>
			{icon && icon}
			{isLoading && <Spinner aria-label='Spinner button' className='mr-2' size='sm' theme={Theme} />}
			{btnTxt}
		</Button>
	);
};

export default ButtonSecondary;

const Theme = {
	color: {
		info: 'fill-primary'
	},
	isProcessing: 'cursor-wait'
};
