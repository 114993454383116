import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// import ButtonPrimary from 'shared/components/buttons/primary';
// import ButtonSecondary from 'shared/components/buttons/secondary';
import useOnClickOutside from 'shared/hooks/useOutSideClick';
import { CloseIcon } from 'shared/icon';
import { formatDate } from 'shared/util/utility';

interface IProps {
	formateType?: string;
	title: string;
	onChange: (range: Range) => void;
	isFormSubmit?: boolean;
	required?: boolean;
	errorMsg?: string;
	dateRange?: Range;
	isLabel?: boolean;
	onCancel: () => void;
	className?: string;
	disabled?: boolean;
}

export const DateRange: React.FC<IProps> = ({
	formateType = 'YYYY-MM-DD',
	title,
	onChange,
	isFormSubmit,
	errorMsg,
	required,
	dateRange,
	onCancel,
	isLabel = true,
	disabled = false, // Default to false

	className
}) => {
	const [ranges, setRanges] = useState<Range>({ ...dateRange, key: 'selection' });
	const [isOpen, setIsOpen] = useState(false);
	const popupRef = useRef(null);
	useOnClickOutside(popupRef, () => {
		if (!disabled) setIsOpen(false);
	});
	useEffect(() => {
		if (isFormSubmit) {
			setRanges({
				startDate: undefined,
				endDate: undefined,
				key: 'selection'
			});
		}
	}, [isFormSubmit]);
	const isSelected = useMemo(() => ranges.startDate || ranges.endDate, [ranges]);
	return (
		<div className={`relative ${disabled ? 'text-themeGray-text' : ''}`} ref={popupRef}>
			{isLabel && (
				<label className={`input-label ${disabled ? 'text-themeGray-text' : ''}`}>
					{title}
					{required && <span className='text-xs'>*</span>}
				</label>
			)}

			<div
				className={`${
					disabled ? '' : 'cursor-pointer'
				} relative text-sm-responsive py-2 px-[6px] border rounded-lg border-themeGray ${
					!isSelected ? 'text-themeGray-text font-semibold' : ''
				}`}
				onClick={() => !disabled && setIsOpen(!isOpen)}
			>
				{isSelected ? (
					<>
						{ranges.startDate ? formatDate(ranges.startDate as unknown as string, formateType) : ''}
						&nbsp; to &nbsp;
						{ranges.endDate ? formatDate(ranges.endDate as unknown as string, formateType) : ''}
						{!disabled && (
							<button
								className='absolute right-2 top-3 text-themeGray-text'
								onClick={(e) => {
									e.stopPropagation();
									onCancel();
									setRanges({ startDate: undefined, endDate: undefined, key: 'selection' });
								}}
							>
								<CloseIcon />
							</button>
						)}
					</>
				) : (
					'Select Date'
				)}
			</div>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
			{isOpen && !disabled && (
				<div
					className={`date-range-picker absolute z-20 bg-white mt-1 p-1 pb-2 rounded-lg border-themeGray border ${className}`}
				>
					<DateRangePicker
						onChange={(item: any) => {
							const { selection } = item;
							setRanges(selection);
							onChange(selection);
						}}
						startDatePlaceholder='Start Date'
						endDatePlaceholder='End Date'
						ranges={[ranges]}
						months={1}
						inputRanges={[]}
					/>
					{/* <div className='mt-1 mb-1 date-range-actions flex items-center content-center px-3'>
						<ButtonSecondary
							className='mr-5'
							onClick={() => {
								setIsOpen(false); // Close the date picker
							}}
							btnTxt='Cancel'
						/>
						<ButtonPrimary
							disable={!ranges.startDate || !ranges.endDate} // Disable when no date is selected
							className='mr-5'
							onClick={() => {
								setIsOpen(false);
								onChange(ranges);
							}}
							btnTxt='Submit'
						/>
					</div> */}
				</div>
			)}
		</div>
	);
};
