import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal } from 'flowbite-react';
import { isEmpty } from 'lodash';

import ButtonPrimary from 'shared/components/buttons/primary';
import { useAthleteContext } from 'store/athleteStore';
import ButtonSecondary from 'shared/components/buttons/secondary';
import { IKpiDataNew, IKpiValueNew } from 'features/addPlayer/interface';
import Loader from 'shared/components/spinner/spinner';
import TooltipSlider from 'shared/form/slider';
import { BackIcon } from 'shared/icon';
import { useAuthContext } from 'store/userDetailStore';

interface IProps {
	show: boolean;
	dataFilters: any[];
	onClose: () => void;
	onChangeFilter: (filters: any) => void;
}

export type FilterValues = {
	sport: string;
	circuit_level: string;
	discipline: string;
	country_id: number | string;
	status: string;
	gender: string;
};

interface IKPIData {
	[key: number]: number;
}

const DataFilterModal: React.FC<IProps> = ({ show, dataFilters, onClose, onChangeFilter }) => {
	const { kpiData: badmintonKpiData, tennisKpiData, kpiLoading } = useAthleteContext();
	const { isTennis } = useAuthContext();

	const [kpiValues, setKpiValues] = useState<IKPIData>({});
	const kpiData = useMemo(() => (isTennis ? tennisKpiData : badmintonKpiData), [isTennis]);
	useEffect(() => {
		if (dataFilters && dataFilters.length > 0) {
			setKpiValues(() => {
				const kpiObj: IKPIData = {};
				dataFilters.forEach(({ kpi_id, value }: any) => {
					kpiObj[kpi_id] = value;
				});
				return kpiObj;
			});
		}
	}, [dataFilters]);

	const isKPIValues = useMemo(() => !isEmpty(kpiValues), [kpiValues]);

	const onSubmit = () => {
		let KPIArray: { kpi_id: number; value: number }[] = [];
		KPIArray = Object.entries(kpiValues).map(([kpi_id, value]) => ({
			kpi_id: Number(kpi_id),
			value
		}));

		onChangeFilter(KPIArray);
		onClose();
	};

	const resetFilters = useCallback(() => {
		setKpiValues({});
		onChangeFilter([]);
		onClose();
	}, [setKpiValues, onChangeFilter, onClose]);

	const handleChangeSlider = (value: number, kpiId: number) => {
		setKpiValues((prevState) => ({
			...prevState,
			[kpiId]: value // Update the value for the specific KPI
		}));
	};

	return (
		<Modal show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg bg-lightBlue flex items-center justify-between border-b-2 border-primary'>
				<div className='flex items-center justify-center gap-2'>
					<div
						className='flex items-center justify-center border-themeGray border bg-white size-10 rounded-md shadow-sm cursor-pointer'
						onClick={onClose}
					>
						<BackIcon width='15px' />
					</div>
					<p className='header-txt'>Data Filter</p>
				</div>
				<div className='flex gap-3 w-1/3'>
					<ButtonSecondary
						className='w-1/2'
						btnTxt={'Reset Filter'}
						disabled={isEmpty(dataFilters)}
						onClick={resetFilters}
					/>
					<ButtonPrimary disable={!isKPIValues} className='w-1/2' btnTxt={'Apply'} onClick={onSubmit} />
				</div>
			</Modal.Header>
			<Modal.Body>
				{kpiLoading && (
					<div className='h-[700px]'>
						<Loader size={24} />
					</div>
				)}

				{!kpiLoading && (
					<div className={`${isTennis ? 'flex-col-reverse' : ''} flex w-full gap-4 mt-4 `}>
						{!isEmpty(kpiData) &&
							kpiData.map((data: IKpiDataNew) => {
								return (
									<div className={`${!isTennis && 'w-[14%]'}`} key={data.title}>
										<div className='flex justify-between items-center border-b p-1 border-themeGray mb-3'>
											<div className='flex'>
												<p className='text-textBlack font-semibold text-sm-responsive'>
													{data.title}
												</p>
											</div>
										</div>
										<div className={`${isTennis && 'flex gap-4'}`}>
											{data.kpisData.map((items: IKpiValueNew) => (
												<TooltipSlider
													key={items.id}
													value={kpiValues[items.id] || 0}
													title={items.name}
													className={`max-w-[280px] p-1 mt-2 rounded-lg border bg-lightBlue `}
													onChange={(value) => handleChangeSlider(value, items.id)}
													isSize={false}
													isTennis={isTennis}
												/>
											))}
										</div>
									</div>
								);
							})}
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};
export default DataFilterModal;
const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	header: {
		base: 'flex items-start justify-between rounded-t p-3 dark:border-gray-600',
		title: 'text-xl font-medium w-full flex items-center justify-between px-3 py-2'
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[1200px]'
	},
	body: {
		base: 'flex-1 px-3 pb-3 overflow-auto custom-scrollbar'
	}
};
