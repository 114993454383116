import { Modal } from 'flowbite-react';
import React from 'react';
import ButtonSecondary from '../buttons/secondary';
import ButtonPrimary from '../buttons/primary';
import { InfoIcon } from 'shared/icon';

interface IProps {
	className?: string;
	primaryText?: string;
	secondaryText?: string | any;
	show: boolean;
	disable?: boolean;
	onClose: () => void;
	onSubmit: () => void;
	btnPrimaryTxt: string;
	btnSecondaryTxt: string;
	btnText?: string;
}

const ConfirmModal: React.FC<IProps> = (props) => {
	const { className, show, onClose, onSubmit, secondaryText, primaryText, btnSecondaryTxt, btnPrimaryTxt, btnText } =
		props;
	return (
		<Modal className={`${className || ''}`} show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
				<div className='size-10 bg-[#EAECF0] shadow-sm flex items-center justify-center rounded-lg'>
					<InfoIcon className='stroke-[#0057B7]' />
				</div>
			</Modal.Header>
			<Modal.Body>
				{primaryText && <p className='header-txt'>{primaryText}</p>}
				{secondaryText && <p className='sub-header-txt '>{secondaryText}</p>}
				{btnText && <p className='text-xs text-yellow-500 mt-2 '>{btnText}</p>}
			</Modal.Body>
			<Modal.Footer>
				<ButtonSecondary className='w-full' onClick={onClose} btnTxt={btnSecondaryTxt} />
				<ButtonPrimary className='w-full' onClick={onSubmit} btnTxt={btnPrimaryTxt} />
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmModal;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	header: {
		base: 'flex items-start justify-between p-4 dark:border-gray-600'
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[400px]'
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};
