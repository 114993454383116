import { AccessMangeIcon, AddUserIcon, EquipmentIcon, LogoutIcon } from 'shared/icon';

export const MENU_LIST = [
	//{
	//	icon: <SettingIcon className='mr-4' />,
	//	title: 'accountSetting',
	//	desc: 'accountSettingText'
	//},
	//{
	//	icon: <NotificationMangeIcon className='mr-4' />,
	//	title: 'notificationsManagement',
	//	desc: 'notificationsManagementText'
	//},
	{
		icon: <AccessMangeIcon className='mr-4' />,
		title: 'accessManagement',
		desc: 'accessManagementText'
	},
	//{
	//	icon: <HelpAndSupportIcon className='mr-4' />,
	//	title: 'helpSupport',
	//	desc: 'helpSupportText'
	//},
	{
		icon: <LogoutIcon className='mr-4' />,
		title: 'logout'
	}
];

export const PLAYER_MANAGE_LIST = [
	{
		icon: <AddUserIcon />,
		title: 'athleteRegistration',
		desc: 'athleteRegistrationText',
		path: '/add-player'
	},
	{
		icon: <EquipmentIcon />,
		title: 'athleteDatabase',
		desc: 'athleteDatabaseText',
		path: '/athlete'
	}
];

export const ATHLETE_PATH = ['/athlete', '/add-player', '/add-player/:player_Id', 'player-profile/:id'];
export const SCOUTING_PATH = ['/all-athletes', '/shortlist', '/recruiting-dashboard'];
export const CONTRACT_MANAGEMENT = [
	'/contract-management',
	'/contract/tournament',
	'/contract/team',
	'/contract/individual',
	'/contract/tournament/:id',
	'/contract/team/:id',
	'/contract/individual/:id',
	'/tournament/contract-details/:id',
	'/team/contract-details/:id',
	'/individual/contract-details/:id'
];
export const DASHBOARD = ['/dashboard'];

export const USER_TABLE_CELL = [
	{ title: 'User Name', key: 'userName' },
	{ title: 'Work email', key: 'userEmail' },
	{ title: 'Access', key: 'access' }
];

export const TABS = [
	{
		title: 'Invite to the Platform',
		key: 'inviteToThePlatform'
	},
	{
		title: 'Manage Access',
		key: 'manageAccess'
	}
];

export enum TAB_KEY {
	INVITE_TO_PLATFORM = 'inviteToThePlatform',
	ACCESS_MANAGE = 'manageAccess'
}

export const USER_DATA = [
	{ userId: '1', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{
		userId: '2',
		userName: 'Tadash Sano',
		profileUrl: 'https://flowbite.com/docs/images/people/profile-picture-5.jpg',
		userEmail: 'tadashi.sano@gmail.com'
	},
	{ userId: '3', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{ userId: '4', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{ userId: '5', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{
		userId: '6',
		userName: 'Tadash Sano',
		profileUrl: 'https://flowbite.com/docs/images/people/profile-picture-5.jpg',
		userEmail: 'tadashi.sano@gmail.com'
	},
	{ userId: '7', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{ userId: '8', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{ userId: '9', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' },
	{
		userId: '10',
		userName: 'Tadash Sano',
		profileUrl: 'https://flowbite.com/docs/images/people/profile-picture-5.jpg',
		userEmail: 'tadashi.sano@gmail.com'
	},
	{ userId: '11', userName: 'Tadash Sano', profileUrl: '', userEmail: 'tadashi.sano@gmail.com' }
];

export enum CommonModal {
	CREATE_SCOUT = 'Create',
	SHOW_LIST = 'View List',
	QUICK_ADD = 'Quick Add',
	IMPORT_POPUP = 'Import Popup',
	EXPORT_POPUP = 'Export Popup'
}

export enum ATHLETE {
	senior_athlete = 'Senior Athlete',
	junior_athlete = 'Junior Athlete'
}

export enum NOTIFICATION_TYPE {
	VIEW_NOTIFICATION = 'View NOtification',
	CHANGE_REQUEST = 'Change Request'
}

export enum TYPE {
	ACCESS_NOTIFICATION = 'Access Change Request Notification',
	STATUS_NOTIFICATION = 'Access Change Request Status Notification'
}

export const avatarTheme = {
	root: {
		img: {
			base: 'object-cover object-top'
		}
	}
};

export enum UserFilter {
	SEARCH = 'search'
}

export const initFilter = {
	[UserFilter.SEARCH]: ''
};

export type IUserFilters = {
	[key in UserFilter]: string;
};
