import { FC, useMemo, useContext } from 'react';
import { Button } from 'flowbite-react';

import { ButtonTheme } from 'shared/components/buttons/primary';
import { AuthContext } from 'store/userDetailStore';
import { buttonGroupTheme } from 'features/athleteDatabase/constant';
import { SPORT, SPORTS_OPTIONS } from 'shared/constants';

interface IProps {
	// selectedValue: string;
	// onChange: (value: string) => void;
	buttonTheme?: any;
	disabled?: boolean;
	reset?: () => void | undefined;
}

const SportsFilter: FC<IProps> = (props) => {
	const { buttonTheme, disabled = false, reset } = props;

	const { userData, sport, changeSports } = useContext(AuthContext);

	const filteredSports: ISportOptions = useMemo(() => {
		if (userData.sports === 'Both') {
			return SPORTS_OPTIONS;
		} else {
			return [];
		}
	}, [userData.sports]);

	const handleSportChange = (newSport: SPORT) => {
		if (sport !== newSport) {
			changeSports(newSport);
			// Reset form values when the sport changes
			reset && reset();
		}
	};

	return filteredSports.length ? (
		<Button.Group outline theme={buttonGroupTheme} className='mr-3 h-fit'>
			{filteredSports.map(({ label, value }) => {
				return (
					<Button
						key={value}
						disabled={disabled}
						className={`ring-none bg-lightBlue1 ${
							sport === value ? '!bg-primary !text-white' : 'text-gray-400'
						} `}
						color='gray'
						theme={buttonTheme ?? initTheme}
						//onClick={() => sport !== value && changeSports(value)}
						onClick={() => handleSportChange(value)}
					>
						{label}
					</Button>
				);
			})}
		</Button.Group>
	) : (
		<></>
	);
};

const initTheme = {
	size: {
		...ButtonTheme.size
	}
};

type ISportOptions = {
	label: string;
	value: SPORT;
}[];

export default SportsFilter;
