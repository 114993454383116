import React, { useMemo } from 'react';
import { isNull } from 'lodash';

import { ShortIcon } from 'shared/icon';
import { IPerformance } from '../interface';

interface IProps {
	performData: IPerformance;
	className?: string;
}

const Perform: React.FC<IProps> = ({ performData, className }) => {
	const performance = useMemo(
		() => ({
			//current: +performData?.current || 0,
			//difference: Math.abs(+performData?.difference || 0),
			current: (+performData?.current || 0).toFixed(2), // Ensure it has 2 decimal places
			difference: Math.abs(+performData?.difference || 0), // Absolute value with 2 decimals
			isNegative: (+performData?.difference || 0) < 0,
			isPositive: (+performData?.difference || 0) > 0
		}),
		[performData]
	);

	return (
		<>
			{!isNull(performData?.current) || !isNull(performData?.difference) ? (
				<div className={`flex items-center justify-center ${className}`}>
					<div>{performance.current}</div>
					<div className='ml-1 flex flex-col items-center justify-center'>
						<ShortIcon fill={performance.isPositive ? '#1CA051' : '#D0D5DD'} height='11' />
						<span>{performance.difference}</span>
						<ShortIcon
							className='rotate-180'
							fill={performance.isNegative ? '#FF0000' : '#D0D5DD'}
							height='11'
						/>
					</div>
				</div>
			) : (
				'NA'
			)}
		</>
	);
};

export default Perform;
