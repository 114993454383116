import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import useFlowbite from 'shared/hooks/useFlowbite';
import { IPagination } from 'features/contractManagement/interface';
import InfiniteScrollComponent from 'shared/components/infiniteScroll/infiniteScrollComponent';
import { CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';
import { IDropdownOptions } from 'shared/interface';
import { debounce } from 'shared/util/utility';

interface IProps {
	name: string;
	title: string;
	options: IDropdownOptions[];
	className?: string;
	errorMsg?: string;
	control: any;
	placeHolder?: string;
	menuPosition?: 'top' | 'bottom';
	disabled?: boolean;
	required?: boolean;
	isLabel?: boolean;
	fetchData?: (searchTerm: any, next_page: number, isAppend: boolean) => void | undefined;
	pagination?: IPagination | any;
	isStaticOptions?: boolean;
}

export interface ISearchData {
	label: string;
	value: string;
	name: string;
	iso3: string;
	continent: string;
	emoji: string;
}

const SearchableSelect: FC<IProps> = ({
	name,
	title,
	options,
	className,
	control,
	errorMsg,
	placeHolder,
	menuPosition,
	disabled,
	required,
	isLabel = true,
	pagination,
	fetchData,
	isStaticOptions = false
}) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const [searchValue, setSearchValue] = useState<ISearchData | null>();

	// Close dropdown on outside click
	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setDropdownVisible(false);
		}
	};

	// Add event listener for outside clicks
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	// Create a debounced version of the API call
	const debouncedFetchAthleteData = useCallback(
		debounce((term: any) => {
			fetchData && fetchData(term, 1, false);
		}, 300),
		[fetchData]
	);

	useEffect(() => {
		debouncedFetchAthleteData(searchTerm);
	}, [searchTerm]);

	const closeDropdown = () => {
		setDropdownVisible(false);
	};

	const getLabel = (selectedValue: string) => {
		if (selectedValue) {
			const mainOption = options.find((option) => option.value === selectedValue);
			return mainOption?.label || searchValue?.label;
		} else {
			return null;
		}
	};

	const filteredOptions = useMemo(() => {
		if (isStaticOptions) {
			return options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));
		} else {
			return options;
		}
	}, [options, isStaticOptions, searchTerm]);

	return (
		<div ref={dropdownRef} className={`${className || ''} relative w-full`}>
			{isLabel && (
				<label htmlFor={name} className='input-label'>
					{title}
					{required && <StarIcon height='7px' width='7px' />}
				</label>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => {
					return (
						<>
							<button
								disabled={disabled}
								id='dropdownButton'
								className={`input-select ring-none focus:outline-none focus:ring-4 focus:ring-blue-300 ${
									!value && 'text-themeGray-text'
								}`}
								type='button'
								onClick={() => setDropdownVisible(!dropdownVisible)}
							>
								{getLabel(value) || placeHolder || 'Select'}
								{value ? (
									<button
										className='h-3 w-4 flex justify-end cursor-pointer'
										onClick={(e) => {
											onChange(null);
											e.stopPropagation();
											setSearchValue(null);
										}}
									>
										<CloseIcon className='size-2.5 stroke-[#344054] ' />
									</button>
								) : (
									<DownArrowIcon className='size-2.5 stroke-[#344054]' />
								)}
							</button>

							<div
								id='dropdown'
								className={`absolute z-10 w-full rounded-lg bg-white shadow ${
									dropdownVisible ? 'block' : 'hidden'
								}`}
								style={menuPosition === 'top' ? { transform: 'translate(0,-54px)' } : {}}
							>
								<div>
									<input
										type='text'
										placeholder='Search...'
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
										className='w-full bg-white border-0 text-textGray text-xs rounded-lg relative'
									/>
									{searchTerm && (
										<button
											type='button'
											className=' z-20 absolute right-[10px] top-3 text-gray-500 hover:text-gray-700 cursor-pointer'
											onClick={(e) => {
												e.stopPropagation();
												setSearchTerm('');
											}}
										>
											<CloseIcon className='w-2 h-2 ' />
										</button>
									)}
								</div>

								<InfiniteScrollComponent
									fetchData={() => {
										fetchData && fetchData(searchTerm, pagination?.next_page, true);
									}}
									hasMore={pagination?.next_page < pagination?.total_pages}
									//hasMore={pagination?.total_pages >= pagination?.current_page + 1}
									totalData={filteredOptions.length}
									height='200px'
								>
									<ul className='text-sm-responsive text-gray-700'>
										{filteredOptions.length > 0 ? (
											filteredOptions.map((option) => {
												return (
													<li
														key={option.value}
														onClick={() => {
															setSearchValue(option as ISearchData);
															onChange(option.value);
															closeDropdown();
															setSearchTerm('');
														}}
													>
														<div
															className={`block px-4 py-2 hover:bg-lightBlue1 cursor-pointer ${
																value === option.value ? 'bg-lightBlue1' : ''
															}`}
														>
															{option.label}
														</div>
													</li>
												);
											})
										) : (
											<li className='px-4 py-2 text-gray-500'>No options found</li>
										)}
									</ul>
								</InfiniteScrollComponent>
							</div>
						</>
					);
				}}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default SearchableSelect;
