import { ATHLETES_OPTIONS } from 'features/athleteDatabase/constant';
import { DASHBOARD_FILTER } from '../constant';
import { QUICK_FILTER } from 'features/allAthlates/component/filter/quickFilter';
import { SPORT } from 'shared/constants';

export enum RECRUITING_FILTERS {
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	IS_PINNED = 'is_pinned',
	BOARD_TYPE = 'recruiting_board_type',
	BOARD_NAME = 'recruiting_board_name',
	SORT_BY = 'sort_by',
	ORDER_BY = 'order_by',
	RECRUITING_BOARD_NAME = 'recruiting_board_name',
	SPORTS = 'sports'
}

export enum BOARD_PLAYER_FILTERS {
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	SPORTS = 'sports',
	ATHLETE_TYPE = 'athlete_type',
	SORT_BY = 'sort_by',
	ORDER_BY = 'order_by',
	ATHLETE_NAME = 'athlete_name',
	KPI = 'kpis'
}

export const initRecruitingPlayerFilters = {
	[BOARD_PLAYER_FILTERS.SPORTS]: '',
	[BOARD_PLAYER_FILTERS.ATHLETE_TYPE]: ATHLETES_OPTIONS[2]?.value || '',
	[BOARD_PLAYER_FILTERS.ORDER_BY]: '',
	[BOARD_PLAYER_FILTERS.SORT_BY]: '',
	[BOARD_PLAYER_FILTERS.CURRENT_PAGE]: 1,
	[BOARD_PLAYER_FILTERS.RECORD_PER_PAGE]: 10,
	[BOARD_PLAYER_FILTERS.KPI]: '',
	[BOARD_PLAYER_FILTERS.ATHLETE_NAME]: ''
};

export const initTennisRecruitingPlayerFilters = {
	[BOARD_PLAYER_FILTERS.SPORTS]: SPORT.Tennis,
	[BOARD_PLAYER_FILTERS.ATHLETE_TYPE]: '',
	[BOARD_PLAYER_FILTERS.ORDER_BY]: '',
	[BOARD_PLAYER_FILTERS.SORT_BY]: '',
	[BOARD_PLAYER_FILTERS.CURRENT_PAGE]: 1,
	[BOARD_PLAYER_FILTERS.RECORD_PER_PAGE]: 10,
	[BOARD_PLAYER_FILTERS.KPI]: '',
	[BOARD_PLAYER_FILTERS.ATHLETE_NAME]: ''
};

export type IRecruitingPlayerFilters = {
	[key in BOARD_PLAYER_FILTERS | QUICK_FILTER]: string | number | any[] | boolean;
};

export const initRecruitingFilter = {
	[RECRUITING_FILTERS.IS_PINNED]: false,
	[RECRUITING_FILTERS.BOARD_TYPE]: DASHBOARD_FILTER[0]?.value || '',
	[RECRUITING_FILTERS.BOARD_NAME]: '',
	[RECRUITING_FILTERS.ORDER_BY]: '',
	[RECRUITING_FILTERS.SORT_BY]: '',
	[RECRUITING_FILTERS.CURRENT_PAGE]: 1,
	[RECRUITING_FILTERS.RECORD_PER_PAGE]: 12,
	[RECRUITING_FILTERS.SPORTS]: 'Badminton'
};

export type IRecruitingFilters = {
	[key in RECRUITING_FILTERS]: string | number | boolean;
};

export interface IDashboard {
	id: string;
	name: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	department: string;
	department_group: string;
	sports: string;
	theme: string;
	color_code: string;
	is_pinned: boolean;
	is_active: boolean;
	recruiting_board_creator: IRecruitingBoardCreator;
	recruiting_board_editor: IRecruitingBoardEditor[];
	total_player_count: number;
	ops: number;
	label?: string;
}

export interface IRecruitingBoardCreator {
	id: string;
	name: string;
	last_name: string;
}

export interface IRecruitingBoardEditor {
	id: string;
	name: string;
	last_name: string;
	profile_picture: string;
}

export interface ISelectPlayer {
	id: string;
	profile_picture: any;
	first_name: string;
	last_name: string;
	gender: string;
	status: string;
	circuit_level: string;
	potential_grade?: string;
	country: ICountry;
	creator: ICreator;
	performance: IPerformance;
	sports: string;
	athlete_id: number;
	editors: any[];
	is_pinned: boolean;
	is_in_recruiting_board?: number;
	recruit_chance?: string;
	brand?: string;
	string_model?: string;
}

export interface ICountry {
	id: number;
	name: string;
	emoji: string;
	iso3: string;
}

export interface ICreator {
	id: string;
	name: string;
	last_name: string;
}

export interface IPerformance {
	current: string;
	previous: string;
	difference: string;
}

export interface IEditor {
	editor_id: string;
	editor_name: string;
	editor_last_name: string;
}

export enum BOARD_SHORTLIST_FILTERS {
	SHORTLIST_ID = 'shortlist_id',
	ORDER_BY = 'order_by',
	SHORT_BY = 'sort_by',
	ATHLETE_NAME = 'athlete_name'
}

export const initBoardShortListFilter = {
	[BOARD_SHORTLIST_FILTERS.SHORTLIST_ID]: '',
	[BOARD_SHORTLIST_FILTERS.ORDER_BY]: '',
	[BOARD_SHORTLIST_FILTERS.SHORT_BY]: '',
	[BOARD_SHORTLIST_FILTERS.ATHLETE_NAME]: ''
};

export interface IDashboardShortlist {
	id: string;
	title: string;
	theme: string;
	color_code: string;
	is_pinned: boolean;
	is_active: boolean;
	department: any;
	department_group?: string;
	sports?: string;
	created_at: string;
	updated_at: string;
	deleted_at: any;
	players: IShortlistPlayer[];
}

export interface IShortlistPlayer {
	id: string;
	profile_picture: string;
	first_name: string;
	last_name: string;
	sport: string;
	performance: IPerformance;
}
