import { FC, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import ButtonPrimary from 'shared/components/buttons/primary';
import ButtonSecondary from 'shared/components/buttons/secondary';
import DropdownSelect from 'shared/form/select/dropdownSelect';
import { useAthleteContext } from 'store/athleteStore';
import { CONTRACT_TYPE_OPTIONS, TENNIS_CONTRACT_TYPE_OPTIONS } from 'features/addPlayer/component/form/contractInfo';
import { CIRCUIT_LEVEL_OPTIONS, DISCIPLINE_OPTIONS, PLAYING_HAND } from 'features/athleteDatabase/constant';
import MultiSelectButtons from 'shared/components/filter/multiSelectbuttons';
import { CheckBox } from 'shared/form/checkbox';
import { IQuickFilters } from 'features/allAthlates/constant';
import { BackIcon } from 'shared/icon';
import { AuthContext } from 'store/userDetailStore';

interface IProps {
	isQuickFilterApplied: boolean;
	initQuickFilter?: IQuickFilters;
	onChangeQuickFilter: (filters: IQuickFilters) => void;
	closeQuickFilter: () => void;
}

const QuickFilters: FC<IProps> = ({ initQuickFilter, isQuickFilterApplied, onChangeQuickFilter, closeQuickFilter }) => {
	const { countryOptions, allAthleteFilters: filters } = useAthleteContext();
	const { handleSubmit, control, watch, setValue, reset, register } = useForm<any>({
		defaultValues: initQuickFilters
	});

	const { isTennis } = useContext(AuthContext);

	useEffect(() => {
		const filter = initQuickFilter ? initQuickFilter : filters;
		const formValue = {
			[QUICK_FILTER.COUNTRY]: filter.country_ids,
			[QUICK_FILTER.GENDER]: filter.genders,
			[QUICK_FILTER.CIRCUIT_LEVEL]: filter.circuit_levels,
			[QUICK_FILTER.PLAYING_HAND]: filter.playing_hands,
			[QUICK_FILTER.CATEGORY]: filter.category1,
			[QUICK_FILTER.CONTRACT]: filter.contract_types,
			[QUICK_FILTER.X_FACTOR]: filter.is_x_factor
		};
		reset(formValue);
	}, [initQuickFilter, filters]);

	const clearFilters = () => {
		reset(initQuickFilters);
		onChangeQuickFilter(initQuickFilters);
	};

	const onSubmit = (quickFilters: IQuickFilters) => {
		onChangeQuickFilter(quickFilters);
	};

	const CONTRACT_OPTIONS = useMemo(() => {
		return isTennis ? TENNIS_CONTRACT_TYPE_OPTIONS : CONTRACT_TYPE_OPTIONS;
	}, [isTennis]);

	return (
		<div className='h-[calc(100vh-140px)] w-[25%] border  ml-5 rounded-lg'>
			<div className='flex p-2 2xl:p-3 items-center justify-between border-b-2 border-primary bg-lightBlue rounded-t-lg'>
				<p className='text-textBlack font-semibold text-lg-responsive'>Quick Filter</p>
				<div
					className='flex items-center justify-center border-themeGray border bg-white size-10 rounded-md shadow-sm cursor-pointer'
					onClick={closeQuickFilter}
				>
					<BackIcon width='15px' className='rotate-180' />
				</div>
			</div>
			<div className='p-3 2xl:p-5'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DropdownSelect
						name='country_ids'
						title='Select Countries'
						options={countryOptions}
						control={control}
						placeHolder='Select Countries'
						className='mt-1 2xl:mt-3'
						isSearchable={true}
					/>
					<MultiSelectButtons
						title='Gender'
						name='genders'
						selectedArray={watch('genders')}
						setValue={setValue}
						option={GENDERS}
						className='mt-1 2xl:mt-3'
					/>

					{!isTennis && (
						<>
							<DropdownSelect
								name='circuit_levels'
								title='Circuit Level'
								options={CIRCUIT_LEVEL_OPTIONS}
								control={control}
								placeHolder='Select Level'
								className='mt-1 2xl:mt-3'
							/>
							<MultiSelectButtons
								title='Hand'
								name='playing_hands'
								selectedArray={watch('playing_hands')}
								setValue={setValue}
								option={PLAYING_HAND}
								className='mt-1 2xl:mt-3'
							/>
							<DropdownSelect
								name='category1'
								title='Category 1'
								options={DISCIPLINE_OPTIONS}
								control={control}
								placeHolder='Select Category 1'
								className='mt-1 2xl:mt-3'
							/>
						</>
					)}
					<DropdownSelect
						name='contract_types'
						title='Contract Type'
						options={CONTRACT_OPTIONS}
						control={control}
						placeHolder='Select Type'
						className='mt-1 2xl:mt-3'
					/>
					{!isTennis && (
						<div className='mt-4'>
							<CheckBox register={register} title='X-Factor' name='is_x_factor' />
						</div>
					)}
					<div className='flex justify-between gap-2 mt-6'>
						<ButtonSecondary
							className='w-full'
							onClick={clearFilters}
							btnTxt={'Reset Filter'}
							disabled={!isQuickFilterApplied}
						/>
						<ButtonPrimary
							className='w-full'
							disable={false}
							isLoading={false}
							onClick={handleSubmit(onSubmit)}
							btnTxt={'Apply'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export enum QUICK_FILTER {
	COUNTRY = 'country_ids',
	GENDER = 'genders',
	CIRCUIT_LEVEL = 'circuit_levels',
	PLAYING_HAND = 'playing_hands',
	CATEGORY = 'category1',
	CONTRACT = 'contract_types',
	X_FACTOR = 'is_x_factor'
}

export const initQuickFilters = {
	[QUICK_FILTER.COUNTRY]: [],
	[QUICK_FILTER.GENDER]: [],
	[QUICK_FILTER.CIRCUIT_LEVEL]: [],
	[QUICK_FILTER.PLAYING_HAND]: [],
	[QUICK_FILTER.CATEGORY]: [],
	[QUICK_FILTER.CONTRACT]: [],
	[QUICK_FILTER.X_FACTOR]: false
};

export const initTennisQuickFilters = {
	[QUICK_FILTER.CIRCUIT_LEVEL]: [],
	[QUICK_FILTER.PLAYING_HAND]: [],
	[QUICK_FILTER.CATEGORY]: [],
	[QUICK_FILTER.CONTRACT]: [],
	[QUICK_FILTER.X_FACTOR]: false
};

export const QuickFiltersList = [
	QUICK_FILTER.COUNTRY,
	QUICK_FILTER.GENDER,
	QUICK_FILTER.CIRCUIT_LEVEL,
	QUICK_FILTER.PLAYING_HAND,
	QUICK_FILTER.CATEGORY,
	QUICK_FILTER.CONTRACT,
	QUICK_FILTER.X_FACTOR
];

const GENDERS = [
	{ label: 'Female', value: 'Female' },
	{ label: 'Male', value: 'Male' }
];

export default QuickFilters;
