import CryptoJS from 'crypto-js';
import { SPORT } from 'shared/constants';
import { IUserData } from 'shared/interface';

const KEY: string = process.env['REACT_APP_ENCRYPTION_KEY'] as string;

/**
 * function to check if user is logged in or not
 */
const isLogin = () => {
	if (localStorage['authData_v0.2']) {
		return true;
	} else {
		return false;
	}
};

/**
 * function to get authentication data
 */
const getAuthData = () => {
	try {
		const data = localStorage['authData_v0.2'];
		if (data) {
			const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
			const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as IUserData;
			return decryptedData;
		} else {
			return {} as IUserData;
		}
	} catch (e) {
		return {} as IUserData;
	}
};

/**
 * function to set user authentication data
 */
const setAuthData = (data: IUserData): void => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	localStorage.setItem('authData_v0.2', cipherText.toString());
};

/**
 * function to remove user authentication data
 */
const removeAuthData = (key = 'authData_v0.2'): void => {
	localStorage.removeItem(key);
};

/**
 * function to get user access token
 */
const getAccessToken = (): string => {
	const data = getAuthData();
	if (data && data.token) {
		return data.token;
	} else {
		return '';
	}
};

/**
 * function to get user language
 */
export const getUserLanguage = (): string => {
	return 'en';
};

export const setTempToken = (token: string) => {
	localStorage.setItem('tempToken', token);
};

export const getTempToken = (): string => {
	return localStorage['tempToken'] || '';
};

export const setUserSport = (sport: SPORT) => {
	localStorage.setItem('userSport', sport);
};

export const getUserSport = (): SPORT => {
	return localStorage['userSport'] || SPORT.Badminton;
};

const AuthService = {
	isLogin,
	getAccessToken,
	setAuthData,
	getAuthData,
	removeAuthData,
	setTempToken,
	getTempToken,
	setUserSport,
	getUserSport
};

export default AuthService;
