import React, { useState } from 'react';
import { Progress } from 'flowbite-react';

import { DownArrowIcon } from 'shared/icon';
import { IKpiAvg, IKpiNum } from 'features/athleteDatabase/interface';
import StarRating from 'shared/rating/rating';
import { useAuthContext } from 'store/userDetailStore';

interface IProps {
	kpiAvgData: IKpiAvg;
}

const CompareKpiScore: React.FC<IProps> = (props) => {
	const { kpiAvgData } = props;

	const { isTennis } = useAuthContext();

	const [dropDownId, setDropDownId] = useState('');

	const getColor = (scoreOne: number, scoreTwo: number): string => {
		if (scoreOne > scoreTwo) {
			return 'green';
		} else if (scoreOne < scoreTwo) {
			return 'red';
		} else if (scoreOne === scoreTwo) {
			return 'blue';
		}
		return 'gray';
	};

	return (
		<>
			{Object.entries(kpiAvgData).map(([group, kpis]) => {
				return (
					<div
						className={`border-b border-themeGray p-3 ${dropDownId === group ? 'bg-lightBlue' : ''}`}
						key={group}
					>
						<div
							className='flex items-center justify-around cursor-pointer select-none'
							onClick={() => setDropDownId(dropDownId === group ? '' : group)}
						>
							<div className='text-textGray text-sm'>
								{`${group} Score`}: {kpis.player_one_avg_score} / {isTennis ? 5 : 20}
							</div>
							<div className='flex items-center justify-center'>
								<p className='text-textBlack font-semibold'>{group}</p>
								{dropDownId === group ? (
									<DownArrowIcon className='size-3 rotate-180 stroke-[#344054] ml-4 cursor-pointer' />
								) : (
									<DownArrowIcon className='size-3  stroke-[#344054] ml-4 cursor-pointer' />
								)}
							</div>
							<div className='text-textGray text-sm'>
								{' '}
								{`${group} Score`}: {kpis.player_two_avg_score} / {isTennis ? 5 : 20}
							</div>
						</div>
						{dropDownId === group && (
							<>
								{kpis.kpiData.length > 0 &&
									kpis.kpiData.map((details: IKpiNum) => {
										return (
											<div
												className='w-full max-w-[850px] mt-3 mx-auto bg-white'
												key={details.kpi_name}
											>
												<div
													className={`${
														isTennis ? 'justify-between' : 'justify-around'
													} flex gap-8 items-center p-2 border-themeGray border rounded-lg`}
												>
													<div className={`${isTennis ? '' : 'w-[40%]'}`}>
														{!isTennis && (
															<p className='text-textBlack font-semibold mb-3'>
																{details.player_one_value} / 20
															</p>
														)}
														{!isTennis && (
															<Progress
																progress={details.player_one_fill}
																color={getColor(
																	details.player_one_value,
																	details.player_two_value
																)}
																theme={progressTheme}
																className='w-full'
															/>
														)}
														{isTennis && (
															<StarRating
																className='mt-3 2xl:mt-1'
																initialRating={details.player_one_value || 0}
																isDisabled
																fractions={2}
																size='30'
															/>
														)}
													</div>
													<div className='text-textGray text-sm w-1/5 text-center capitalize'>
														{details.kpi_name}
													</div>
													<div className={`${isTennis ? '' : 'w-[40%]'}`}>
														{!isTennis && (
															<p className='text-textBlack font-semibold mb-3 text-end'>
																{details.player_two_value} / 20
															</p>
														)}
														{!isTennis && (
															<Progress
																progress={details.player_two_fill}
																color={getColor(
																	details.player_two_value,
																	details.player_one_value
																)}
																theme={progressTheme}
																className='w-full'
															/>
														)}
														{isTennis && (
															<StarRating
																className='mt-3 2xl:mt-1'
																initialRating={details.player_two_value || 0}
																isDisabled
																fractions={2}
																size='30'
															/>
														)}
													</div>
												</div>
											</div>
										);
									})}
							</>
						)}
					</div>
				);
			})}
		</>
	);
};
export default CompareKpiScore;

const progressTheme = {
	color: {
		green: 'bg-[#34C759]',
		red: 'bg-[#FF3B30]',
		blue: 'bg-primary'
	}
};
