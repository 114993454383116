import React, { useState } from 'react';
import { Spinner } from 'flowbite-react';
import { excelModal, ISport, ISportSelectionProps } from 'features/addPlayer/interface';
import { BUCKET_NAME, FILE_NAME, TENNIS_FILE_NAME } from 'shared/constants';
import { downloadFile } from 'shared/util/utility';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { BadmintonSVGIcon, ExportIcon, ImportIcon, TennisSVGIcon } from 'shared/icon';
import { useAuthContext } from 'store/userDetailStore';
import useModal from 'shared/hooks/useModal';
import TooltipContainer from '../tooltip';
import InformationModal from '../modal/informationModal';

interface IProps {}

const ImportExportButton: React.FC<IProps> = () => {
	const [fileLoader, setFileLoader] = useState(false);

	const { isOpen, type, closeModal, openModal } = useModal<excelModal>();

	const { userData, isTennis } = useAuthContext();

	const [selectedSport, setSelectedSport] = useState('');

	const handleSelectedSport = (sport: string) => {
		setSelectedSport(sport);
	};

	const SportSelection = ({ sports, onSelect }: ISportSelectionProps) => {
		return (
			<div className='flex justify-center items-center gap-5'>
				{sports.map((sport: ISport) => {
					const isSelected = sport.name === selectedSport;
					const fillColor = isSelected ? 'white' : 'black';
					return (
						<div
							key={sport.name}
							className={`${
								isSelected ? 'bg-primary' : ''
							} flex justify-center flex-col items-center border-2 rounded-md p-1 w-24 cursor-pointer`}
							onClick={() => {
								onSelect(sport.name);
							}}
						>
							{<sport.icon fill={fillColor} />}
							<p className={`${isSelected ? 'text-white' : 'text-black'}`}>{sport.name}</p>
						</div>
					);
				})}
			</div>
		);
	};

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0];
		setFileLoader(true);

		if (selectedFile) {
			try {
				const formData = new FormData();
				formData.append('file', selectedFile);
				const contentType = 'multipart/form-data';
				await HttpService.post(
					`${selectedSport === 'Tennis' ? API_CONFIG.tennisPlayer : API_CONFIG.player}/import-excel`,
					formData,
					{ contentType }
				);
				setFileLoader(false);
				// Reset the input value to allow uploading the same file again
				event.target.value = '';
				closeModal();
			} catch (error) {
				setFileLoader(false);
				closeModal();
				// Reset the input value to allow uploading the same file again
				event.target.value = '';
				console.error('Error uploading file:', error);
			}
		}
	};

	const handleSportAction = (sport: string) => {
		if (type === excelModal.IMPORT_POPUP) {
			handleSelectedSport(sport);
		} else if (type === excelModal.EXPORT_POPUP) {
			const API = `https://${BUCKET_NAME}.s3.eu-central-1.amazonaws.com/player-videos/${
				sport === 'Tennis' ? TENNIS_FILE_NAME : FILE_NAME
			}-player-data.xlsx`;
			downloadFile(API, 'players_export.xlsx');
			closeModal();
		}
	};

	return (
		<div>
			<div className='flex justify-center items-center gap-3'>
				<p className='text-sm font-semibold text-primary'>Excel</p>
				<TooltipContainer
					placement='top'
					textClassName=' w-[120px]'
					content={'Upload multiple athletes at once'}
				>
					<div
						className='h-10 rounded-md border border-primary px-2 py-2 cursor-pointer'
						onClick={userData.sports === 'Both' ? () => openModal(excelModal.IMPORT_POPUP) : undefined}
					>
						<label>
							<div>
								{!fileLoader && <ImportIcon className='cursor-pointer' />}
								{fileLoader && (
									<div className='flex justify-center items-center'>
										<Spinner aria-label='Center-aligned spinner' theme={Theme} size={'sm'} />
									</div>
								)}
								{userData.sports !== 'Both' && (
									<input
										type='file'
										accept='.xlsx, .xls'
										onChange={handleFileChange}
										style={{ display: 'none' }}
										key={'import'}
									/>
								)}
							</div>
						</label>
					</div>
				</TooltipContainer>
				<TooltipContainer placement='top' content={'Download sample file'}>
					<button
						className='h-10 rounded-md border border-primary px-2 py-2 cursor-pointer'
						onClick={() => {
							if (userData.sports === 'Both') {
								openModal(excelModal.EXPORT_POPUP);
							} else {
								const API = `https://${BUCKET_NAME}.s3.eu-central-1.amazonaws.com/player-videos/${
									isTennis ? TENNIS_FILE_NAME : FILE_NAME
								}-player-data.xlsx`;
								downloadFile(API, 'players_export.xlsx');
							}
						}}
					>
						<ExportIcon />
					</button>
				</TooltipContainer>
			</div>

			{isOpen && (
				<InformationModal headerTile={'Select a Sport'} size={'md'} show={isOpen} onClose={closeModal}>
					<div className='flex justify-center items-center gap-5'>
						{type === excelModal.IMPORT_POPUP && (
							<label className='flex gap-5'>
								<input
									type='file'
									accept='.xlsx, .xls'
									onChange={handleFileChange}
									style={{ display: 'none' }}
									key={'import'}
								/>
								<SportSelection sports={sports} onSelect={(sport: any) => handleSportAction(sport)} />
							</label>
						)}
						{type === excelModal.EXPORT_POPUP && (
							<SportSelection sports={sports} onSelect={(sport: any) => handleSportAction(sport)} />
						)}
					</div>
				</InformationModal>
			)}
		</div>
	);
};

export default ImportExportButton;

const Theme = {
	color: {
		info: 'fill-primary'
	}
};

const sports = [
	{ name: 'Badminton', icon: BadmintonSVGIcon },
	{ name: 'Tennis', icon: TennisSVGIcon }
];
