import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import _ from 'lodash';

import { IAction, IState } from 'shared/interface/state';
import { AnyObject } from 'shared/interface';
import { KpiGroup, TENNIS_PLAYER_KPI } from 'features/athleteDatabase/constant';
import HttpService from 'shared/services/http.service';
import { notify } from 'shared/components/notification/notification';
import { IScore } from 'features/playerProfile/interface';

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
export const createAction = (ACTION: string, data: any = null): IAction => ({
	type: ACTION,
	payload: data
});

/**
 * create loading selector
 * @param actions - actions to dispatch
 */
export const createLoadingSelector = (actions: string[]) => (state: IState) =>
	// returns true only when all actions is not loading
	_(actions).some((action: string) => _.get(state, `loading.api.${action}`));

/**
 * dispatch action after given time (to handle some events like close modal after success api call)
 * @param dispatch - dispatch object
 * @param action - action type
 * @param time - time after which action is to be dispatched (default - 100ms)
 */
export const dispatchActionAfterTime = (
	dispatch: ThunkDispatch<unknown, unknown, IAction>,
	action: string,
	time = 100
) => {
	setTimeout(() => {
		dispatch(createAction(action));
	}, time);
};

export const debounce = (func: any, wait = 1000) => {
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

export const formatDate = (date: string | null, format = 'DD.MM.YYYY') => {
	if (date) return moment(date).format(format);
	return '-';
};

export const contractPeriodDates = (startDate: string | null, endDate: string | null, format = 'DD.MM.YYYY') => {
	if (startDate && endDate) {
		const formattedStartDate = moment(startDate).format(format);
		const formattedEndDate = moment(endDate).format(format);
		return `${formattedStartDate} - ${formattedEndDate}`;
	}
	return '-';
};

export const noteFormatDate = (date: string | null, format = 'll') => {
	if (date) return moment(date).format(format);
	return '--';
};

export const getVideoName = (url: string) => {
	const separatedLink = url.split('/');
	const videoNameWithTimestamp: any = separatedLink[separatedLink.length - 1];
	return videoNameWithTimestamp.slice(videoNameWithTimestamp.indexOf('-') + 1);
};

export const DateFormate = (date: string) => {
	if (date) return moment(date).format();
	return '--';
};

export const VideoSize = (size?: number) => {
	if (!size) {
		return 'File size not available';
	}
	return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};

export const formatKey = (name: string) => {
	return name
		.replace(/\s+/g, '')
		.replace(/[^a-zA-Z0-9]/g, '')
		.toLowerCase();
};

export const cleanObject = (obj: AnyObject): AnyObject | any[] => {
	for (const key in obj) {
		if (obj[key] === '' || obj[key] === '+81') {
			delete obj[key];
		} else if (_.isPlainObject(obj[key])) {
			cleanObject(obj[key]);
		} else if (Array.isArray(obj[key])) {
			// it is guaranteed that if obj[key] is an array, it will be an array of objects
			for (const elem of obj[key]) {
				cleanObject(elem);
			}
			obj[key] = obj[key].filter((val: any) => Object.keys(val).length);
		}
	}
	return obj;
};

export const cleanObjectWithZero = (obj: any): any => {
	for (const key in obj) {
		// Check if value is 0, '', null, undefined, or an empty array/object
		if (
			obj[key] === '' ||
			//obj[key] === 0 ||
			obj[key] === undefined ||
			(Array.isArray(obj[key]) && obj[key].length === 0) ||
			(_.isPlainObject(obj[key]) && Object.keys(obj[key]).length === 0)
		) {
			delete obj[key];
		} else if (_.isPlainObject(obj[key])) {
			// Recursively clean nested objects
			cleanObjectWithZero(obj[key]);
			// Remove if the object is empty after cleaning
			if (Object.keys(obj[key]).length === 0) {
				delete obj[key];
			}
		} else if (Array.isArray(obj[key])) {
			// Recursively clean arrays
			obj[key] = obj[key]
				.map((item: any) => cleanObjectWithZero(item))
				.filter((item: any) => Object.keys(item).length > 0);
			// Remove array if it becomes empty
			if (obj[key].length === 0) {
				delete obj[key];
			}
		}
	}
	return obj;
};

export const cleanObjectWithArray = (obj: AnyObject): AnyObject | any[] => {
	for (const key in obj) {
		if (obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length <= 0)) {
			delete obj[key];
		} else if (_.isPlainObject(obj[key])) {
			cleanObjectWithArray(obj[key]);
		} else if (Array.isArray(obj[key])) {
			if (_.isPlainObject(obj[key][0])) {
				for (const elem of obj[key]) {
					cleanObjectWithArray(elem);
				}
				obj[key] = obj[key].filter((val: any) => Object.keys(val).length);
			} else {
				obj[key] = obj[key].filter((val: any) => val !== '' && val != null);
			}
		}
	}
	return obj;
};

export const calculateAveragePerGroup = (data: any[]) => {
	const groupMap: any = {};

	// Initialize groupMap with predefined KPI groups
	KpiGroup.forEach((group) => {
		groupMap[group] = { total: 0, count: 0 };
	});

	// Aggregate the total and count for each group
	data.forEach((item) => {
		const group = item.player_kpi_group;
		if (groupMap[group]) {
			// Only update if the group exists in KpiGroup
			groupMap[group].total += item.value;
			groupMap[group].count += 1;
		}
	});

	// Calculate average for each group and return the result
	return KpiGroup.map((group) => ({
		title: group,
		score: groupMap[group].count > 0 ? Math.round(groupMap[group].total / groupMap[group].count) : 0
	}));
};

export const calculateTennisAvg = (input: any[]) => {
	// Initialize the output with all KPI groups set to score 0
	const initialOutput = Object.values(TENNIS_PLAYER_KPI).map((title) => ({
		title,
		score: 0
	}));

	// Aggregate the scores by grouping them into KPI categories
	input.forEach((item) => {
		const groupIndex = initialOutput.findIndex((kpi) => kpi.title === item.player_kpi_name);
		if (groupIndex !== -1) {
			initialOutput[groupIndex]!.score += item.value;
		}
	});

	return initialOutput;
};

export const downloadExcel = async (url: string, fileType: string, fileName: string) => {
	try {
		const response = await HttpService.get(url, {}, { responseType: 'blob' });

		const file = new Blob([response.data || response], { type: fileType }); // Use 'response' if 'response.data' is undefined
		const blobUrl = window.URL.createObjectURL(file);
		const link = document.createElement('a');
		link.href = blobUrl;
		link.download = fileName; // Set the file name for download
		link.click();
		window.URL.revokeObjectURL(blobUrl); // Clean up after the download
		notify('Excel Export Successfully', 'success');
	} catch (err) {
		console.error('Error downloading file:', err);
	}
};

export const downloadFile = (url: string, fileName: string) => {
	try {
		const link = document.createElement('a');
		link.href = url;
		link.download = fileName; // Set the file name for download
		link.click();
		notify('Excel Download Successfully', 'success');
	} catch (err) {
		console.error('Error downloading file:', err);
	}
};

// Function to convert hex to rgba with specified opacity
export const hexToRgba = (hex: string, opacity: any) => {
	// Remove the '#' character
	hex = hex.replace('#', '');
	// Parse the red, green, and blue values
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	// Return the RGBA string
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const formatPostDate = (timestamp: number) => {
	const date = moment(timestamp);
	const currentDate = moment();
	const daysDifference = currentDate.diff(date, 'days');
	if (daysDifference <= 60) {
		return moment.unix(timestamp / 1000).fromNow();
	} else {
		return date.format('DD.MM.YYYY');
	}
};

export const getButtonClass = (value: string, isPinned: boolean) => {
	const activeConditions: { [key: string]: boolean } = {
		view_all: !isPinned,
		only_pinned: isPinned
	};

	return activeConditions[value] ? 'bg-lightBlue1' : 'text-gray-400';
};

export const calculateAge = (dob: string | number | Date) => {
	if (!dob) return '-';

	const today = new Date();
	const birthDate = new Date(dob);

	// Check if DOB is in the future
	if (birthDate > today) return '-';

	let age = today.getFullYear() - birthDate.getFullYear();

	// Adjust the age if today's date is before the birthday this year
	const isBeforeBirthdayThisYear =
		today.getMonth() < birthDate.getMonth() ||
		(today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

	if (isBeforeBirthdayThisYear) {
		age--;
	}

	return age;
};

export const calculateAverage = (scores: IScore[]) => {
	const filteredScores = scores.filter((item) => item.score > 0); // Exclude scores with 0
	const total = filteredScores.reduce((sum, item) => sum + item.score, 0);
	return filteredScores.length > 0 ? (total / filteredScores.length).toFixed(2) : '0';
};
