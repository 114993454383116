import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import { isEmpty } from 'lodash';

import ProfilePic from 'shared/components/profilePic';
import TableComponent from 'shared/components/table/table';
import TableBody from 'shared/components/table/tableBody';
import TableCell from 'shared/components/table/tableCell';
import TableHeader from 'shared/components/table/tableHeader';
import TableRow from 'shared/components/table/tableRow';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import TableSpinner from 'shared/components/table/tableSpinner';
import { IKpi, IPerformanceMatrix } from '../interface';
import EmptyData from 'shared/components/table/emptyData';
import { PLAYER_KPI, TENNIS_PLAYER_KPI } from '../constant';
import Pagination from 'shared/components/pagination';
import { AuthContext } from 'store/userDetailStore';

interface IProps {
	className?: string;
	show: boolean;
	onClose: () => void;
}
interface IPagination {
	current_page: number;
	record_per_page: number;
	total_pages: number;
}
const PlayerMatrix: React.FC<IProps> = (props) => {
	const { className, show, onClose } = props;
	const { isTennis } = useContext(AuthContext);

	const tableCellList = !isTennis ? PLAYER_TABLE_CELL : TENNIS_PLAYER_TABLE_CELL;

	const [matrixData, setMatrixData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState<IPagination>({ current_page: 1, record_per_page: 10, total_pages: 1 });
	const [totalCount, setTotalCount] = useState<number>();

	const getCell = (key: string, record: IPerformanceMatrix) => {
		const findKpiValue = (group: string) => {
			const kpi = record.kpis.find((kpi: IKpi) => kpi.kpi_group === group);
			return kpi ? kpi.avg : '-';
		};

		const tennisFindKpiValue = (kpi_name: string) => {
			const kpi = record.kpis.find((kpi: IKpi) => kpi.kpi_name === kpi_name);
			return kpi ? kpi.avg : '-';
		};

		switch (key) {
			case 'athleteName':
				return (
					<div className='flex items-center ml-2'>
						<ProfilePic
							profileUrl={record.profile_picture}
							playerName={`${record.first_name} ${record.last_name}`}
						/>
					</div>
				);
			case 'technical':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.TECHNICAL)}</p>;
			case 'footwork':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.FOOTWORK)}</p>;
			case 'tactical':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.TACTICAL)}</p>;
			case 'psychological':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.PSYCHOLOGICAL)}</p>;
			case 'physical':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.PHYSICAL)}</p>;
			case 'dailyLifecycle':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.DAILY_PRACTICE_OR_LIFESTYLE)}</p>;
			case 'perform':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.PERFORMANCE_ENVIRONMENT)}</p>;
			case 'tennisTechnical':
				return <p className='text-center'>{tennisFindKpiValue(TENNIS_PLAYER_KPI.TECHNICAL)}</p>;
			case 'tennisPhysical':
				return <p className='text-center'>{tennisFindKpiValue(TENNIS_PLAYER_KPI.PHYSICAL)}</p>;
			case 'tennisTectical':
				return <p className='text-center'>{tennisFindKpiValue(TENNIS_PLAYER_KPI.TACTICAL)}</p>;
			case 'mental':
				return <p className='text-center'>{tennisFindKpiValue(TENNIS_PLAYER_KPI.MENTAL)}</p>;

			case 'ops':
				return (
					<p
						className={`text-center ${
							record.is_max_ops
								? 'text-green-400 font-semibold'
								: record.is_min_ops
								? 'text-red-400 font-semibold'
								: ''
						}`}
					>
						{record.OPS || '-'}
					</p>
				);
			default:
				return <p>-</p>;
		}
	};

	const getPerformanceMatrix = async (params: IPagination) => {
		setLoading(true);
		try {
			const res = await HttpService.get(
				`${isTennis ? API_CONFIG.tennisPlayer : API_CONFIG.player}/performance-matrix`,
				params
			);
			setPagination({ ...pagination, total_pages: res.data.total_pages, current_page: res.data.current_page });
			setTotalCount(res.data.total);
			setLoading(false);
			setMatrixData(res.data.data);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const onPageChange = (page: number) => {
		getPerformanceMatrix({ ...pagination, current_page: page });
	};

	useEffect(() => {
		getPerformanceMatrix(pagination);
	}, []);

	return (
		<Modal className={`${className || ''}`} show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
				<div className='flex items-center gap-2'>
					<p className='header-txt'>Athlete Performance Matrix</p>
					{totalCount && totalCount > 0 ? (
						<p className='bg-[#1d953317] p-2 rounded-lg h-8 text-[#1E9634] text-xs'>
							{totalCount} {`${totalCount === 1 ? 'Athlete' : 'Athletes'}`}
						</p>
					) : (
						<></>
					)}
				</div>
			</Modal.Header>
			<Modal.Body>
				<TableComponent className='rounded-xl border'>
					<TableHeader
						tableCellList={tableCellList}
						className='sticky top-0 z-[1] table w-full table-fixed bg-white first:text-left'
					/>
					<TableBody className='custom-scrollbar block h-[calc(100vh-750px)] min-h-[57vh] overflow-y-auto border-none'>
						{loading && (
							<div className='flex justify-center items-center h-full'>
								<TableSpinner colSpan={tableCellList.length} />
							</div>
						)}
						{!loading && !isEmpty(matrixData) && matrixData.length > 0 && (
							<>
								{matrixData.map((data: IPerformanceMatrix, index: number) => {
									return (
										<Fragment key={index}>
											<TableRow className='table w-full table-fixed tr-left-border'>
												{tableCellList.map((cell) => (
													<TableCell key={cell.title} className='border-b' style={cell.style}>
														{getCell(cell.key, data)}
													</TableCell>
												))}
											</TableRow>
										</Fragment>
									);
								})}
							</>
						)}
						{!loading && isEmpty(matrixData) && matrixData.length <= 0 && <EmptyData className='h-full' />}
					</TableBody>
				</TableComponent>
				<Pagination loading={loading} onPageChange={onPageChange} pagination={pagination} />
			</Modal.Body>
		</Modal>
	);
};
export default PlayerMatrix;

const PLAYER_TABLE_CELL = [
	{ title: 'Athlete name', key: 'athleteName', style: { width: '150px' } },
	{ title: 'Technical', key: 'technical', style: { width: '100px' } },
	{ title: 'Footwork', key: 'footwork', style: { width: '100px' } },
	{ title: 'Tactical', key: 'tactical', style: { width: '100px' } },
	{ title: 'Psychological', key: 'psychological', style: { width: '100px' } },
	{ title: 'Physical', key: 'physical', style: { width: '100px' } },
	{ title: 'Daily / Lifecycle', key: 'dailyLifecycle', style: { width: '100px' } },
	{ title: 'Perform. envi.', key: 'perform', style: { width: '100px' } },
	{ title: 'OPS', key: 'ops', style: { width: '100px' } }
];

const TENNIS_PLAYER_TABLE_CELL = [
	{ title: 'Athlete name', key: 'athleteName', style: { width: '150px' } },
	{ title: 'Technical', key: 'tennisTechnical', style: { width: '100px' } },
	{ title: 'Physical', key: 'tennisPhysical', style: { width: '100px' } },
	{ title: 'Tectical', key: 'tennisTectical', style: { width: '100px' } },
	{ title: 'Mental', key: 'mental', style: { width: '100px' } },
	{ title: 'OPS', key: 'ops', style: { width: '100px' } }
];

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[1320px]'
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};
