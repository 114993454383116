import { searchBarTheme } from 'features/athleteDatabase/constant';
import { TextInput } from 'flowbite-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { CloseIcon, SearchIcon } from 'shared/icon';
import { debounce } from 'shared/util/utility';

interface IProps {
	onChangeFilter: (key: string, value: string) => void;
	FilterKey: string;
	sizing?: string;
	searchValue?: string;
	placeholder?: string;
}

const SearchInput: React.FC<IProps> = ({ onChangeFilter, FilterKey, sizing = 'md', searchValue, placeholder }) => {
	const [inputValue, setInputValue] = useState(searchValue);

	const debounceSearch = useCallback(
		debounce((text: string) => onChangeFilter(FilterKey, text), 500),
		[onChangeFilter, FilterKey]
	);

	const handleClearInput = () => {
		setInputValue('');
		onChangeFilter(FilterKey, '');
	};

	const onInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value.trim(); // Trim whitespace here
			setInputValue(value);
			debounceSearch(value);
		},
		[debounceSearch]
	);
	return (
		<div className='flex relative'>
			<TextInput
				theme={searchBarTheme}
				value={inputValue}
				onChange={onInputChange}
				sizing={sizing}
				type='text'
				icon={() => <SearchIcon />}
				placeholder={placeholder || `Search Athlete`}
			/>
			{inputValue && (
				<CloseIcon
					className='absolute right-3 top-[12px] 2xl:top-[15px] cursor-pointer'
					onClick={handleClearInput}
				/>
			)}
		</div>
	);
};

export default SearchInput;
