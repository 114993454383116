import React, { PropsWithChildren } from 'react';

import { Tooltip } from 'flowbite-react';

interface IProps extends PropsWithChildren {
	content: string;
	textClassName?: string;
	placement?: any;
	arrow?: boolean;
	style?: any;
	color?: any;
}

const TooltipContainer: React.FC<IProps> = (props) => {
	return (
		<Tooltip
			arrow={props.arrow as boolean}
			placement={props.placement || 'top'}
			content={<p className={`text-center ${props.textClassName}`}>{props.content}</p>}
			theme={{
				target: 'w-full',
				style: {
					auto: 'bg-white border border-primary'
				},
				arrow: {
					style: {
						auto: 'bg-white border-primary border-l border-b'
					}
				}
			}}
			color={props.color}
			style={props.style}
		>
			{props.children}
		</Tooltip>
	);
};

export default TooltipContainer;
