import { ButtonTheme } from 'shared/components/buttons/primary';

export interface IFormValues {
	name: string;
	editor_ids: string[];
	theme: string;
	color_code: string;
	sports: string;
}

export const ThemesOptions = [
	{
		themeTitle: 'Theme Navbar',
		themeName: 'Navbar Colorful',
		className: 'border border-primary bg-white relative',
		iconClassName: 'stroke-primary top-[50%]'
	},
	{
		themeTitle: 'Theme Full',
		themeName: 'Full Background Colorful',
		className: 'bg-primary',
		iconClassName: 'stroke-white top-[40%]'
	}
];

export const ColorOptions = [
	{ label: '#0057B7', value: '#0057B7' },
	{ label: '#1D9533', value: '#1D9533' },
	{ label: '#ED3833', value: '#ED3833' },
	{ label: '#F3B743', value: '#F3B743' },
	{ label: '#655091', value: '#655091' },
	{ label: '#A44062', value: '#A44062' },
	{ label: '#EE79C3', value: '#EE79C3' },
	{ label: '#337E7E', value: '#337E7E' },
	{ label: '#7F7F7F', value: '#7F7F7F' },
	{ label: '#3A4B90', value: '#3A4B90' }
];

export const defaultValues: IFormValues = {
	name: '',
	editor_ids: [],
	theme: ThemesOptions[0]?.themeName || '',
	color_code: ColorOptions[0]?.label || '',
	sports: ''
};

export interface IShortlistDetail {
	name: string;
	theme: string;
	color_code: string;
	editor_ids?: string[];
	sports: string;
	id: string;
}

export enum ScreenType {
	SELECTION_ATHLETE = 'Athlete Selection',
	SHORTLIST = 'Shortlist',
	SHORTLIST_DETAILS = 'Shortlist Details',
	RECRUITING_DASHBOARD = 'RECRUITING_DASHBOARD'
}

export enum ScoutingModal {
	CREATE_SCOUT = 'Create',
	EDIT_SCOUT = 'Edit',
	VIEW_CREATED_SCOUT = 'View created scout',
	DELETE_SCOUT = 'Delete',
	VIEW_EDITOR = 'View Editor'
}

export const buttonTheme = {
	size: {
		...ButtonTheme.size
	}
};

export const Select_Athlete_Table_Cells = [
	{ title: 'Athlete name', key: 'playerName', style: { width: '250px' } },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true, style: { width: '50px' } },
	{ title: 'Gender', key: 'gender', style: { width: '50px' } },
	{ title: 'Circuit Lvl.', key: 'circuitLvl', style: { width: '190px' } },
	{ title: 'Potential Grade', key: 'potential_grade', style: { width: '100px' } },
	{
		title: 'OPS',
		key: 'perform',
		shortKey: 'Performance',
		sortable: true,
		style: { width: '150px' },
		tooltip: true,
		tooltipText: 'Overall Performance Score'
	},
	{ title: 'Status', key: 'status', style: { width: '150px' } },
	{ title: 'Profile Creator', key: 'profile_creator', style: { width: '150px' } },
	{ title: 'Editor', key: 'editors', style: { width: '190px' } },
	{ title: '', key: 'action', style: { width: '50px' } }
];

export const Tennis_Select_Athlete_Table_Cells = [
	{ title: 'Athlete name', key: 'playerName', style: { width: '250px' } },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true, style: { width: '50px' } },
	{ title: 'Gender', key: 'gender', style: { width: '50px' } },
	{
		title: 'OPS',
		key: 'perform',
		shortKey: 'Performance',
		sortable: true,
		style: { width: '150px' },
		tooltip: true,
		tooltipText: 'Overall Performance Score'
	},
	{ title: 'Recruit Chance', key: 'recruit_chance', style: { width: '100px' } },
	{ title: 'Brand', key: 'brand', style: { width: '100px' } },
	{ title: 'String Model', key: 'string_model', style: { width: '100px' } },
	{ title: 'Editor', key: 'editors', style: { width: '190px' } },
	{ title: '', key: 'action', style: { width: '50px' } }
];

export const ShortList_Table_Cells = [
	{ title: 'Shortlist name', key: 'shortlist_name', style: { width: '150px' } },
	{ title: 'Average OPS', key: 'avg_ops', shortKey: 'Performance', sortable: true, style: { width: '100px' } },
	{ title: 'No. of Athletes ', key: 'no_of_athletes', style: { width: '150px' } },
	{ title: 'Created by', key: 'created_by', style: { width: '150px' } },
	{ title: 'Creation date', key: 'creation_date', style: { width: '150px' } },
	{
		title: 'Editor',
		key: 'editor',
		style: { width: '150px' }
	},
	{ title: '', key: 'action', style: { width: '100px' } }
];

export const SHORTLISTfILTER = [
	{ label: 'All Shortlists', value: 'All Shortlist' },
	{ label: 'My Shortlists', value: 'My Shortlist' },
	{ label: 'Yonex Shortlists', value: 'Yonex Shortlist' }
];

export const ThemeClassNameMapper: { [key: string]: string } = {
	'Navbar Colorful': 'with-border',
	'Full Background Colorful': 'with-bg'
};

export const Theme_Colors: { [key: string]: string } = {
	'#0057B7': 'color-palate-1',
	'#1D9533': 'color-palate-2',
	'#ED3833': 'color-palate-3',
	'#F3B743': 'color-palate-4',
	'#655091': 'color-palate-5',
	'#A44062': 'color-palate-6',
	'#EE79C3': 'color-palate-7',
	'#337E7E': 'color-palate-8',
	'#7F7F7F': 'color-palate-9',
	'#3A4B90': 'color-palate-10'
};

export enum THEME {
	NAVBAR_COLOR_FULL = 'Navbar Colorful'
}
