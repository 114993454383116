import { ColorOptions } from 'features/shortlist/constant';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import useFlowbite from 'shared/hooks/useFlowbite';
import { CheckIcon, CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';

interface IProps {
	name: string;
	title: string;
	className?: string;
	errorMsg?: string;
	control: any;
	disabled?: boolean;
	required?: boolean;
	isLabel?: boolean;
}

const SelectColor: FC<IProps> = ({ name, title, className, control, errorMsg, disabled, required, isLabel = true }) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const dropdownRef = useRef<HTMLButtonElement | null>(null);

	// Handle outside click
	const handleClickOutside = (event: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		// Add event listener to handle outside clicks
		document.addEventListener('click', handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const closeDropdown = () => {
		setDropdownVisible(false);
	};

	return (
		<div className={`${className || ''} w-full`}>
			{isLabel && (
				<label htmlFor={name} className='input-label'>
					{title}
					{required && (
						<span className='text-xs'>
							<StarIcon height='7px' width='7px' />
						</span>
					)}
				</label>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<>
						<button
							ref={dropdownRef}
							disabled={disabled}
							id='dropdownButton'
							className={`input-select py-1 2xl:py-2 ring-none focus:outline-none focus:ring-4 focus:ring-blue-300 relative ${
								!value && 'text-themeGray-text'
							}`}
							type='button'
							color='white'
							onClick={() => {
								setDropdownVisible(!dropdownVisible);
							}}
						>
							{value ? (
								<div className={`size-6 rounded-md`} style={{ backgroundColor: `${value}` }} />
							) : (
								'Select Color'
							)}

							{value ? (
								<button
									type='button'
									className='h-3 w-4 flex justify-end'
									onClick={(e) => {
										onChange(null);
										e.stopPropagation();
									}}
								>
									<CloseIcon className='size-2.5 stroke-[#344054] cursor-pointer' />
								</button>
							) : (
								<DownArrowIcon className='size-2.5 stroke-[#344054]' />
							)}
						</button>

						<div
							id='dropdown'
							className={`absolute cursor-pointer left-5 z-10 rounded-lg bg-white shadow dark:bg-gray-700 ${
								dropdownVisible ? 'block' : 'hidden'
							}`}
						>
							{/* {dropdownVisible && ( */}
							<div className='flex w-[340px] flex-wrap p-2'>
								{ColorOptions.map((color: any) => (
									<button
										key={color.value}
										className={`size-12 m-2 flex items-center justify-center border rounded-xl cursor-pointer ${
											color.value !== value ? 'opacity-50' : ''
										}`}
										type='button'
										style={{ backgroundColor: `${color.label}` }}
										onClick={(e) => {
											onChange(color.value);
											closeDropdown();
											e.stopPropagation();
										}}
									>
										{value === color.label && <CheckIcon className='size-6 stroke-white' />}
									</button>
								))}
							</div>
							{/* )} */}
						</div>
					</>
				)}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default SelectColor;
