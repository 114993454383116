import React, { Fragment, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Modal } from 'flowbite-react';

import TableComponent from 'shared/components/table/table';
import TableHeader from 'shared/components/table/tableHeader';
import TableRow from 'shared/components/table/tableRow';
import TableCell from 'shared/components/table/tableCell';
import TableBody from 'shared/components/table/tableBody';
import { IDashboard, initRecruitingFilter, RECRUITING_FILTERS } from 'features/recruitingDashboard/interface';
import { cleanObject, formatDate } from 'shared/util/utility';
import { API_CONFIG } from 'shared/constants/api';
import HttpService from 'shared/services/http.service';
import TableSpinner from 'shared/components/table/tableSpinner';
import Pagination from 'shared/components/pagination';
import BoardEditor from '../boardEditor';
import EmptyTable from 'shared/components/table/emptyTable';
import { ISortData } from 'shared/interface';

interface IProps {
	show: boolean;
	onClose: () => void;
	sport: string;
}

const ViewDashboardModal: React.FC<IProps> = (props) => {
	const { show, onClose, sport } = props;

	const [dashboard, setDashboard] = useState<IDashboard[]>([]);
	const [filters, setFilters] = useState(initRecruitingFilter);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({ current_page: 1, record_per_page: 10, total_pages: 1 });

	useEffect(() => {
		fetchDashboard({ ...filters });
	}, [filters]);

	const fetchDashboard = async (filters: any) => {
		const params = { ...cleanObject(filters), [RECRUITING_FILTERS.BOARD_TYPE]: '', sports: sport };
		try {
			setLoading(true);
			const res = await HttpService.get(`${API_CONFIG.recruitingDashboard}`, params);
			setDashboard(res.data.data);
			setPagination({ ...pagination, total_pages: res.data.total_pages, current_page: res.data.current_page });
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	};

	const getCell = (key: string, record: IDashboard) => {
		switch (key) {
			case 'board_name':
				return (
					<div className='flex cursor-pointer items-center justify-center ml-3 font-medium text-textBlack capitalize'>
						{record.name || '-'}
					</div>
				);
			case 'avg_ops':
				return <div>{Math.floor(+record.ops) || '-'}</div>;

			case 'no_of_athletes':
				return (
					<span className='bg-[#1d953317] p-2 rounded-lg h-8 text-[#1E9634] text-sm'>
						{record.total_player_count} Athletes
					</span>
				);

			case 'created_by':
				return <p>{`${record.recruiting_board_creator.name} ${record.recruiting_board_creator.last_name}`}</p>;

			case 'creation_date':
				return <p>{formatDate(record.created_at) || '-'}</p>;

			case 'editor':
				return <BoardEditor data={record.recruiting_board_editor} record={record} />;

			default:
				return <p>-</p>;
		}
	};

	const onPageChange = (page: number) => {
		setFilters({ ...filters, current_page: page });
	};

	const onSort = (sortData: ISortData) => {
		setFilters({ ...filters, ...sortData });
	};

	return (
		<>
			<Modal show={show} onClose={onClose} theme={Theme}>
				<Modal.Header className='rounded-t-lg bg-lightBlue'>
					<div className='flex justify-between items-center gap-10 mr-2'>
						<p className='header-txt'>Recruiting Dashboard</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<TableComponent className='h-[calc(100vh-260px)] 2xl:h-[calc(100vh-365px)] min-h-[340px] overflow-auto custom-scrollbar'>
						<TableHeader
							tableCellList={Table_Cells}
							sortData={{ sort_by: filters.sort_by, order_by: filters.order_by }}
							onSort={onSort}
						/>
						<TableBody>
							{loading && <TableSpinner colSpan={Table_Cells.length} />}
							{!loading && isEmpty(dashboard) && (
								<EmptyTable colSpan={Table_Cells.length} hideRegisterButton={true} />
							)}
							{!loading &&
								!isEmpty(dashboard) &&
								dashboard.map((data: IDashboard) => {
									return (
										<Fragment key={data.id}>
											<TableRow className='tr-left-border'>
												{Table_Cells.map((cell) => (
													<TableCell key={cell.title} className='text-center'>
														{getCell(cell.key, data)}
													</TableCell>
												))}
											</TableRow>
										</Fragment>
									);
								})}
						</TableBody>
					</TableComponent>
					<Pagination loading={loading} onPageChange={onPageChange} pagination={pagination} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ViewDashboardModal;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	header: {
		base: 'flex items-center justify-between px-4 py-5',
		title: 'text-xl font-medium w-full'
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[850px]'
	},
	body: {
		base: 'flex-1 px-3 pb-3 overflow-auto custom-scrollbar'
	}
};

const Table_Cells = [
	{ title: 'Board name', key: 'board_name', style: { width: '250px' } },
	{ title: 'Created by', key: 'created_by', style: { width: '150px' } },
	{ title: 'Creation date', key: 'creation_date', style: { width: '150px' } },
	{ title: 'No. of Athletes ', key: 'no_of_athletes', style: { width: '150px' } },
	{ title: 'Average OPS', key: 'avg_ops', shortKey: 'Performance', sortable: true, style: { width: '100px' } },
	{
		title: 'Editor',
		key: 'editor',
		style: { width: '200px' }
	}
];
